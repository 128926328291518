export const stopDraftColors = [
    "#7BC86C",
    "#F4DD28",
    "#FFAE3E",
    "#EE7564",
    "#CF8EE6",
    "#5BA4CF",
    "#2BCCE5",
    "#6DEDA9",
    "#FF8ED4",
    "#172A4D",
    "#BE717A",
];

export const fakeFromAddresses = [
    "Askängsbacken 46, 168 65 Bromma, Sweden",
    "Sköldgatan 10, 118 63 Stockholm, Sweden",
    "Blekingegatan 53, 118 56 Stockholm, Sweden",
    "Nytorgsgatan 29, 116 40 Stockholm, Sweden",
    "Rindögatan 10, 115 36 Stockholm, Sweden",
    "Frimurarevägen 2, 181 41 Lidingö, Sweden",
    "Rådjursvägen 2, 131 42 Nacka, Sweden",
    "Vasagatan 1, 111 20 Stockholm",
    "Drottninggatan 50, 111 21 Stockholm",
    "Stortorget 2, 111 29 Stockholm",
    "Sveavägen 100, 113 50 Stockholm",
    "Kungsträdgårdsgatan 12, 111 47 Stockholm",
    "Österlånggatan 17, 111 31 Stockholm",
    "Birger Jarlsgatan 16, 114 34 Stockholm",
    "Hornsgatan 45, 118 49 Stockholm",
    "Götgatan 67, 116 21 Stockholm",
    "Fleminggatan 22, 112 26 Stockholm",
    "Karlavägen 24, 114 31 Stockholm",
    "Strandvägen 15, 114 56 Stockholm",
    "Västerlånggatan 60, 111 29 Stockholm",
    "Södermalmstorg 4, 116 45 Stockholm",
    "Norrmalmstorg 5, 111 46 Stockholm",
    "Valhallavägen 117, 115 31 Stockholm, Sweden",
    "Hornsgatan 124, 117 28 Stockholm, Sweden",
    "Sveavägen 164, 113 46 Stockholm, Sweden",
    "Olof Palmes gata 29, 111 22 Stockholm, Sweden",
    "Lindhagensgatan 76, 112 18 Stockholm, Sweden",
    "Birger Jarlsgatan 112, 114 20 Stockholm, Sweden",
    "Tegnérgatan 3, 111 40 Stockholm, Sweden",
    "Odengatan 70, 113 22 Stockholm, Sweden",
    "Ringvägen 100, 118 60 Stockholm, Sweden",
    "Karlavägen 58, 114 49 Stockholm, Sweden",
];

export const fakeToAddresses = [
    "Hagsätravägen 143, 124 67 Bandhagen, Sweden",
    "Vaktelstigen 9, 125 56 Älvsjö, Sweden",
    "Mälarhöjdsvägen 19A, 129 40 Hägersten, Sweden",
    "Bäckaskiftsvägen 10, 122 42 Enskede, Sweden",
    "Laxåvägen 44, 128 44 Bagarmossen, Sweden",
    "Saltvägen 39, 123 55 Farsta, Sweden",
    "Filipstadsbacken 72, 123 43 Farsta, Sweden",
    "Centralvägen 6, 171 68 Solna",
    "Stationsvägen 15, 191 40 Sollentuna",
    "Hammarby allé 110, 120 66 Hammarby",
    "Granitvägen 12, 131 49 Nacka",
    "Skolvägen 2, 183 34 Täby",
    "Biblioteksgången 8, 164 46 Kista",
    "Strandpromenaden 3, 181 82 Lidingö",
    "Värdshusvägen 7, 182 75 Danderyd",
    "Sjövägen 14, 139 60 Värmdö",
    "Parkvägen 20, 136 38 Haninge",
    "Solängsvägen 12, 141 42 Huddinge",
    "Tallvägen 4, 175 64 Järfälla",
    "Aspvägen 8, 135 50 Tyresö",
    "Klockarvägen 12, 151 61 Södertälje",
    "Ängsvägen 3, 178 40 Ekerö",
    "Hammarby allé 47, 120 66 Hammarby, Sweden",
    "Stora Nygatan 42, 111 27 Stockholm, Sweden",
    "Götgatan 16, 118 46 Stockholm, Sweden",
    "Skeppsbron 22, 111 30 Stockholm, Sweden",
    "Fleminggatan 39, 112 26 Stockholm, Sweden",
    "Vasagatan 16, 111 20 Stockholm, Sweden",
    "Nybrogatan 53, 114 40 Stockholm, Sweden",
    "Regeringsgatan 65, 111 56 Stockholm, Sweden",
    "Kungsgatan 44, 111 35 Stockholm, Sweden",
    "Sankt Eriksgatan 5, 112 39 Stockholm, Sweden",
];
