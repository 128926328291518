import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { deleteOrderDraft } from "../../api/stop-draft";
import Icon from "../../components/UI/Icon";
import Button from "../../components/buttons/Button";
import OrderFile from "../../containers/OrderFile";
import Popup from "../../hoc/Popup";
import { OrderDraft } from "../../shared/types/api";
import { formatDateString } from "../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onDeleted?: () => void;
    orderDraft: OrderDraft | null;
};

function OrderDraftPopup(props: Props) {
    const { t } = useTranslation();

    const { mutate: deleteOrderDraftHandler, isPending: isDeletingOrderDraft } =
        useMutation({
            mutationFn: async (orderDraftId: number) => {
                await deleteOrderDraft(orderDraftId);
            },
            onSuccess: () => {
                toast.success(t("successMessage.orderDraftDeleted"));
                if (props.onDeleted) props.onDeleted();
                props.onClose();
            },
            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    if (!props.orderDraft) return null;
    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            dontCloseOnOutsideClick={!!props.orderDraft.file_name}
            overlayComponent={
                props.orderDraft.file_name ? (
                    <OrderFile filename={props.orderDraft.file_name} />
                ) : undefined
            }
        >
            <div className="order-draft-popup">
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderDraft.sourceLabel")}
                    </p>
                    <p>
                        {(props.orderDraft.source === "alron" ||
                            props.orderDraft.source === "ai") && (
                            <Icon type="ai" />
                        )}{" "}
                        {props.orderDraft.source === "ai"
                            ? props.orderDraft.source.toUpperCase()
                            : props.orderDraft.source}
                    </p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderDraft.createdAtLabel")}
                    </p>
                    <p>{formatDateString(props.orderDraft.created_at)}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderDraft.orderNumberLabel")}
                    </p>
                    <p>{props.orderDraft.origin_order_number || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderDraft.weightLabel")}
                    </p>
                    <p>
                        {props.orderDraft.weight_kg
                            ? convertToNumberWithSpaces(
                                  props.orderDraft.weight_kg,
                                  "kg"
                              )
                            : "-"}
                    </p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.fromLabel")}
                    </p>
                    <p>{props.orderDraft.from_location || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.toLabel")}
                    </p>
                    <p>{props.orderDraft.to_location || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.earliestPickupLabel")}
                    </p>
                    <p>
                        {props.orderDraft.earliest_pickup_utc
                            ? formatDateString(
                                  props.orderDraft.earliest_pickup_utc,
                                  {
                                      showTime:
                                          props.orderDraft.earliest_pickup_utc.includes(
                                              "T"
                                          ),
                                  }
                              )
                            : "-"}
                    </p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.latestDeliveryLabel")}
                    </p>
                    <p>
                        {props.orderDraft.latest_delivery_utc
                            ? formatDateString(
                                  props.orderDraft.latest_delivery_utc,
                                  {
                                      showTime:
                                          props.orderDraft.latest_delivery_utc.includes(
                                              "T"
                                          ),
                                  }
                              )
                            : "-"}
                    </p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.pickupPhoneLabel")}
                    </p>
                    <p>{props.orderDraft.pickup_phone || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.contactPhoneLabel")}
                    </p>
                    <p>{props.orderDraft.dropoff_phone || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.cargoContentLabel")}
                    </p>
                    <p
                        style={{
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {props.orderDraft.cargo_content || "-"}
                    </p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.pickupContactLabel")}
                    </p>
                    <p>{props.orderDraft.pickup_contact?.name || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.dropoffContactLabel")}
                    </p>
                    <p>{props.orderDraft.dropoff_contact?.name || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.projectReferenceLabel")}
                    </p>
                    <p>{props.orderDraft.project_reference || "-"}</p>
                </section>
                <section>
                    <p className="title text-s-r">
                        {t("popup.orderInfo.tagLabel")}
                    </p>
                    <p>{props.orderDraft.tag || "-"}</p>
                </section>
                <Button
                    label={t("popup.orderDraft.deleteLabel")}
                    color="danger"
                    isLoading={isDeletingOrderDraft}
                    onClick={() =>
                        deleteOrderDraftHandler(props.orderDraft!.id)
                    }
                    style={{
                        marginTop: "10px",
                        marginRight: "auto",
                        marginLeft: "10px",
                    }}
                />
            </div>
        </Popup>
    );
}

export default OrderDraftPopup;
