import { CAR_NAME, DELIVERY_NAME } from "../values/enums";

export type IconType =
    | "ordinary-car"
    | "large-van"
    | "truck"
    | "circle-info"
    | "plus"
    | "cog"
    | "phone"
    | "spinner-third"
    | "chevron-up"
    | "chevron-down"
    | "chevron-left"
    | "chevron-right"
    | "arrow-down"
    | "arrow-up"
    | "orders"
    | "logout"
    | "paper-plane"
    | "crane"
    | "medium-crane"
    | "large-crane"
    | "search"
    | "cross"
    | "check"
    | "clock"
    | "calendar"
    | "map"
    | "trash"
    | "star"
    | "star-filled"
    | "seedling"
    | "comment"
    | "circle"
    | "circle-dot"
    | "circle-solid"
    | "house"
    | "eye-slash"
    | "copy"
    | "leaf"
    | "arrow-bracket"
    | "plane-departure"
    | "race-flag"
    | "user"
    | "address-book"
    | "image"
    | "file-upload"
    | "file-download"
    | "ai"
    | "map-pin"
    | "hashtag"
    | "tag"
    | "weight";

export type DropdownOption = {
    value: string;
    label: string;
    disabled?: boolean;
};

export type OrderMode = "active" | "fulfilled";

const orderStatuses = [
    "created",
    "verified",
    "at-pickup",
    "in-progress",
    "next-stop",
    "unloading",
    "fulfilled",
] as const;

export type OrderStatus = (typeof orderStatuses)[number];

export type AppEnvironment = "production" | "staging" | "demo";

export type OrderType = "fleet-on-demand" | "fleet-planner";

export const faqSections = [
    "vehicle",
    "delivery",
    "payment",
    "return",
    "account",
    "sustainability",
] as const;

export type FaqSection = (typeof faqSections)[number];

export type CarNames = (typeof CAR_NAME)[keyof typeof CAR_NAME];
export type DeliveryNames = (typeof DELIVERY_NAME)[keyof typeof DELIVERY_NAME];

export type KeyString<T> = {
    [key: string]: T;
};

export type KeyNumber<T> = {
    [key: number]: T;
};

export type NavItem = {
    id: string;
    label: string;
    path: string;
    newExpires?: Date;
    isBeta?: boolean;
};

export type StopDraftsMapFilterOptions = {
    stopTypeToShow: "pickup" | "dropoff" | "both";
    date: Date | null;
    time: { from: string; to: string } | null;
    showDrivers: boolean;
    showLocations: boolean;
    color: string | null;
    contactId: number | null;
    tag: string | null;
};

export type DeepNonNullableFields<T> = T extends Array<infer U>
    ? Array<DeepNonNullableFields<U>>
    : T extends object
    ? {
          [P in keyof T]: DeepNonNullableFields<T[P]> extends infer R
              ? R extends number | null
                  ? number
                  : R extends string | null
                  ? string
                  : DeepNonNullableFields<R>
              : never;
      }
    : T;

export type MapPoint = {
    address: string;
    label: string;
    location: {
        lat: number;
        lng: number;
    };
    icon?: IconType;
};

export type FleetPlannerReloadDataHandler = (
    ...labels: (
        | "tours"
        | "etas"
        | "stop-drafts"
        | "fleet-planner-orders"
        | "order-drafts"
        | "moved-stops"
    )[]
) => Promise<void>;
