import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSmsSettings, updateSmsSettings } from "../../api/tours";
import Spinner from "../../components/UI/Spinner";
import Switch from "../../components/inputs/Switch";
import { SmsSettings } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {};

function FleetSettings(props: Props) {
    const { t } = useTranslation();
    const { t: tSms } = useTranslation("sms");

    const { user } = useSelector((state: ReduxState) => state.auth);

    const {
        data: smsSettings,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["sms-settings"],
        queryFn: async () => {
            const res = await getSmsSettings();
            return res.data;
        },
    });

    const { mutate: updateSmsSettingsHandler, isPending: isUpdating } =
        useMutation({
            mutationFn: async (data: SmsSettings) => {
                await updateSmsSettings(data);
                await refetch();
            },
            onSuccess: () => {
                toast.success(t("successMessage.smsSettingsUpdated"));
            },
            onError: () => {
                toast.error(t("errorMessage.smsSettingsUpdatedError"));
            },
        });

    const smsEtaAndTrackingKey = useMemo(
        () => (smsSettings?.skip_eta_and_tracking_in_sms ? "without" : "with"),
        [smsSettings?.skip_eta_and_tracking_in_sms]
    );

    const mockData = useMemo(
        () => ({
            eta_minutes: "10",
            tracking_link: "connect.alrik.com" + ROUTE.TrackingRedirect,
            scheduled_date: "2000-01-01",
            dropoff_address: "Alrik Technologies, Storgatan, Stockholm",
            origin_order_id: "123456789",
            company:
                user?.company_entity?.friendly_name ||
                user?.company_entity?.name ||
                "",
        }),
        [user?.company_entity?.friendly_name, user?.company_entity?.name]
    );

    return status !== "success" ? (
        <Spinner padding="10px" />
    ) : (
        <div className="fleet-settings">
            <p>{t("fleetSettings.description")}</p>

            <div className="sms-row">
                <div className="sms-switch-wrapper">
                    <p className="sms-label">
                        {t("fleetSettings.includeEtaAndTracking")}
                    </p>

                    <Switch
                        isActive={!smsSettings.skip_eta_and_tracking_in_sms}
                        onChange={(value) =>
                            updateSmsSettingsHandler({
                                ...smsSettings,
                                skip_eta_and_tracking_in_sms: !value,
                            })
                        }
                        disabled={isUpdating || isFetching}
                    />
                </div>
            </div>

            <hr />

            <div className="sms-row">
                <div className="sms-switch-wrapper">
                    <p className="sms-label">
                        {t("fleetSettings.orderScheduled")}
                    </p>

                    <Switch
                        isActive={!smsSettings.skip_order_scheduled_sms}
                        onChange={(value) =>
                            updateSmsSettingsHandler({
                                ...smsSettings,
                                skip_order_scheduled_sms: !value,
                            })
                        }
                        disabled={isUpdating || isFetching}
                    />
                </div>

                <div
                    className="sms"
                    style={{
                        backgroundColor: !smsSettings.skip_order_scheduled_sms
                            ? "var(--text-color-alt)"
                            : undefined,
                    }}
                >
                    <p className="sms-label">
                        {tSms(
                            `orderScheduled.${smsEtaAndTrackingKey}.trackingLink`,
                            {
                                ...mockData,
                                interpolation: {
                                    prefix: "{",
                                    suffix: "}",
                                    escapeValue: false,
                                },
                            }
                        )}
                    </p>
                </div>

                <p>{t("fleetSettings.smsReciverDropoffContact")}</p>
            </div>

            <div className="sms-row">
                <div className="sms-switch-wrapper">
                    <p className="sms-label">
                        {t("fleetSettings.orderConfirmation")}
                    </p>

                    <Switch
                        isActive={!smsSettings.skip_order_confirmation_sms}
                        onChange={(value) =>
                            updateSmsSettingsHandler({
                                ...smsSettings,
                                skip_order_confirmation_sms: !value,
                            })
                        }
                        disabled={isUpdating || isFetching}
                    />
                </div>

                <div
                    className="sms"
                    style={{
                        backgroundColor:
                            !smsSettings.skip_order_confirmation_sms
                                ? "var(--text-color-alt)"
                                : undefined,
                    }}
                >
                    <p className="sms-label">
                        {tSms(
                            `orderConfirmed.${smsEtaAndTrackingKey}.trackingLink`,
                            {
                                ...mockData,
                                interpolation: {
                                    prefix: "{",
                                    suffix: "}",
                                    escapeValue: false,
                                },
                            }
                        )}
                    </p>
                </div>

                <p>{t("fleetSettings.smsReciverDropoffContact")}</p>
            </div>

            <div className="sms-row">
                <div className="sms-switch-wrapper">
                    <p className="sms-label">
                        {t("fleetSettings.pickupReminder")}
                    </p>

                    <Switch
                        isActive={!smsSettings.skip_pickup_stop_sms}
                        onChange={(value) =>
                            updateSmsSettingsHandler({
                                ...smsSettings,
                                skip_pickup_stop_sms: !value,
                            })
                        }
                        disabled={isUpdating || isFetching}
                    />
                </div>

                <div
                    className="sms"
                    style={{
                        backgroundColor: !smsSettings.skip_pickup_stop_sms
                            ? "var(--text-color-alt)"
                            : undefined,
                    }}
                >
                    <p className="sms-label">
                        {tSms(
                            `pickupReminder.${smsEtaAndTrackingKey}.etaMinutes`,
                            {
                                ...mockData,
                                interpolation: {
                                    prefix: "{",
                                    suffix: "}",
                                    escapeValue: false,
                                },
                            }
                        )}
                    </p>
                </div>

                <p>{t("fleetSettings.smsReciverPickupContact")}</p>
            </div>
            <div className="sms-row">
                <div className="sms-switch-wrapper">
                    <p className="sms-label">
                        {t("fleetSettings.dropoffReminder")}
                    </p>

                    <Switch
                        isActive={!smsSettings.skip_dropoff_stop_sms}
                        onChange={(value) =>
                            updateSmsSettingsHandler({
                                ...smsSettings,
                                skip_dropoff_stop_sms: !value,
                            })
                        }
                        disabled={isUpdating || isFetching}
                    />
                </div>

                <div
                    className="sms"
                    style={{
                        backgroundColor: !smsSettings.skip_dropoff_stop_sms
                            ? "var(--text-color-alt)"
                            : undefined,
                    }}
                >
                    <p className="sms-label">
                        {tSms(
                            `dropoffReminder.${smsEtaAndTrackingKey}.etaMinutes`,
                            {
                                ...mockData,
                                interpolation: {
                                    prefix: "{",
                                    suffix: "}",
                                    escapeValue: false,
                                },
                            }
                        )}
                    </p>
                </div>

                <p>{t("fleetSettings.smsReciverDropoffContact")}</p>
            </div>
        </div>
    );
}

export default FleetSettings;
