import { AdvancedMarker } from "@vis.gl/react-google-maps";
import Icon from "../../../components/UI/Icon";
import "./style.scss";
import { MapPoint } from "../../../shared/types/internal";

type Props = {
    point: MapPoint;
    amountAtSameLocation?: number;
};

function PointsMarker(props: Props) {
    return (
        <AdvancedMarker position={props.point.location}>
            <div className="points-marker">
                <div className="content">
                    <Icon type={props.point.icon || "chevron-right"} />
                    {props.amountAtSameLocation &&
                    props.amountAtSameLocation > 1 ? (
                        <span className="amount-at-location text-m-m">
                            {props.amountAtSameLocation}
                        </span>
                    ) : null}
                    <span className="text-m-m">{props.point.label}</span>
                </div>
            </div>
        </AdvancedMarker>
    );
}

export default PointsMarker;
