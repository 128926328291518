import { Map } from "@vis.gl/react-google-maps";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../components/buttons/Button";
import TextButton from "../../components/buttons/TextButton";
import { Driver } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { getDriverDisplayName } from "../../shared/utility/misc";
import { DEFAULT_CENTER } from "../../shared/values/enums";
import DriverMarkers from "../markers/DriverMarkers";
import "./style.scss";

type Props = {
    drivers: Driver[];
    onUpdateDrivers: () => void;
    isUpdatingDrivers: boolean;
};

function DriversMap(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);
    const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(
        null
    );

    const activeDrivers = useMemo(
        () =>
            props.drivers.filter(
                (driver) => driver.online && driver.mt_driver_id
            ),
        [props.drivers]
    );
    const inactiveDrivers = useMemo(
        () =>
            props.drivers.filter(
                (driver) => !driver.online && driver.mt_driver_id
            ),
        [props.drivers]
    );

    return (
        <div className="drivers-map">
            <Map
                mapId="drivers-map"
                defaultCenter={
                    center
                        ? center
                        : user?.location_entity?.lat &&
                          user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                onCenterChanged={({ detail }) => setCenter(detail.center)}
                defaultZoom={12}
            >
                <DriverMarkers drivers={props.drivers} />
            </Map>
            <div className="drivers-list">
                <div className="controls">
                    <Button
                        label={t("drivers.updateMap")}
                        color="secondary"
                        onClick={props.onUpdateDrivers}
                        isLoading={props.isUpdatingDrivers}
                        short
                    />
                </div>
                {!!activeDrivers.length && (
                    <section>
                        <p>{t("drivers.activeDrivers")}</p>
                        {activeDrivers.map((driver) => (
                            <TextButton
                                key={driver.id}
                                label={getDriverDisplayName(driver, {
                                    showOrganization:
                                        !!settings.showAllDriversInCompany,
                                })}
                                color="var(--text-color)"
                                onClick={() =>
                                    setCenter({
                                        lat: +driver.current_lat!,
                                        lng: +driver.current_lng!,
                                    })
                                }
                            />
                        ))}
                    </section>
                )}
                {!!inactiveDrivers.length && (
                    <section>
                        <p>{t("drivers.inactiveDrivers")}</p>
                        {inactiveDrivers.map((driver) => (
                            <TextButton
                                key={driver.id}
                                label={getDriverDisplayName(driver, {
                                    showOrganization:
                                        !!settings.showAllDriversInCompany,
                                })}
                                color="var(--text-color)"
                                onClick={() =>
                                    toast.error(
                                        t("errorMessage.inactiveDriver")
                                    )
                                }
                            />
                        ))}
                    </section>
                )}
            </div>
        </div>
    );
}

export default DriversMap;
