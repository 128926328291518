import { useTranslation } from "react-i18next";
import DotsModal from "../../components/UI/DotsModal";
import { Driver } from "../../shared/types/api";
import {
    convertToNumberWithSpaces,
    getDriverDisplayName,
} from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    drivers: Driver[];
    onEditClick: (driver: Driver) => void;
};

function DriversTable(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="drivers-table">
            <table>
                <thead>
                    <tr>
                        <th>{t("drivers.emailLabel")}</th>
                        <th>{t("drivers.nameLabel")}</th>
                        <th>{t("drivers.phoneLabel")}</th>
                        <th>{t("drivers.hourlyCostLabel")}</th>
                        <th>{t("drivers.fuelTypeLabel")}</th>
                        <th>{t("drivers.fuelConsumptionLabel")}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {props.drivers.map((d) => (
                        <tr
                            key={d.id}
                            style={{
                                color: !d.mt_driver_id
                                    ? "var(--text-color-light)"
                                    : undefined,
                            }}
                        >
                            <td>{d.email}</td>
                            <td>
                                {getDriverDisplayName(d, { ignoreEmail: true })}
                            </td>
                            <td>{d.phone}</td>
                            <td>
                                {d.hourly_cost
                                    ? convertToNumberWithSpaces(
                                          d.hourly_cost,
                                          "SEK"
                                      )
                                    : null}
                            </td>
                            <td>{d.fuel_type?.name}</td>
                            <td>{d.fuel_consumption}</td>
                            <td>
                                <div className="driver-edit-wrapper">
                                    <DotsModal
                                        content={[
                                            {
                                                title: t("drivers.editLabel"),
                                                onClick: () =>
                                                    props.onEditClick(d),
                                            },
                                        ]}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                    {!props.drivers.length && (
                        <tr>
                            <td
                                colSpan={7}
                                style={{
                                    textAlign: "center",
                                    color: "var(--text-color-light)",
                                }}
                            >
                                {t("drivers.noDrivers")}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default DriversTable;
