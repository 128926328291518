import { Map } from "@vis.gl/react-google-maps";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Icon from "../../components/UI/Icon";
import usePanToMapCenter from "../../hooks/functionality/usePanToMapCenter";
import { ReduxState } from "../../shared/types/redux";
import { debounce } from "../../shared/utility/misc";
import { DEFAULT_CENTER } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    center?: google.maps.LatLng;
    onCenterChanged?: (center: google.maps.LatLngLiteral) => void;
};

function PinMap(props: Props) {
    const { onCenterChanged } = props;

    const { user } = useSelector((state: ReduxState) => state.auth);
    usePanToMapCenter(props.center);

    const mapCenterChangedHandler = useMemo(
        () =>
            debounce((center: google.maps.LatLngLiteral) => {
                onCenterChanged?.(center);
            }, 200),

        [onCenterChanged]
    );

    return (
        <div className="pin-map">
            <Map
                mapId="pin-map"
                defaultCenter={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                defaultZoom={12}
                onCenterChanged={(e) => {
                    mapCenterChangedHandler(e.detail.center);
                }}
            >
                <div className="pin">
                    <Icon type="map-pin" fontSize="35px" />
                </div>
            </Map>
        </div>
    );
}

export default PinMap;
