import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    getAddressFromLocation,
    getGoogleAutoComplete,
    getLocationFromAddress,
} from "../../api/google";
import Button from "../../components/buttons/Button";
import AddressSearchRow from "../../components/inputs/AddressSearch/AddressSearchRow";
import Input from "../../components/inputs/Input";
import Popup from "../../hoc/Popup";
import PinMap from "../../maps/PinMap";
import analytics from "../../shared/services/ga4";
import { ReduxState } from "../../shared/types/redux";
import { debounce } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onSubmit: (address: string) => void;
    preSelectedAddress?: string;
    popupRef?: RefObject<HTMLDivElement>;
};

function PinMapPopup(props: Props) {
    const { onClose } = props;

    const { t } = useTranslation();

    const { user } = useSelector((state: ReduxState) => state.auth);

    const [mapCenter, setMapCenter] = useState<google.maps.LatLng | undefined>(
        undefined
    );
    const [selectedAddress, setSelectedAddress] = useState("");

    const [searchString, setSearchString] = useState("");
    const [predictions, setPredictions] = useState<
        google.maps.places.QueryAutocompletePrediction[]
    >([]);

    // State for prevent running the location to address conversion when the center is set from address (this would cause the selected address to change)
    const [isCenterSetFromAddress, setIsCenterSetFromAddress] = useState(false);
    const [isInvalidLocation, setIsInvalidLocation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getAddressAutoComplete = useCallback(
        async (searchString: string) => {
            if (!searchString) {
                return [];
            }
            const data = await getGoogleAutoComplete(
                searchString,
                user?.location_entity?.region?.region_code
            );

            if (!data?.length) {
                return [
                    {
                        place_id: "no-results",
                        description: searchString,
                        matched_substrings: [],
                        terms: [],
                    },
                ];
            }

            return data;
        },
        [user?.location_entity?.region?.region_code]
    );

    const changeCenterFromAddressHandler = useMemo(
        () =>
            debounce(async (address: string) => {
                if (!address) return;

                setIsLoading(true);
                const { location, locationType } = await getLocationFromAddress(
                    address
                );
                setIsLoading(false);

                if (!location) return;

                setIsCenterSetFromAddress(true);
                setIsInvalidLocation(locationType !== "ROOFTOP");
                setMapCenter(location);
            }, 100),
        []
    );

    const centerChangeHandler = useCallback(
        async (center: google.maps.LatLngLiteral) => {
            if (!props.showPopup) return;

            setIsLoading(true);
            const { address, locationType } = await getAddressFromLocation(
                center
            );
            setIsLoading(false);

            if (!address) return;

            setIsInvalidLocation(locationType !== "ROOFTOP");
            setSelectedAddress(address);
            setSearchString(address);
            setPredictions([]);
        },
        [props.showPopup]
    );

    const selectAddressHandler = useCallback(
        async (address: string) => {
            setSelectedAddress(address);
            setSearchString(address);
            setPredictions([]);
            changeCenterFromAddressHandler(address);
        },
        [changeCenterFromAddressHandler]
    );

    useEffect(() => {
        if (!props.preSelectedAddress || !props.showPopup) return;
        selectAddressHandler(props.preSelectedAddress);
    }, [selectAddressHandler, props.preSelectedAddress, props.showPopup]);

    const searchStringChangedHandler = useCallback(
        async (searchString: string) => {
            setSearchString(searchString);
            const data = await getAddressAutoComplete(searchString);
            setPredictions(data);
        },
        [getAddressAutoComplete]
    );

    const closeHandler = useCallback(() => {
        setMapCenter(undefined);
        setSelectedAddress("");
        setSearchString("");
        setPredictions([]);
        setIsInvalidLocation(false);
        onClose();
    }, [onClose]);

    return (
        <Popup
            ref={props.popupRef}
            showPopup={props.showPopup}
            onClose={closeHandler}
            title={t("popup.pinMap.title")}
            dontCloseOnOutsideClick
            overlayComponent={
                <PinMap
                    center={mapCenter}
                    onCenterChanged={(center) => {
                        if (!center) return;
                        if (isCenterSetFromAddress) {
                            setIsCenterSetFromAddress(false);
                            return;
                        }
                        centerChangeHandler(center);
                    }}
                />
            }
        >
            <div className="pin-map-popup">
                <Input
                    type="text"
                    label={t("popup.pinMap.addressLabel")}
                    value={searchString}
                    onChange={searchStringChangedHandler}
                    isLoading={isLoading}
                    icon="search"
                />
                <div className="pin-map-address-search">
                    {!!predictions.length && (
                        <div className="address-buttons">
                            {predictions.map((p) => (
                                <AddressSearchRow
                                    key={p.place_id}
                                    label={p.description}
                                    onClick={() =>
                                        selectAddressHandler(p.description)
                                    }
                                    onFavouriteClick={() => {}}
                                    isFocused={false}
                                    noFavourite={true}
                                />
                            ))}
                        </div>
                    )}
                </div>

                {isInvalidLocation && (
                    <div className="invalid-location">
                        <p>{t("errorMessage.invalidLocationPinMap")}</p>
                        <Button
                            label={t("popup.pinMap.useAnyway")}
                            onClick={() => {
                                analytics.mapPinAddress({
                                    address: selectedAddress,
                                });
                                props.onSubmit(selectedAddress);
                            }}
                            color="secondary"
                        />
                    </div>
                )}
                <Button
                    onClick={() => {
                        analytics.mapPinAddress({
                            address: selectedAddress,
                        });
                        props.onSubmit(selectedAddress);
                    }}
                    label={t("popup.pinMap.submitLabel")}
                    color="secondary"
                    disabled={isInvalidLocation}
                />
            </div>
        </Popup>
    );
}

export default PinMapPopup;
