import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    activateCustomer,
    getCustomerActivationStatus,
} from "../../../api/auth";
import Button from "../../../components/buttons/Button";
import TextButton from "../../../components/buttons/TextButton";
import Input from "../../../components/inputs/Input";
import { useSearchQuery } from "../../../hooks/functionality/useSearchQuery";
import { SetupForm, setupForm } from "../../../schemas/auth";
import memberstack from "../../../shared/services/memberstack";
import { onFormError } from "../../../shared/utility/misc";
import { QUERY_PARAM, ROUTE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {};

function Setup(props: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const query = useSearchQuery();

    const { control, handleSubmit, setValue } = useForm<SetupForm>({
        resolver: zodResolver(setupForm),
        defaultValues: {
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            password: "",
            repeatPassword: "",
            activationKey: "",
        },
    });

    const { mutate: registerHandler, isPending } = useMutation({
        mutationFn: async (data: SetupForm) => {
            await activateCustomer({
                password: data.password,
                first_name: data.firstName,
                last_name: data.lastName,
                customer_phone: data.phone,
                activation_key: data.activationKey,
            });

            return data;
        },

        onSuccess: async (data: SetupForm) => {
            toast.success(t("setup.success"));

            try {
                await memberstack.loginMemberEmailPassword({
                    email: data.email,
                    password: data.password,
                });
            } catch (error) {
                navigate(ROUTE.Login);
            }
        },

        onError: () => {
            toast.error(t("errorMessage.setupError"));
        },
    });

    useEffect(() => {
        const authCode = query.get(QUERY_PARAM.ActivationKey);
        if (!authCode) {
            toast.error(t("errorMessage.missingAuthCode"));
            navigate(ROUTE.AuthCode);
            return;
        }

        getCustomerActivationStatus(authCode)
            .then((res) => {
                const { email, first_name, last_name, customer_phone } =
                    res.data.information_provided;

                setValue("email", email || "");
                setValue("firstName", first_name || "");
                setValue("lastName", last_name || "");
                setValue("phone", customer_phone || "");
                setValue("activationKey", authCode || "");
            })
            .catch(() => {
                toast.error(t("errorMessage.invalidAuthCode"));
                //navigate(ROUTE.AuthCode);
            });
    }, [navigate, query, setValue, t]);

    return (
        <div className="setup">
            <form
                onSubmit={handleSubmit(
                    (data) => registerHandler(data),
                    onFormError
                )}
            >
                <h1 className="text-xl-m">{t("setup.title")}</h1>
                <hr />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="email"
                            value={value}
                            onChange={onChange}
                            width="100%"
                            disabled
                        />
                    )}
                />
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            width="100%"
                            placeholder={t("settings.firstNamePlaceholder")}
                            label={t("settings.firstNameLabel")}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            width="100%"
                            placeholder={t("settings.lastNamePlaceholder")}
                            label={t("settings.lastNameLabel")}
                        />
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            width="100%"
                            placeholder={t("settings.phonePlaceholder")}
                            label={t("settings.phoneLabel")}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="password"
                            value={value}
                            onChange={onChange}
                            label={t("setup.passwordLabel")}
                            placeholder={t("setup.passwordPlaceholder")}
                            width="100%"
                            autoComplete="new-password"
                        />
                    )}
                />
                <Controller
                    name="repeatPassword"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="password"
                            value={value}
                            onChange={onChange}
                            label={t("setup.repeatPasswordLabel")}
                            placeholder={t("setup.passwordPlaceholder")}
                            width="100%"
                            autoComplete="new-password"
                        />
                    )}
                />

                <div className="policy-wrapper">
                    <p className="text-s-r">
                        {t("setup.acceptPolicy")}{" "}
                        <a
                            href="https://www.alrik.com/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TextButton
                                label={t("setup.privacyPolicy")}
                                color="primary"
                                noSumbit
                            />
                        </a>
                        {" & "}
                        <a
                            href="https://storage.googleapis.com/alrik-connect-images/terms-of-service.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TextButton
                                label={t("general.termsAndConditions")}
                                noSumbit
                            />
                        </a>
                    </p>
                </div>

                <Button
                    color="secondary"
                    label={t("setup.submitButtonLabel")}
                    width="100%"
                    isLoading={isPending}
                />
            </form>
        </div>
    );
}

export default Setup;
