import { useCallback, useEffect } from "react";
import { MapPoint } from "../../shared/types/internal";

function useMapFitBounds({
    points,
    map,
}: {
    points: MapPoint[];
    map: google.maps.Map | null;
}) {
    const fitBoundsHandler = useCallback(
        (points: MapPoint[]) => {
            if (!map) return;

            const bounds = new google.maps.LatLngBounds();

            for (let i = 0; i < points.length; i++) {
                const point = points[i];

                bounds.extend(point.location);
            }

            if (bounds.isEmpty()) return;

            map.fitBounds(bounds, 0);
        },
        [map]
    );

    useEffect(() => {
        if (!map) return;
        const listener = google.maps.event.addListenerOnce(
            map,
            "tilesloaded",
            () => fitBoundsHandler(points)
        );

        return () => {
            listener.remove();
        };
    }, [fitBoundsHandler, map, points]);
}

export default useMapFitBounds;
