import { ChartData, ChartDataset, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import "./style.scss";

type Props = {
    title?: string;
    description?: string;
    labels: string[];
    data: ChartDataset<"line">[];
    options?: ChartOptions<"line">;
};

function LineChart(props: Props) {
    const options: ChartOptions<"line"> = {
        animation: {
            duration: 0,
        },
        plugins: {
            tooltip: {
                backgroundColor: "#0f0f0f",
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        ...props.options,
    };

    const data: ChartData<"line"> = {
        labels: props.labels,
        datasets: props.data.map((ds) => ({
            borderColor: "#47e793",
            backgroundColor: ds.borderColor || "#47e793",
            pointBorderColor: "#0f0f0f",
            pointBackgroundColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointBorderWidth: 2,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 3,
            pointRadius: 3,
            lineTension: 0.3,
            datalabels: {
                display: false,
            },
            ...ds,
        })),
    };

    return (
        <div className="line-chart">
            {props.title && <p className="text-l-m">{props.title}</p>}
            {props.description && <p>{props.description}</p>}
            <Line data={data} options={options} />
        </div>
    );
}

export default LineChart;
