import Icon from "../../../UI/Icon";
import "./style.scss";

type Props = {
    label: string;
    onClick: () => void;
    onFavouriteClick: () => void;
    isFocused?: boolean;
    isFavourite?: boolean;
    noFavourite?: boolean;
};

function AddressSearchRow(props: Props) {
    return (
        <div
            className="address-search-row"
            tabIndex={0}
            role="button"
            onClick={props.onClick}
            data-focused={props.isFocused}
            style={
                props.isFocused
                    ? {
                          backgroundColor: "var(--highlight-color)",
                      }
                    : {}
            }
        >
            <span className="text-m-r">{props.label}</span>
            {!props.noFavourite && (
                <button
                    className="favourite-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onFavouriteClick();
                    }}
                    type="button"
                >
                    <Icon
                        type={props.isFavourite ? "star-filled" : "star"}
                        style={{
                            color: "var(--text-color)",
                        }}
                    />
                </button>
            )}
        </div>
    );
}

export default AddressSearchRow;
