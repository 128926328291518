import { Draggable } from "@hello-pangea/dnd";
import CombinedStopCard, { CombinedStopCardProps } from "../CombinedStopCard";
import "./style.scss";

type Props = {
    index: number;
    isDragDisabled?: boolean;
    draggableId?: string;
} & CombinedStopCardProps;

function CombinedStopCardDraggable(props: Props) {
    return (
        <Draggable
            draggableId={props.draggableId || props.stops.pickup.id.toString()}
            index={props.index}
            isDragDisabled={props.isDragDisabled}
        >
            {(provided) => <CombinedStopCard {...props} provided={provided} />}
        </Draggable>
    );
}

export default CombinedStopCardDraggable;
