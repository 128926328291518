import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    createFavourite,
    deleteFavourite,
    updateFavourite,
} from "../../api/favourite";
import Button from "../../components/buttons/Button";
import AddressSearch from "../../components/inputs/AddressSearch";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import Textarea from "../../components/inputs/Textarea";
import Popup from "../../hoc/Popup";
import { FavouriteForm, favouriteForm } from "../../schemas/form";
import analytics from "../../shared/services/ga4";
import { Favourite, FavouriteType } from "../../shared/types/api";
import { onFormError } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    editFavourite?: Favourite;
    address?: string;
    showPopup: boolean;
    onClose: () => void;
    onSave: () => void;
    favouriteType?: FavouriteType;
};

function FavouritePopup(props: Props) {
    const { t } = useTranslation();

    const defaultValues = useMemo(() => {
        return {
            name: props.editFavourite?.name || "",
            description: props.editFavourite?.description || "",
            address: props.editFavourite?.address || props.address || "",
            type: props.editFavourite?.type || props.favouriteType || "dropoff",
        };
    }, [
        props.address,
        props.editFavourite?.address,
        props.editFavourite?.description,
        props.editFavourite?.name,
        props.editFavourite?.type,
        props.favouriteType,
    ]);

    const {
        handleSubmit,
        control,
        reset: resetInputs,
    } = useForm<FavouriteForm>({
        resolver: zodResolver(favouriteForm),
        defaultValues,
    });

    useEffect(() => {
        resetInputs(defaultValues);
    }, [defaultValues, resetInputs]);

    const { mutate: createHandler, isPending: isCreating } = useMutation({
        mutationFn: async ({
            name,
            description,
            address,
            type,
        }: FavouriteForm) => {
            await createFavourite({
                name,
                description,
                address,
                type,
            });

            return { name, address };
        },
        onSuccess: ({ name, address }: { name: string; address: string }) => {
            toast.success(t("successMessage.favouriteCreated"));
            analytics.favouriteAdded({ name, address });
            closeHandler();
            props.onSave();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: editHandler, isPending: isEditing } = useMutation({
        mutationFn: async ({
            data: { name, description, address, type },
            id,
        }: {
            data: FavouriteForm;
            id: number;
        }) => {
            await updateFavourite({
                id,
                name,
                description,
                address,
                type,
            });
        },
        onSuccess: () => {
            toast.success(t("successMessage.favouriteUpdated"));
            closeHandler();
            props.onSave();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: deleteHandler, isPending: isDeleting } = useMutation({
        mutationFn: async (id: number) => {
            await deleteFavourite(id);
        },
        onSuccess: () => {
            toast.success(t("successMessage.favouriteDeleted"));
            closeHandler();
            props.onSave();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    function closeHandler() {
        resetInputs();
        props.onClose();
    }

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={closeHandler}
            title={
                props.editFavourite
                    ? t("popup.favourite.titleEdit")
                    : t("popup.favourite.titleAdd")
            }
            dontCloseOnOutsideClick
        >
            <div className="favourite-popup">
                <Controller
                    name="address"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <AddressSearch
                            value={value}
                            onChange={onChange}
                            preselectedAddress={defaultValues.address}
                            label={t("popup.favourite.addressLabel")}
                            placeholder={t(
                                "popup.favourite.addressPlaceholder"
                            )}
                            width="100%"
                            noFavorites
                        />
                    )}
                />

                <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            label={t("popup.favourite.nameLabel")}
                            placeholder={t("popup.favourite.namePlaceholder")}
                            value={value}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <Controller
                    name="type"
                    control={control}
                    render={({ field: { value, onChange } }) =>
                        !props.favouriteType || props.editFavourite ? (
                            <Dropdown
                                value={value}
                                onSelect={(o) =>
                                    onChange(o.value as FavouriteType)
                                }
                                options={[
                                    {
                                        label: t("popup.favourite.typeDropoff"),
                                        value: "dropoff",
                                    },
                                    {
                                        label: t("popup.favourite.typePickup"),
                                        value: "pickup",
                                    },
                                ]}
                                label={t("popup.favourite.typeLabel")}
                                placeholder={t(
                                    "popup.favourite.typePlaceholder"
                                )}
                                width="100%"
                            />
                        ) : (
                            <></>
                        )
                    }
                />
                <Controller
                    name="description"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Textarea
                            label={t("popup.favourite.descriptionLabel")}
                            placeholder={t(
                                "popup.favourite.descriptionPlaceholder"
                            )}
                            width="100%"
                            value={value}
                            onChange={onChange}
                        />
                    )}
                />
                <div className="buttons">
                    {props.editFavourite ? (
                        <>
                            <Button
                                label={t("popup.favourite.deleteLabel")}
                                onClick={() =>
                                    deleteHandler(props.editFavourite!.id)
                                }
                                isLoading={isDeleting}
                                color="secondary"
                                width="49%"
                            />
                            <Button
                                label={t("popup.favourite.submitLabel")}
                                onClick={handleSubmit(
                                    (data) =>
                                        editHandler({
                                            data,
                                            id: props.editFavourite!.id,
                                        }),
                                    onFormError
                                )}
                                isLoading={isEditing}
                                color="secondary"
                                width="49%"
                            />
                        </>
                    ) : (
                        <Button
                            label={t("popup.favourite.submitLabel")}
                            onClick={handleSubmit(
                                (data) => createHandler(data),
                                onFormError
                            )}
                            isLoading={isCreating}
                            color="secondary"
                            width="100%"
                        />
                    )}
                </div>
            </div>
        </Popup>
    );
}

export default FavouritePopup;
