import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Button from "../../components/buttons/Button";
import { ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    mode: "active" | "fulfilled";
};

function NoFleetTours(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="no-fleet-tours">
            <div className="content">
                <h2 className="text-l-m">
                    {props.mode === "active"
                        ? t("fleet.noActiveTours")
                        : t("fleet.noFulfilledTours")}
                </h2>
                <p className="text-s-r">
                    {props.mode === "active"
                        ? t("fleet.noActiveToursDescription")
                        : t("fleet.noFulfilledToursDescription")}
                </p>
                <NavLink to={ROUTE.ActiveFleet}>
                    <Button
                        label={t("fleet.showActiveTours")}
                        color="secondary"
                    />
                </NavLink>
            </div>
        </div>
    );
}

export default NoFleetTours;
