import {
    addMinutes,
    format,
    intervalToDuration,
    isAfter,
    isValid as isValidDate,
    parseISO,
    setHours,
    startOfHour,
    startOfTomorrow,
    subSeconds,
    toDate,
} from "date-fns";

/**
 * Stringifies a Date object to an ISO string.
 */
export function dateToString(
    date: Date | null | undefined,
    options?: {
        clock?: boolean;
        timezone?: boolean;
        onlyTime?: boolean;
    }
) {
    if (!date) return "";

    if (options?.clock) {
        return format(date, "yyyy-MM-dd'T'HH:mm");
    }

    if (options?.timezone) {
        return format(date, "yyyy-MM-dd'T'HH:mmxxx");
    }

    if (options?.onlyTime) {
        return format(date, "HH:mm");
    }

    return format(date, "yyyy-MM-dd");
}

export function formatDateString(
    dateString: string,
    options?: { showTime?: boolean; onlyTime?: boolean }
) {
    if (!dateString) return "";

    const date = stringToDate(dateString);

    if (!date) return "Invalid date";

    if (options?.showTime) return format(date, "yyyy-MM-dd HH:mm");

    if (options?.onlyTime) return format(date, "HH:mm");

    return format(date, "yyyy-MM-dd");
}

/**
 * Parses a string to a Date object. Supports ISO strings and Date.parse.
 * If the timezone is missing, it will be considered as UTC.
 */
export function stringToDate(dateString: string) {
    // Check if the dateString contains timezone information
    const hasTimezone =
        /[+-][0-9]{2}:[0-9]{2}$/.test(dateString) || dateString.endsWith("Z");

    // If there's no timezone info, consider it as UTC
    if (!hasTimezone) {
        dateString += "Z";
    }

    // Try parsing the dateString as an ISO string first
    let date = parseISO(dateString);

    // If the date is invalid, fall back to Date.parse
    if (!isValidDate(date)) {
        date = toDate(Date.parse(dateString));
    }

    if (!isValidDate(date)) {
        return null;
    }

    return date;
}

export function formatDuration(duration: Duration) {
    let hours = duration.hours || 0;
    if (duration.days) {
        hours += duration.days * 24;
    }

    return `${hours ? `${hours}h ` : ""}${
        duration.minutes ? `${duration.minutes}m` : "1m"
    }`;
}

export function formatMinutes(minutes: number) {
    return formatDuration(
        intervalToDuration({
            start: new Date(),
            end: addMinutes(new Date(), minutes),
        })
    );
}

export function formatEta(
    eta: string | undefined,
    options?: { fullDate?: boolean }
) {
    if (!eta) return null;

    const etaDate = stringToDate(eta);

    if (!etaDate) return null;

    const now = subSeconds(new Date(), 30);

    if (isAfter(now, etaDate)) return null;

    return formatDateString(eta, {
        onlyTime: !options?.fullDate,
        showTime: !!options?.fullDate,
    });
}

export function getExpressOrderTimes() {
    const now = new Date();
    const pickupTime = addMinutes(now, 30);
    const deliveryTime = addMinutes(now, 90);

    return {
        pickup: pickupTime,
        delivery: deliveryTime,
    };
}

export function getTomorrowOrderTimes() {
    const tomorrow = startOfTomorrow();
    const pickupTime = startOfHour(setHours(tomorrow, 8));
    const deliveryTime = startOfHour(setHours(tomorrow, 16));

    return {
        pickup: pickupTime,
        delivery: deliveryTime,
    };
}
