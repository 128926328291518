import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { getContacts } from "../../../api/contact";
import { useClickOutside } from "../../../hooks/functionality/useClickOutside";
import ContactPopup from "../../../popups/ContactPopup";
import { Contact } from "../../../shared/types/api";
import ShowingAllMessage from "../../UI/ShowingAllMessage";
import IconButton from "../../buttons/IconButton";
import AddressSearchRow from "../AddressSearch/AddressSearchRow";
import Input from "../Input";
import "./style.scss";

type Props = {
    value: number | null;
    onSelect: (contact: Contact | null) => void;
    placeholder?: string;
    label?: string;
    width?: string;
    noCreate?: boolean;
    hasClearButton?: boolean;
};

function ContactInput(props: Props) {
    const { t } = useTranslation();

    const contactInputRef = useRef<HTMLLabelElement>(null);

    const [searchString, setSearchString] = useState("");
    const [newContactName, setNewContactName] = useState("");
    const [editContact, setEditContact] = useState<Contact | null>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);

    const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);

    const {
        data: contacts,
        status: contactStatus,
        refetch: refetchContacts,
    } = useQuery({
        queryKey: ["contacts"],
        queryFn: async () => {
            const res = await getContacts();
            return res.data;
        },
    });

    useClickOutside([contactInputRef], () => {
        if (!isModalOpen) return;

        setIsModalOpen(false);
        if (!searchString.length) {
            selectContactHandler(null);
            return;
        }

        const chosenContact = contacts?.find(
            (contact) => contact.id === props.value
        );
        selectContactHandler(chosenContact || null);
    });

    function selectContactHandler(contact: Contact | null) {
        setTimeout(() => {
            setIsModalOpen(() => false);
        }, 0);

        if (!contact) {
            props.onSelect(null);
            setSearchString("");
            return;
        }

        props.onSelect(contact);
        setSearchString(contact.name);
    }

    const filterContacts = useCallback(
        (searchString: string, contact: Contact) => {
            const { name, address, phone } = contact;
            const search = searchString.toLowerCase();
            return (
                name.toLowerCase().includes(search) ||
                address?.toLowerCase().includes(search) ||
                phone?.toLowerCase().includes(search)
            );
        },
        []
    );

    const searchHandler = useCallback(
        (searchString: string) => {
            if (contactStatus !== "success") return;

            if (searchString.length > 0) {
                const filtered = contacts.filter((contact) =>
                    filterContacts(searchString, contact)
                );

                setFilteredContacts(filtered);
            } else {
                setFilteredContacts(contacts);
            }
        },
        [contactStatus, contacts, filterContacts]
    );

    useEffect(() => {
        const scrollableEl = document.querySelector<HTMLElement>(
            "#contact-options-wrapper"
        );
        if (!scrollableEl) return;

        const focusedEl = scrollableEl.querySelector<HTMLElement>(
            "[data-focused=true]"
        );
        if (!focusedEl) return;

        scrollableEl.scroll({
            top: focusedEl.offsetTop - scrollableEl.offsetTop - 10,
            behavior: "smooth",
        });
    }, [focusedIndex]);

    useEffect(() => {
        if (contactStatus === "success") {
            setFilteredContacts(contacts);
        }
    }, [contactStatus, contacts]);

    useEffect(() => {
        if (props.value === null) {
            setSearchString("");
            return;
        }

        const chosenContact = contacts?.find(
            (contact) => contact.id === props.value
        );

        if (!chosenContact) {
            setSearchString("");
            return;
        }

        setSearchString(chosenContact.name);
    }, [contacts, props.value]);

    useHotkeys(
        "ArrowDown, ArrowUp, Enter",
        (e) => {
            e.preventDefault();

            switch (e.key) {
                case "ArrowUp":
                    if (focusedIndex > 0) {
                        setFocusedIndex((state) => state - 1);
                    } else {
                        setFocusedIndex(filteredContacts.length - 1);
                    }
                    break;

                case "ArrowDown":
                    if (focusedIndex < filteredContacts.length) {
                        setFocusedIndex((state) => state + 1);
                    } else {
                        setFocusedIndex(0);
                    }
                    break;

                case "Enter":
                    if (
                        focusedIndex < 0 ||
                        focusedIndex > filteredContacts.length
                    )
                        return;
                    selectContactHandler(filteredContacts[focusedIndex]);
                    break;
            }
        },
        {
            enabled: isModalOpen,
            enableOnFormTags: ["input"],
        }
    );

    return (
        <>
            <label
                className="contact-input-wrapper"
                ref={contactInputRef}
                style={{ width: props.width }}
                onClick={() => setIsModalOpen(() => true)}
                onFocus={() => setIsModalOpen(() => true)}
            >
                <Input
                    type="text"
                    icon="address-book"
                    value={searchString}
                    label={props.label}
                    placeholder={props.placeholder}
                    onChange={(v) => {
                        setSearchString(v);
                        searchHandler(v);
                        if (!isModalOpen) {
                            setIsModalOpen(true);
                        }
                    }}
                />
                {props.hasClearButton && (
                    <IconButton
                        icon="cross"
                        style={{
                            position: "absolute",
                            right: "8px",
                            bottom: "5px",
                            backgroundColor: "transparent",
                            color: "var(--text-color)",
                        }}
                        onClick={() => {
                            selectContactHandler(null);
                        }}
                        disabled={!props.value}
                        short
                    />
                )}
                {isModalOpen && (
                    <div className="contact-input-modal">
                        <h3 className="text-s-r">{t("general.contacts")}</h3>
                        <section id="contact-options-wrapper">
                            {filteredContacts.map((contact, i) => (
                                <AddressSearchRow
                                    key={contact.id}
                                    label={contact.name}
                                    onClick={() =>
                                        selectContactHandler(contact)
                                    }
                                    isFocused={i === focusedIndex}
                                    onFavouriteClick={() =>
                                        setEditContact(contact)
                                    }
                                    isFavourite={contact.id !== -1}
                                />
                            ))}
                            {!!searchString && !props.noCreate && (
                                <AddressSearchRow
                                    key="new-contact"
                                    label={`${t(
                                        "general.createContact"
                                    )} ${searchString}`}
                                    onClick={() =>
                                        setNewContactName(searchString)
                                    }
                                    onFavouriteClick={() =>
                                        setNewContactName(searchString)
                                    }
                                />
                            )}
                            {filteredContacts.length === 0 &&
                                props.noCreate && (
                                    <ShowingAllMessage
                                        text={t("general.noContactsFound")}
                                    />
                                )}
                        </section>
                    </div>
                )}
            </label>
            {!props.noCreate && (
                <ContactPopup
                    showPopup={!!newContactName || !!editContact}
                    onClose={() => {
                        setNewContactName("");
                        setEditContact(null);
                    }}
                    onSave={(contact) => {
                        refetchContacts();
                        selectContactHandler(contact || null);
                    }}
                    onDelete={(id) => {
                        refetchContacts();
                        if (id === props.value) selectContactHandler(null);
                    }}
                    name={newContactName}
                    editContact={editContact || undefined}
                />
            )}
        </>
    );
}

export default ContactInput;
