import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import analytics from "../../../shared/services/ga4";
import { ReduxState } from "../../../shared/types/redux";
import Button from "../../buttons/Button";
import "./style.scss";

type Props = {
    error?: Error;
    resetError: () => void;
};

function ErrorComponent(props: Props) {
    const { resetError } = props;

    const { t } = useTranslation();
    const location = useLocation();
    const { user } = useSelector((state: ReduxState) => state.auth);

    useEffect(() => {
        resetError();
    }, [resetError, location.pathname]);

    useEffect(() => {
        if (props.error) {
            analytics.fatalError({ error: props.error, userId: user?.id });
        }
    }, [props.error, user?.id]);

    function reloadPageHandler() {
        props.resetError();
        window.location.reload();
    }

    return (
        <div className="error-component">
            <p className="text-xl-m">{t("general.fatalError.title")}</p>
            <p className="text-l-m">{t("general.fatalError.message")}</p>
            <Button
                color="secondary"
                label={t("general.fatalError.buttonLabel")}
                onClick={reloadPageHandler}
            />
            <p className="text-m-m prompt">
                {t("general.fatalError.contactPrompt")}
            </p>
            <div className="error text-m-m">
                <p>{`Name: ${props.error?.name}`}</p>
                <p>{`Message: ${props.error?.message}`}</p>
                <p>{`Cause: ${props.error?.cause}`}</p>
            </div>
        </div>
    );
}

export default ErrorComponent;
