import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getPublicOrder } from "../../api/order";
import Icon from "../../components/UI/Icon";
import Spinner from "../../components/UI/Spinner";
import Button from "../../components/buttons/Button";
import TextButton from "../../components/buttons/TextButton";
import OrderStatusTimeline from "../../components/widgets/OrderStatusTimeline";
import { useSearchQuery } from "../../hooks/functionality/useSearchQuery";
import PointsMap from "../../maps/PointsMap";
import { IconType } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { formatDateString, formatEta } from "../../shared/utility/date";
import { getOrderStatus } from "../../shared/utility/order";
import { QUERY_PARAM, ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    shouldUseQueryParams?: boolean;
};

function PublicOrder(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const navigate = useNavigate();

    const { publicId: paramPublicId } = useParams<{ publicId: string }>();
    const query = useSearchQuery();

    const [isShowingMore, setIsShowingMore] = useState(false);
    const [publicOrderId, setPublicOrderId] = useState("");

    useEffect(() => {
        if (props.shouldUseQueryParams) {
            const queryPublicId = query.get(QUERY_PARAM.PublicOrderId);
            if (!queryPublicId) {
                navigate("/");
                return;
            }
            setPublicOrderId(queryPublicId);
        } else {
            if (!paramPublicId) {
                navigate("/");
                return;
            }
            setPublicOrderId(paramPublicId);
        }
    }, [navigate, props.shouldUseQueryParams, paramPublicId, query]);

    const { data: publicOrder, status } = useQuery({
        queryKey: ["public-order", publicOrderId],
        queryFn: async ({ queryKey: [, publicOrderId] }) => {
            const { data } = await getPublicOrder(publicOrderId!);
            return data;
        },
        enabled: !!publicOrderId,
    });

    const orderStatus = publicOrder && getOrderStatus(publicOrder);

    const etaString =
        formatEta(publicOrder?.dropoff_eta, {
            fullDate: true,
        }) || "-";

    return (
        <div
            className="public-order"
            style={{
                marginBottom: user ? "20px" : undefined,
            }}
        >
            {status !== "success" ? (
                <Spinner padding="10px" />
            ) : (
                <div
                    className="content"
                    style={{
                        maxHeight: !user ? "calc(100vh - 22px)" : undefined,
                    }}
                >
                    <div className="map-wrapper">
                        <PointsMap
                            mapId="public-order-map"
                            showDirections
                            points={
                                publicOrder &&
                                publicOrder.pickup_lat &&
                                publicOrder.pickup_lng &&
                                publicOrder.dropoff_lat &&
                                publicOrder.dropoff_lng
                                    ? [
                                          {
                                              address:
                                                  publicOrder.pickup_address ||
                                                  "",
                                              location: {
                                                  lat: +publicOrder.pickup_lat,
                                                  lng: +publicOrder.pickup_lng,
                                              },
                                              label: "1",
                                              icon: "plane-departure",
                                          },
                                          {
                                              address:
                                                  publicOrder.dropoff_address ||
                                                  "",
                                              location: {
                                                  lat: +publicOrder.dropoff_lat,
                                                  lng: +publicOrder.dropoff_lng,
                                              },
                                              label: "2",
                                              icon: "race-flag",
                                          },
                                      ]
                                    : []
                            }
                        />
                    </div>

                    <div className="order-info">
                        <OrderStatusTimeline
                            activeStatus={orderStatus!}
                            order={publicOrder}
                        />
                        {!publicOrder.arrived_at && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t("popup.orderInfo.etaLabel")}
                                    </p>
                                    <p className="value text-m-r">
                                        {etaString}
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.weight_kg && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t("popup.orderInfo.weightLabel")}
                                    </p>
                                    <p className="value text-m-r">
                                        {publicOrder.weight_kg} kg
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.additional_info?.number_of_eu_pallets && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t(
                                            "popup.confirmOrder.numberOfPackagesLabel"
                                        )}
                                    </p>
                                    <p className="value text-m-r">
                                        {
                                            publicOrder.additional_info
                                                .number_of_eu_pallets
                                        }
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.weight_of_heaviest_package && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t(
                                            "popup.confirmOrder.weightOfHeaviestPackageLabel"
                                        )}
                                    </p>
                                    <p className="value text-m-r">
                                        {publicOrder.weight_of_heaviest_package}{" "}
                                        kg
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.vehicle && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t("popup.orderInfo.vehicleLabel")}
                                    </p>
                                    <p className="value text-m-r">
                                        <span>
                                            <Icon
                                                type={
                                                    publicOrder.vehicle_type as IconType
                                                }
                                                style={{
                                                    color: "var(--text-color-light)",
                                                }}
                                            />{" "}
                                            {publicOrder.vehicle.friendlyname}
                                        </span>
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.vehicle?.car_name === "large-van" && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t(
                                            "createOrder.additionalVehicleTypeLabel"
                                        )}
                                    </p>
                                    <p className="value text-m-r">
                                        {t(
                                            publicOrder.additional_info
                                                ?.side_loading
                                                ? "general.yes"
                                                : "general.no"
                                        )}
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.earliest_pickup && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t(
                                            "popup.confirmOrder.earliestPickupLabel"
                                        )}
                                    </p>
                                    <p className="value text-m-r">
                                        {formatDateString(
                                            publicOrder.earliest_pickup,
                                            { showTime: true }
                                        )}
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.latest_delivery && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t(
                                            "popup.confirmOrder.latestDeliveryLabel"
                                        )}
                                    </p>
                                    <p className="value text-m-r">
                                        {formatDateString(
                                            publicOrder.latest_delivery,
                                            { showTime: true }
                                        )}
                                    </p>
                                </div>
                            </section>
                        )}
                        {publicOrder.delivery &&
                            !publicOrder.earliest_pickup &&
                            !publicOrder.latest_delivery && (
                                <section>
                                    <div className="info">
                                        <p className="title text-s-r">
                                            {t("popup.orderInfo.deliveryLabel")}
                                        </p>
                                        <p className="value text-m-r">
                                            {publicOrder.delivery.friendlyname}
                                        </p>
                                    </div>
                                </section>
                            )}
                        <section>
                            <div className="info">
                                <p className="title text-s-r">
                                    {t("popup.orderInfo.fromLabel")}
                                </p>
                                <p className="value">
                                    {publicOrder.pickup_address}
                                </p>
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-s-r">
                                    {t("popup.orderInfo.toLabel")}
                                </p>
                                <p className="value">
                                    {publicOrder.dropoff_address}
                                </p>
                            </div>
                        </section>
                        {publicOrder.cargo_content && (
                            <section>
                                <div className="info">
                                    <p className="title text-s-r">
                                        {t("popup.orderInfo.cargoContentLabel")}
                                    </p>
                                    <p className="value text-m-r">
                                        {publicOrder.cargo_content}
                                    </p>
                                </div>
                            </section>
                        )}
                        <section>
                            <div className="info">
                                <p className="title text-s-r">
                                    {t("popup.orderInfo.orderNumberLabel")}
                                </p>
                                <p className="value text-m-r">
                                    {publicOrder.origin_order_id}
                                </p>
                            </div>
                        </section>
                        <section>
                            <div className="info">
                                <p className="title text-s-r">
                                    {t("popup.orderInfo.contactPhoneLabel")}
                                </p>
                                <p className="value text-m-r">
                                    {publicOrder.dropoff_contact_phone}
                                </p>
                            </div>
                        </section>
                        <div className="text-button-wrapper">
                            <TextButton
                                label={
                                    isShowingMore
                                        ? t("popup.confirmOrder.hideMoreLabel")
                                        : t("popup.confirmOrder.showMoreLabel")
                                }
                                color="var(--text-color)"
                                onClick={() =>
                                    setIsShowingMore((state) => !state)
                                }
                            />
                        </div>
                        {isShowingMore && (
                            <>
                                <section>
                                    <div className="info">
                                        <p className="title text-s-r">
                                            {t(
                                                "createOrder.additionalInfoCompany"
                                            )}
                                        </p>
                                        <p className="value text-m-r">
                                            {publicOrder.additional_info
                                                ?.consignee_company || "-"}
                                        </p>
                                    </div>
                                </section>
                                <section>
                                    <div className="info">
                                        <p className="title text-s-r">
                                            {t(
                                                "createOrder.additionalInfoUnloading"
                                            )}
                                        </p>
                                        <p className="value text-m-r">
                                            {publicOrder.additional_info
                                                ?.estimated_offload_time || "-"}
                                        </p>
                                    </div>
                                </section>
                                <section>
                                    <div className="info">
                                        <p className="title text-s-r">
                                            {t(
                                                "createOrder.additionalInfoAdditionalContact"
                                            )}
                                        </p>
                                        {publicOrder.additional_info?.contacts?.map(
                                            (c) => (
                                                <p className="value text-m-r">
                                                    <span>
                                                        {c.consignee_name}
                                                    </span>
                                                    <span>
                                                        {c.consignee_phone}
                                                    </span>
                                                </p>
                                            )
                                        ) || "-"}
                                    </div>
                                </section>
                                <section>
                                    <div className="info">
                                        <p className="title text-s-r">
                                            {t(
                                                "createOrder.additionalInfoSpecificDropoff"
                                            )}
                                        </p>
                                        <p className="value text-m-r">
                                            {publicOrder.additional_info
                                                ?.offload_instructions || "-"}
                                        </p>
                                    </div>
                                </section>
                                <section>
                                    <div className="info">
                                        <p className="title text-s-r">
                                            {t(
                                                "createOrder.additionalInfoTotalCargo"
                                            )}
                                        </p>
                                        {publicOrder.additional_info?.cargo_dimensions?.map(
                                            (cd) => (
                                                <p className="value text-m-r">
                                                    <span>
                                                        {t(
                                                            "createOrder.additionalInfoDropdownLengthLabel"
                                                        )}
                                                        :{cd.length || "-"}
                                                    </span>
                                                    <span>
                                                        {t(
                                                            "createOrder.additionalInfoDropdownWidthLabel"
                                                        )}
                                                        :{cd.width || "-"}
                                                    </span>
                                                    <span>
                                                        {t(
                                                            "createOrder.additionalInfoDropdownHeightLabel"
                                                        )}
                                                        :{cd.height || "-"}
                                                    </span>
                                                    <span>
                                                        {t(
                                                            "createOrder.additionalInfoDropdownWeightLabel"
                                                        )}
                                                        : {cd.weight || "-"}
                                                    </span>
                                                </p>
                                            )
                                        ) || "-"}
                                    </div>
                                </section>
                            </>
                        )}
                        {!user && (
                            <div className="bottom">
                                <NavLink
                                    to={ROUTE.Login}
                                    style={{ width: "100%" }}
                                >
                                    <Button
                                        label={t("register.loginLabel")}
                                        color="secondary"
                                    />
                                </NavLink>
                                <NavLink
                                    to={{
                                        pathname: ROUTE.Register,
                                        search: "?utm_source=public-order",
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    <Button
                                        label={t("register.createAccountLabel")}
                                        color="secondary"
                                    />
                                </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PublicOrder;
