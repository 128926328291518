import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Icon from "../../../components/UI/Icon";
import StatusCircle from "../../../components/UI/StatusCircle";
import Button from "../../../components/buttons/Button";
import environment from "../../../environment";
import { Customer } from "../../../shared/types/api";
import { OrderStatus } from "../../../shared/types/internal";
import { formatDateString } from "../../../shared/utility/date";
import "./style.scss";

type Props = {
    status: OrderStatus;
    orderNumber: string;
    created: string;
    customer?: Customer;
    pickupData: {
        address: string;
        date?: string;
        note?: string;
    };
    dropoffData: {
        address: string;
        date?: string;
        note?: string;
    };
    trackingIds?: {
        pickup: string;
        dropoff: string;
    };
    onViewMore: () => void;
};

function OrderRow(props: Props) {
    const { t } = useTranslation();

    let statusText = "Status";
    switch (props.status) {
        case "created":
            statusText = t("orderHistory.statusCreated");
            break;
        case "verified":
            statusText = t("orderHistory.statusVerified");
            break;
        case "at-pickup":
            statusText = t("orderHistory.statusAtPickup");
            break;
        case "in-progress":
            statusText = t("orderHistory.statusInProgress");
            break;
        case "next-stop":
            statusText = t("orderHistory.statusNextStop");
            break;
        case "unloading":
            statusText = t("orderHistory.statusUnloading");
            break;
        case "fulfilled":
            statusText = t("orderHistory.statusFulfilled");
            break;
    }

    return (
        <tr className="order-row" onClick={props.onViewMore}>
            <td className="text-s-r">
                <div className="status-wrapper">
                    <StatusCircle status={props.status} />
                    {statusText}
                </div>
            </td>
            <td className="text-s-r">{props.orderNumber}</td>
            <td className="text-s-r">
                <div className="created-wrapper">
                    <div
                        className="created-by"
                        title={`${props.customer?.first_name} ${props.customer?.last_name}`}
                    >
                        {`${props.customer?.first_name.charAt(
                            0
                        )}${props.customer?.last_name.charAt(0)}`.toUpperCase()}
                    </div>
                    {formatDateString(props.created)}
                </div>
            </td>
            <td className="text-s-r">
                <div className="location-wrapper">
                    <span className="address" title={props.pickupData.address}>
                        {props.pickupData.note && (
                            <Icon
                                type="comment"
                                style={{
                                    marginRight: "5px",
                                }}
                            />
                        )}
                        {props.pickupData.address}
                    </span>
                    {props.status !== "fulfilled" && (
                        <>
                            {props.trackingIds ? (
                                <a
                                    href={`${environment.motionToolsTrackingUrl}${props.trackingIds.pickup}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Button
                                        label={t("orderHistory.track")}
                                        color="secondary"
                                        short
                                    />
                                </a>
                            ) : (
                                <Button
                                    label={t("orderHistory.track")}
                                    color="secondary"
                                    short
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toast.error(
                                            t("errorMessage.noTrackingCode")
                                        );
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            </td>
            <td className="text-s-r">
                <div className="location-wrapper">
                    <span className="address" title={props.dropoffData.address}>
                        {props.dropoffData.note && (
                            <Icon
                                type="comment"
                                style={{
                                    marginRight: "5px",
                                }}
                            />
                        )}
                        {props.dropoffData.address}
                    </span>
                    {props.status !== "fulfilled" &&
                        (props.trackingIds ? (
                            <a
                                href={`${environment.motionToolsTrackingUrl}${props.trackingIds.dropoff}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Button
                                    label={t("orderHistory.track")}
                                    color="secondary"
                                    short
                                />
                            </a>
                        ) : (
                            <Button
                                label={t("orderHistory.track")}
                                color="secondary"
                                short
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toast.error(
                                        t("errorMessage.noTrackingCode")
                                    );
                                }}
                            />
                        ))}
                </div>
            </td>
            {props.status === "fulfilled" && props.dropoffData.date && (
                <td>
                    <span>{formatDateString(props.dropoffData.date)}</span>
                </td>
            )}
        </tr>
    );
}

export default OrderRow;
