import { ProfileForm } from "../schemas/form";
import axios from "../shared/services/axios";
import { User } from "../shared/types/api";

export function getMe() {
    return axios.get<User>("/customers/me", {
        timeout: 10000,
    });
}

export function getUsersInCompany() {
    return axios.get<{
        users: {
            id: number;
            email: string;
            first_name: string;
            last_name: string;
            location_id: number;
            mt_organization: string;
        }[];
    }>("/customers/me/company");
}

export function updateProfile(data: ProfileForm) {
    return axios.put<string>("/customer_profile", {
        first_name: data.firstName,
        last_name: data.lastName,
        customer_phone: data.phone,
        location_id: data.locationId,
    });
}

export function getCustomerActivationStatus(activationKey: string) {
    return axios.get<{
        status: string;
        information_provided: {
            email: string;
            first_name: string | null;
            last_name: string | null;
            customer_phone: string | null;
        };
    }>("/activate", {
        params: {
            activation_key: activationKey,
        },
    });
}

export function activateCustomer(data: {
    activation_key: string;
    password: string;
    first_name: string;
    last_name: string;
    customer_phone: string;
}) {
    return axios.post<{ status: string }>("/activate", {
        ...data,
    });
}

export function inviteCustomer({
    email,
    source,
}: {
    email: string;
    source: string;
}) {
    return axios.post<{ status: string }>("/invite", {
        email,
        source,
    });
}

export function registerAccount({
    email,
    source,
}: {
    email: string;
    source: string;
}) {
    return axios.post<{ status: string }>("/register", {
        email,
        source,
    });
}
