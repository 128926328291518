import { CSSProperties, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TextButton from "../../buttons/TextButton";
import "./style.scss";

type OrderSection = {
    title: string;
    value?: string | number | null;
    titleStyle?: CSSProperties;
    valueStyle?: CSSProperties;
    valueComponent?: ReactNode;
};

type Props<T> = {
    order: T;
    getSections: (order: T) => OrderSection[][];
    getShowMoreSections?: (order: T) => OrderSection[][];
};

function OrderDetails<T>(props: Props<T>) {
    const { t } = useTranslation();

    const { getSections, getShowMoreSections } = props;

    const [isShowingMore, setIsShowingMore] = useState<Boolean>(false);

    const sections = useMemo(() => {
        return getSections(props.order).filter((section) =>
            section.some(
                ({ value, valueComponent }) => !!value || !!valueComponent
            )
        );
    }, [getSections, props.order]);

    const showMoreSections = useMemo(() => {
        return (
            getShowMoreSections?.(props.order).filter((section) =>
                section.some(
                    ({ value, valueComponent }) => !!value || !!valueComponent
                )
            ) || []
        );
    }, [getShowMoreSections, props.order]);

    return (
        <div className="order-details">
            {sections.map((section, i) => (
                <section key={i}>
                    {section
                        .filter(
                            ({ value, valueComponent }) =>
                                !!value || !!valueComponent
                        )
                        .map(
                            ({
                                title,
                                value,
                                titleStyle,
                                valueStyle,
                                valueComponent,
                            }) => (
                                <div className="info" key={title + value}>
                                    <p
                                        className="title text-s-r"
                                        style={titleStyle}
                                    >
                                        {title}
                                    </p>
                                    {valueComponent ? (
                                        <div className="value">
                                            {valueComponent}
                                        </div>
                                    ) : (
                                        <p
                                            className="value text-m-r"
                                            style={valueStyle}
                                        >
                                            {value}
                                        </p>
                                    )}
                                </div>
                            )
                        )}
                </section>
            ))}
            {showMoreSections.length > 0 && (
                <TextButton
                    label={
                        isShowingMore
                            ? t("popup.confirmOrder.hideMoreLabel")
                            : t("popup.confirmOrder.showMoreLabel")
                    }
                    color="var(--text-color)"
                    onClick={() => setIsShowingMore((state) => !state)}
                />
            )}
            {showMoreSections.length > 0 &&
                isShowingMore &&
                showMoreSections.map((section, i) => (
                    <section key={"more-" + i}>
                        {section
                            .filter(
                                ({ value, valueComponent }) =>
                                    !!value || !!valueComponent
                            )
                            .map(
                                ({
                                    title,
                                    value,
                                    titleStyle,
                                    valueStyle,
                                    valueComponent,
                                }) => (
                                    <div className="info" key={title + value}>
                                        <p
                                            className="title text-s-r"
                                            style={titleStyle}
                                        >
                                            {title}
                                        </p>
                                        {valueComponent ? (
                                            <div className="value">
                                                {valueComponent}
                                            </div>
                                        ) : (
                                            <p
                                                className="value text-m-r"
                                                style={valueStyle}
                                            >
                                                {value}
                                            </p>
                                        )}
                                    </div>
                                )
                            )}
                    </section>
                ))}
        </div>
    );
}

export default OrderDetails;
