import { Driver } from "../../../shared/types/api";
import DriverMarker from "../DriverMarker";

type Props = {
    drivers: Driver[];
};

function DriverMarkers(props: Props) {
    return (
        <>
            {props.drivers
                .filter((d) => d.online)
                .map((driver) => (
                    <DriverMarker key={driver.id} driver={driver} />
                ))}
        </>
    );
}

export default DriverMarkers;
