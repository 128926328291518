import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TextButton from "../../../components/buttons/TextButton";
import { ROUTE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {};

function EmailSent(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="email-sent">
            <h1 className="text-xl-m">{t("emailSent.title")}</h1>
            <hr />
            <p>{t("emailSent.message")}</p>
            <div className="login-wrapper">
                <p className="text-s-r">{t("register.hasAccount")}</p>
                <NavLink to={ROUTE.Login}>
                    <TextButton
                        label={t("register.loginLabel")}
                        color="primary"
                    />
                </NavLink>
            </div>
        </div>
    );
}

export default EmailSent;
