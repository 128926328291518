import { forwardRef, Ref } from "react";
import { IconType } from "../../../shared/types/internal";
import Icon from "../../UI/Icon";
import Spinner from "../../UI/Spinner";
import "./style.scss";

type Props = {
    icon: IconType;
    onClick?: (event: React.MouseEvent) => void;
    style?: React.CSSProperties;
    label?: string | null;
    labelColor?: string;
    labelClass?: string;
    short?: boolean;
    width?: string;
    noSubmit?: boolean;
    disabled?: boolean;
    hug?: boolean;
    isLoading?: boolean;
    fontSize?: string;
    iconColor?: string;
};

const IconButton = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
    const iconButtonClasses = ["icon-button", "waves-effect", "waves-light"];
    const labelClasses = ["text-s-r"];
    if (props.disabled) {
        iconButtonClasses.push("disabled");
    }

    if (props.labelClass) {
        labelClasses.push(props.labelClass);
    }

    if (props.short) {
        iconButtonClasses.push("short");
    } else if (props.hug) {
        // dont add a class for this. Default is hug
    } else {
        iconButtonClasses.push("regular");
    }

    return (
        <button
            ref={ref}
            className="icon-button-wrapper"
            onClick={(e) => {
                if (props.isLoading || props.disabled) {
                    e.preventDefault();
                    return;
                }

                if (props.onClick) props.onClick(e);
            }}
            style={{
                width: props.width || props.style?.width,
            }}
            type={props.noSubmit || props.onClick ? "button" : undefined}
            disabled={props.disabled}
        >
            <div className={iconButtonClasses.join(" ")} style={props.style}>
                {props.isLoading ? (
                    <Spinner />
                ) : (
                    <Icon
                        type={props.icon}
                        fontSize={props.fontSize || "20px"}
                        style={{
                            color: props.iconColor,
                        }}
                    />
                )}
            </div>
            {props.label && (
                <span
                    className={labelClasses.join(" ")}
                    style={{
                        color: props.labelColor,
                    }}
                >
                    {props.label}
                </span>
            )}
        </button>
    );
});

export default IconButton;
