import { useEffect, useState } from "react";
import "./style.scss";

type Props = {
    isActive: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    label?: string;
};

function Switch(props: Props) {
    const switchClasses = ["switch"];
    if (props.disabled) switchClasses.push("disabled");

    const [isActive, setIsActive] = useState(props.isActive);

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    return (
        <label className="switch-wrapper">
            {props.label && (
                <span className="switch-label text-s-r">{props.label}</span>
            )}
            <div className={switchClasses.join(" ")}>
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={(e) => {
                        if (props.disabled) return;
                        setIsActive(e.target.checked);
                        props.onChange(e.target.checked);
                    }}
                />
                <span className="slider" />
            </div>
        </label>
    );
}

export default Switch;
