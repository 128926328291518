import { t } from "i18next";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import Button from "../../buttons/Button";
import Input from "../../inputs/Input";
import "./style.scss";

async function alertPrompt({
    title,
    message,
    conifrmText,
}: {
    title: string;
    message: string;
    conifrmText?: string;
}) {
    let confirmed = false;

    confirmed = await new Promise((resolve, reject) =>
        confirmAlert({
            title,
            message,
            customUI: ({ onClose, title, message }) => {
                const CustomUI = ({
                    onClose,
                    title,
                    message,
                    confirmText,
                }: any) => {
                    const [value, setValue] = useState("");
                    const [isWrongConfrimText, setIsWrongConfrimText] =
                        useState(false);

                    return (
                        <div className="alert-wrapper">
                            <div className="alert">
                                <p className="text-l-m title">{title}</p>
                                <p
                                    style={{
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {message}
                                </p>
                                {confirmText && (
                                    <Input
                                        type="text"
                                        value={value}
                                        onChange={setValue}
                                        invalid={isWrongConfrimText}
                                        short
                                    />
                                )}
                                <div className="buttons">
                                    <Button
                                        color="secondary"
                                        label={t("general.confirm")}
                                        onClick={() => {
                                            if (
                                                conifrmText &&
                                                value !== conifrmText
                                            ) {
                                                setIsWrongConfrimText(true);
                                                return;
                                            }
                                            onClose();
                                            resolve(true);
                                        }}
                                        short
                                        width="120px"
                                    />
                                    <Button
                                        color="secondary"
                                        label={t("general.cancel")}
                                        onClick={() => {
                                            onClose();
                                            resolve(false);
                                        }}
                                        short
                                        width="120px"
                                    />
                                </div>
                            </div>
                        </div>
                    );
                };

                return (
                    <CustomUI
                        onClose={onClose}
                        title={title}
                        message={message}
                    />
                );
            },
        })
    );

    return confirmed;
}

export default alertPrompt;
