import { isAfter } from "date-fns";
import { useTranslation } from "react-i18next";
import "./style.scss";

type Props = {
    style?: React.CSSProperties;
    expiryDate?: Date;
};

function NewIcon(props: Props) {
    const { t } = useTranslation();

    if (props.expiryDate && isAfter(new Date(), props.expiryDate)) return null;

    return (
        <span className="new-icon text-m-m" style={props.style}>
            {t("general.new")}
        </span>
    );
}

export default NewIcon;
