import { getHours } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocale } from "../../../shared/utility/misc";
import Spinner from "../../UI/Spinner";
import Icon from "../../UI/Icon";
import "./style.scss";

type Props = {};

const threeMinutes = 3 * 60 * 1000;

function AvailableVehicles(props: Props) {
    const { t } = useTranslation();

    const [updatedAt, setUpdatedAt] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [hasAvailableVehicles, setHasAvailableVehicles] = useState(true);

    useEffect(() => {
        async function availabledVehiclesFn() {
            setIsLoading(true);
            const promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve(null);
                }, 1000);
            });
            await promise;
            setIsLoading(false);

            const rightNow = new Date();

            setUpdatedAt(rightNow);

            const hourRightNow = getHours(rightNow);

            setHasAvailableVehicles(hourRightNow < 23 && hourRightNow > 3);
        }

        availabledVehiclesFn();

        const interval = setInterval(availabledVehiclesFn, threeMinutes);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="available-vehicles">
            <div>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Icon
                        type={hasAvailableVehicles ? "check" : "cross"}
                        style={{
                            color: hasAvailableVehicles
                                ? "var(--text-color-alt)"
                                : "var(--error-color)",
                        }}
                    />
                )}
            </div>
            <div className="texts">
                <p>
                    {hasAvailableVehicles
                        ? t("createOrder.availableVehicles")
                        : t("createOrder.noAvailableVehicles")}
                </p>
                <p className="updated-at">
                    {t("createOrder.updatedAt")}{" "}
                    <span>
                        {updatedAt.toLocaleTimeString(getLocale(), {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                        })}
                    </span>
                </p>
            </div>
        </div>
    );
}

export default AvailableVehicles;
