import { Draggable } from "@hello-pangea/dnd";
import Icon from "../../../components/UI/Icon";
import { OrderDraft } from "../../../shared/types/api";
import "./style.scss";

type Props = {
    orderDraft: OrderDraft;
    index: number;
    onClick?: () => void;
};

function OrderDraftCard(props: Props) {
    return (
        <Draggable
            key={props.orderDraft.id}
            draggableId={props.orderDraft.id.toString()}
            index={props.index}
        >
            {(provided) => (
                <div
                    className="order-draft-card"
                    ref={provided.innerRef}
                    onClick={props.onClick}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <section className="text-m-m">
                        {props.orderDraft.to_location}
                        {props.orderDraft.file_name && <Icon type="image" />}
                    </section>
                    {props.orderDraft.tag && (
                        <section
                            style={{
                                color: "var(--text-color-light)",
                            }}
                        >
                            <span>
                                <Icon type="hashtag" /> {props.orderDraft.tag}
                            </span>
                        </section>
                    )}
                    <section
                        style={{
                            color: "var(--text-color-light)",
                        }}
                    >
                        <span
                            className="order-number"
                            title={props.orderDraft.origin_order_number || ""}
                        >
                            {props.orderDraft.origin_order_number}
                        </span>
                        <span>
                            {props.orderDraft.source === "ai"
                                ? props.orderDraft.source.toUpperCase()
                                : props.orderDraft.source}{" "}
                            <Icon type="arrow-bracket" />
                        </span>
                    </section>
                </div>
            )}
        </Draggable>
    );
}

export default OrderDraftCard;
