import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setExternalCarrierForOrder } from "../../api/order";
import { setStopDraftsAsExternal } from "../../api/stop-draft";
import Button from "../../components/buttons/Button";
import TextButton from "../../components/buttons/TextButton";
import DateInput from "../../components/inputs/DateInput";
import Dropdown from "../../components/inputs/Dropdown";
import ExternalCarrierInput from "../../components/inputs/ExternalCarrierInput";
import Input from "../../components/inputs/Input";
import OrderDetails from "../../components/widgets/OrderDetails";
import alertPrompt from "../../components/widgets/alertPrompt";
import Popup from "../../hoc/Popup";
import PointsMap from "../../maps/PointsMap";
import {
    MarkAsExternalStopsForm,
    markAsExternalStopsForm,
} from "../../schemas/form";
import { StopDraft } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { dateToString, stringToDate } from "../../shared/utility/date";
import {
    convertToNumberWithSpaces,
    onFormError,
} from "../../shared/utility/misc";
import { tourTimes } from "../../shared/values/dropdown";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onSubmit: (stopIds: number[]) => void;
    pickup: StopDraft;
    dropoff: StopDraft;
    preselectedDate?: string;
};

function ExternalStopsPopup(props: Props) {
    const { t } = useTranslation();

    const { user } = useSelector((state: ReduxState) => state.auth);

    const defaultValues = useMemo(
        () => ({
            date: props.dropoff.date_tooltip || props.preselectedDate || "",
            time: props.dropoff.time_tooltip || "",
            externalCarrierId: "",
            reference: "",
        }),
        [
            props.dropoff.date_tooltip,
            props.dropoff.time_tooltip,
            props.preselectedDate,
        ]
    );

    const {
        control,
        handleSubmit,
        reset: resetInputs,
        setValue,
        getValues,
        watch,
    } = useForm<MarkAsExternalStopsForm>({
        resolver: zodResolver(markAsExternalStopsForm),
        defaultValues,
    });

    const selectedDate = watch("date");
    const selectedTime = watch("time");
    const selectedReference = watch("reference");

    const [showDetails, setShowDetails] = useState<Boolean>();

    useEffect(() => {
        resetInputs(defaultValues);
    }, [defaultValues, resetInputs]);

    const {
        mutate: sendStopsToExternalCarrier,
        isPending: isSendingStopsToExternalCarrier,
    } = useMutation({
        mutationFn: async (data: MarkAsExternalStopsForm) => {
            if (props.pickup.date_tooltip || props.dropoff.date_tooltip) {
                if (
                    props.pickup.date_tooltip !== data.date ||
                    props.dropoff.date_tooltip !== data.date
                ) {
                    if (
                        !(await alertPrompt({
                            title: t("activeFleet.dateMismatchExternalTitle"),
                            message: t(
                                "activeFleet.dateMismatchExternalMessage"
                            ),
                        }))
                    ) {
                        throw new Error("date-mismatch");
                    }
                }
            }

            const res = await setStopDraftsAsExternal({
                pickup: props.pickup,
                dropoff: props.dropoff,
                date: data.date,
                time: data.time,
            });

            await setExternalCarrierForOrder({
                orderId: res.data.id,
                externalCarrierId: +data.externalCarrierId,
                reference: data.reference,
                date: data.date,
                time: data.time,
            });
        },
        onSuccess: () => {
            toast.success(t("successMessages.externalStopsSaved"));
            resetInputs();
            props.onSubmit([props.pickup.id, props.dropoff.id]);
            props.onClose();
        },
        onError: (error) => {
            if (error.message === "date-mismatch") return;
            toast.error(t("errorMessages.externalStopsSave"));
        },
    });

    const { mutate: markStopsAsExternal, isPending: isMarkAsExternalPending } =
        useMutation({
            mutationFn: async (data: { date: string; time?: string }) => {
                if (props.pickup.date_tooltip || props.dropoff.date_tooltip) {
                    if (
                        props.pickup.date_tooltip !== data.date ||
                        props.dropoff.date_tooltip !== data.date
                    ) {
                        if (
                            !(await alertPrompt({
                                title: t(
                                    "activeFleet.dateMismatchExternalTitle"
                                ),
                                message: t(
                                    "activeFleet.dateMismatchExternalMessage"
                                ),
                            }))
                        ) {
                            throw new Error("date-mismatch");
                        }
                    }
                }

                await setStopDraftsAsExternal({
                    pickup: props.pickup,
                    dropoff: props.dropoff,
                    date: data.date,
                    time: data.time,
                });
            },
            onSuccess: () => {
                toast.success(t("successMessages.externalStopsMarked"));
                props.onSubmit([props.pickup.id, props.dropoff.id]);
                props.onClose();
            },
            onError: (error) => {
                if (error.message === "date-mismatch") return;
                toast.error(t("errorMessages.externalStopsMark"));
            },
        });

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.externalStops.title")}
            dontCloseOnOutsideClick
            overlayComponent={
                <PointsMap
                    showDirections
                    points={
                        props.pickup.lat &&
                        props.pickup.lng &&
                        props.dropoff.lat &&
                        props.dropoff.lng
                            ? [
                                  {
                                      address: props.pickup.to_location,
                                      location: {
                                          lat: +props.pickup.lat,
                                          lng: +props.pickup.lng,
                                      },
                                      label: "1",
                                      icon: "plane-departure",
                                  },
                                  {
                                      address: props.dropoff.to_location,
                                      location: {
                                          lat: +props.dropoff.lat,
                                          lng: +props.dropoff.lng,
                                      },
                                      label: "2",
                                      icon: "race-flag",
                                  },
                              ]
                            : []
                    }
                    mapId="external-map"
                />
            }
        >
            <form
                className="external-stops-popup"
                onSubmit={handleSubmit(
                    (d) => sendStopsToExternalCarrier(d),
                    onFormError
                )}
            >
                <section>
                    <Controller
                        control={control}
                        name="date"
                        render={({ field: { value, onChange } }) => (
                            <DateInput
                                value={stringToDate(value)}
                                onChange={(d) => onChange(dateToString(d))}
                                showAllDates
                                ignoreUnselectableDates
                                width="100%"
                                label={t("popup.externalStops.date")}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="time"
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                value={value}
                                onSelect={(option) => onChange(option.value)}
                                options={tourTimes}
                                width="100%"
                                label={t("popup.externalStops.time")}
                            />
                        )}
                    />
                </section>
                <section>
                    <TextButton
                        label={t("popup.externalStops.dontSend")}
                        onClick={() =>
                            markStopsAsExternal({
                                date: getValues("date"),
                                time: getValues("time"),
                            })
                        }
                        isLoading={isMarkAsExternalPending}
                        color="var(--text-color)"
                        style={{
                            textDecoration: isMarkAsExternalPending
                                ? "none"
                                : "underline",
                            marginLeft: "auto",
                        }}
                    />
                </section>
                <section>
                    <Controller
                        control={control}
                        name="externalCarrierId"
                        render={({ field: { value, onChange } }) => (
                            <ExternalCarrierInput
                                value={+value}
                                onSelect={(carrier) => {
                                    onChange(carrier?.id.toString() || "");
                                    setValue(
                                        "reference",
                                        carrier?.reference || ""
                                    );
                                }}
                                width="100%"
                                label={t("createTour.externalCarrier")}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="reference"
                        render={({ field: { value, onChange } }) => (
                            <Input
                                type="text"
                                value={value}
                                onChange={onChange}
                                label={t("createTour.externalReference")}
                                placeholder={t(
                                    "createTour.projectReferencePlaceholder"
                                )}
                                width="100%"
                            />
                        )}
                    />
                </section>
                <section className="bottom">
                    <Button
                        color="secondary"
                        label={t("popup.externalStops.sendToCarrier")}
                        isLoading={isSendingStopsToExternalCarrier}
                        width="100%"
                    />
                </section>
                <div className="description">
                    <p className="title text-l-m">
                        {t("popup.externalStops.descriptionTitle")}
                    </p>
                    <p className="content">
                        {t("popup.externalStops.descriptionContent")}
                    </p>
                </div>
                <div className="mail-details">
                    <TextButton
                        label={
                            showDetails
                                ? t("popup.externalStops.hideDetails")
                                : t("popup.externalStops.showDetails")
                        }
                        onClick={() => setShowDetails(!showDetails)}
                        color="var(--text-color)"
                        style={{
                            textDecoration: "underline",
                        }}
                    />
                    {showDetails && (
                        <OrderDetails
                            order={{
                                pickup: props.pickup,
                                dropoff: props.dropoff,
                            }}
                            getSections={({ pickup, dropoff }) => [
                                [
                                    {
                                        title: t(
                                            "createOrder.deliveryDateLabel"
                                        ),
                                        value: `${selectedDate} ${selectedTime}`,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.externalStops.userPhone"
                                        ),
                                        value: user?.customer_phone || "-",
                                    },
                                    {
                                        title: t(
                                            "popup.externalStops.userEmail"
                                        ),
                                        value: user?.email || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createTour.externalReference"
                                        ),
                                        value: selectedReference || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.fromLabel"),
                                        value: pickup.to_location,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.pickupContactPhoneLabel"
                                        ),
                                        value: pickup.phone || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.toLabel"),
                                        value: dropoff.to_location,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.contactPhoneLabel"
                                        ),
                                        value: dropoff.phone || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.orderNumberLabel"
                                        ),
                                        value: pickup.order_number,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.weightLabel"),
                                        value: pickup.weight_kg
                                            ? convertToNumberWithSpaces(
                                                  pickup.weight_kg,
                                                  "kg"
                                              )
                                            : "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.cargoContentLabel"
                                        ),
                                        value: pickup.cargo_content || "-",
                                    },
                                ],
                            ]}
                        />
                    )}
                </div>
            </form>
        </Popup>
    );
}

export default ExternalStopsPopup;
