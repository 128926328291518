import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useMemo, useState } from "react";

function useDirections({
    hide,
    path,
}: {
    hide: boolean;
    path: google.maps.LatLngLiteral[];
}) {
    const map = useMap();
    const mapsLibrary = useMapsLibrary("maps");
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionsService] =
        useState<google.maps.DirectionsService | null>(null);
    const [directionsRenderer, setDirectionsRenderer] =
        useState<google.maps.DirectionsRenderer | null>(null);
    const [pathDurationInMinutes, setPathDurationInMinutes] = useState(0);

    const arrowIcon = useMemo(
        () => ({
            path: google.maps.SymbolPath?.FORWARD_OPEN_ARROW || "",
            strokeOpacity: 1,
            scale: 3,
            strokeColor: "#0f0f0f",
        }),
        []
    );

    useEffect(() => {
        if (!routesLibrary || !map) return;

        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(
            new routesLibrary.DirectionsRenderer({
                preserveViewport: true,
                suppressMarkers: true,
                polylineOptions: {
                    strokeColor: "#0f0f0f",
                    strokeOpacity: 0.4,
                    strokeWeight: 5,
                    icons: [
                        {
                            icon: arrowIcon,
                            offset: "50%",
                        },
                    ],
                },
            })
        );
    }, [routesLibrary, map, arrowIcon]);

    useEffect(() => {
        if (
            !map ||
            !mapsLibrary ||
            !directionsRenderer ||
            !directionsService ||
            hide
        )
            return;

        if (path.length > 25) {
            const polyline = new mapsLibrary.Polyline({
                path,
                map,
                strokeColor: "#0f0f0f",
                strokeOpacity: 0.4,
                strokeWeight: 5,
                icons: [
                    {
                        icon: arrowIcon,
                        offset: "50%",
                    },
                ],
            });

            setPathDurationInMinutes(0);

            return () => polyline.setMap(null);
        }

        directionsService
            .route({
                origin: path[0],
                waypoints: path.slice(1, path.length - 1).map((point) => ({
                    location: point,
                    stopover: true,
                })),
                destination: path[path.length - 1],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                provideRouteAlternatives: false,
            })
            .then((response) => {
                setPathDurationInMinutes(
                    response.routes[0]?.legs.reduce(
                        (acc, leg) => acc + (leg.duration?.value || 0),
                        0
                    ) / 60
                );

                directionsRenderer.setDirections(response);
                directionsRenderer.setMap(map);
            });

        return () => directionsRenderer.setMap(null);
    }, [
        map,
        mapsLibrary,
        directionsRenderer,
        directionsService,
        path,
        hide,
        arrowIcon,
    ]);

    return { pathDurationInMinutes };
}

export default useDirections;
