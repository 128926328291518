import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import DotsModal, { DotsModalContent } from "../../../components/UI/DotsModal";
import { StopDraft } from "../../../shared/types/api";
import "./style.scss";

type Props = {
    stops: {
        pickup: StopDraft;
        dropoff: StopDraft;
    };
    modalControls?: DotsModalContent[];
    onClick?: () => void;
};

function ExternalCard(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="external-card" onClick={props.onClick}>
            <div className="controls">
                <p className="text-m-m" title={props.stops.pickup.order_number}>
                    {props.stops.pickup.order_number}
                </p>
                {props.stops.pickup.color && (
                    <div
                        className="circle"
                        title={t("fleetPlanner.colorPickup")}
                        style={{
                            backgroundColor: props.stops.pickup.color,
                        }}
                    />
                )}
                {props.stops.dropoff.color && (
                    <div
                        className="circle"
                        title={t("fleetPlanner.colorDropoff")}
                        style={{
                            backgroundColor: props.stops.dropoff.color,
                        }}
                    />
                )}
                {props.modalControls && (
                    <DotsModal
                        content={props.modalControls}
                        style={{
                            marginLeft: "auto",
                        }}
                    />
                )}
            </div>

            <div className="top">
                <p
                    title={`${t("orderHistory.pickUp")}: ${
                        props.stops.pickup.to_location
                    }`}
                >
                    {t("orderHistory.pickUp")}
                    {": "}
                    {props.stops.pickup.to_location}
                </p>
                <p
                    title={`${t("orderHistory.dropOff")}: ${
                        props.stops.dropoff.to_location
                    }`}
                >
                    {t("orderHistory.dropOff")}
                    {": "}
                    {props.stops.dropoff.to_location}
                </p>
                {props.stops.pickup.date_tooltip && (
                    <p>
                        {`${props.stops.pickup.date_tooltip || ""} ${
                            props.stops.pickup.time_tooltip || ""
                        }`}
                    </p>
                )}
            </div>
            {props.stops.pickup.cargo_content && (
                <section>
                    <span className="text-s-r">
                        {props.stops.pickup.cargo_content}
                    </span>
                </section>
            )}

            {props.stops.pickup.order?.carrier_delivery_requests?.map(
                (dr, i) => (
                    <Fragment key={i}>
                        <section>
                            <span className="text-m-m">
                                {t("fleetPlanner.assignedCarrier")}
                            </span>
                            <span className="text-s-r">
                                {dr.external_carrier?.name}
                            </span>
                        </section>
                        <section>
                            <span className="text-m-m">
                                {t("orderHistory.status")}
                            </span>
                            <span
                                className="text-s-r"
                                style={{
                                    color: dr.accepted_at
                                        ? "var(--text-color-alt)"
                                        : dr.declined_at
                                        ? "var(--error-color)"
                                        : undefined,
                                }}
                            >
                                {dr.accepted_at
                                    ? t("externalOrder.accepted")
                                    : dr.declined_at
                                    ? t("externalOrder.declined")
                                    : t("externalOrder.pending")}
                            </span>
                        </section>
                    </Fragment>
                )
            )}
        </div>
    );
}

export default ExternalCard;
