import Icon from "../Icon";
import TooltipModal from "../TooltipModal";
import "./style.scss";

type Props = {
    content: string;
    id?: string;
};

function Tooltip(props: Props) {
    return (
        <div className="tooltip-wrapper" id={props.id}>
            <TooltipModal className="tooltip" content={props.content} />
            <Icon type="circle-info" fontSize="12px" />
        </div>
    );
}

export default Tooltip;
