import { ChartData, ChartDataset, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";

import "./style.scss";

type Props = {
    title?: string;
    labels: string[];
    data: ChartDataset<"bar">[];
    options?: ChartOptions<"bar">;
    description?: string;
};

function BarChart(props: Props) {
    const options: ChartOptions<"bar"> = {
        animation: {
            duration: 0,
        },
        plugins: {
            tooltip: {
                backgroundColor: "#0f0f0f",
                ...props.options?.plugins?.tooltip,
            },
        },
        ...props.options,
    };

    const data: ChartData<"bar"> = {
        labels: props.labels,
        datasets: props.data.map((ds) => ({
            backgroundColor: "#47e793",
            borderRadius: 5,
            datalabels: {
                display: false,
            },
            //Line styling
            borderColor: "#47e793",
            pointBorderColor: "#0f0f0f",
            pointBackgroundColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointBorderWidth: 2,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 3,
            pointRadius: 3,
            lineTension: 0.3,
            ...ds,
        })),
    };

    return (
        <div className="bar-chart">
            {props.title && <p className="text-l-m">{props.title}</p>}
            {props.description && <p>{props.description}</p>}
            <Bar data={data} options={options} />
        </div>
    );
}

export default BarChart;
