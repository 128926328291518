import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
    getExternalCarrierOrder,
    updateExternalCarrierOrder,
} from "../../api/order";
import Spinner from "../../components/UI/Spinner";
import Button from "../../components/buttons/Button";
import OrderDetails from "../../components/widgets/OrderDetails";
import PointsMap from "../../maps/PointsMap";
import { ReduxState } from "../../shared/types/redux";
import { convertToNumberWithSpaces } from "../../shared/utility/misc";
import { QUERY_PARAM, ROUTE } from "../../shared/values/enums";
import "./style.scss";
import { useSearchQuery } from "../../hooks/functionality/useSearchQuery";

type Props = {
    shouldUseQueryParams?: boolean;
};

function ExternalCarrierOrder(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const navigate = useNavigate();
    const { externalId: paramExternalId } = useParams<{ externalId: string }>();
    const query = useSearchQuery();

    const [externalOrderId, setExternalOrderId] = useState("");

    useEffect(() => {
        if (props.shouldUseQueryParams) {
            const queryExternalId = query.get(QUERY_PARAM.ExternalOrderId);
            if (!queryExternalId) {
                navigate("/");
                return;
            }
            setExternalOrderId(queryExternalId);
        } else {
            if (!paramExternalId) {
                navigate("/");
                return;
            }

            setExternalOrderId(paramExternalId);
        }
    }, [navigate, paramExternalId, props.shouldUseQueryParams, query]);

    const {
        data: external,
        status,
        refetch,
    } = useQuery({
        queryKey: ["external-order", externalOrderId],
        queryFn: async ({ queryKey: [, externalOrderId] }) => {
            const { data } = await getExternalCarrierOrder(externalOrderId!);
            return data;
        },
        enabled: !!externalOrderId,
    });

    const { mutate: acceptOrderHandler } = useMutation({
        mutationFn: async (externalId: string) => {
            await updateExternalCarrierOrder(externalId, true);
        },
        onSuccess: async () => {
            toast.success(t("successMessage.acceptExternalOrder"));
            await refetch();
        },
        onError: () => {
            toast.error(t("errorMessages.acceptExternalOrderFailed"));
        },
    });

    useEffect(() => {
        if (status !== "success" || !externalOrderId) return;

        if (external.accepted_at) return;

        acceptOrderHandler(externalOrderId);
    }, [acceptOrderHandler, externalOrderId, external?.accepted_at, status]);

    return (
        <div
            className="external-order"
            style={{
                marginBottom: user ? "20px" : undefined,
            }}
        >
            {status !== "success" ? (
                <Spinner padding="10px" />
            ) : (
                <div
                    className="content"
                    style={{
                        maxHeight: !user ? "calc(100vh - 22px)" : undefined,
                    }}
                >
                    <div className="map-wrapper">
                        <PointsMap
                            mapId="external-order-map"
                            showDirections
                            points={
                                external &&
                                external.order.pickup_lat &&
                                external.order.pickup_lng &&
                                external.order.dropoff_lat &&
                                external.order.dropoff_lng
                                    ? [
                                          {
                                              address:
                                                  external.order
                                                      .pickup_address || "",
                                              location: {
                                                  lat: +external.order
                                                      .pickup_lat,
                                                  lng: +external.order
                                                      .pickup_lng,
                                              },
                                              label: "1",
                                              icon: "plane-departure",
                                          },
                                          {
                                              address:
                                                  external.order
                                                      .dropoff_address || "",
                                              location: {
                                                  lat: +external.order
                                                      .dropoff_lat,
                                                  lng: +external.order
                                                      .dropoff_lng,
                                              },
                                              label: "2",
                                              icon: "race-flag",
                                          },
                                      ]
                                    : []
                            }
                        />
                    </div>
                    <div className="order-info">
                        <p className="title text-l-m">
                            {t("externalOrder.description")}
                        </p>
                        <OrderDetails
                            order={external.order}
                            getSections={(order) => [
                                [
                                    {
                                        title: t(
                                            "createOrder.deliveryDateLabel"
                                        ),
                                        value: `${order.scheduled_date} ${order.scheduled_time}`,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.fromLabel"),
                                        value: order.pickup_address,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.pickupContactPhoneLabel"
                                        ),
                                        value:
                                            order.pickup_contact_phone || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.toLabel"),
                                        value: order.dropoff_address,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.contactPhoneLabel"
                                        ),
                                        value:
                                            order.dropoff_contact_phone || "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.orderNumberLabel"
                                        ),
                                        value: order.origin_order_id,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.weightLabel"),
                                        value: order.weight_kg
                                            ? convertToNumberWithSpaces(
                                                  order.weight_kg,
                                                  "kg"
                                              )
                                            : "-",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.cargoContentLabel"
                                        ),
                                        value: order.cargo_content || "-",
                                    },
                                ],
                            ]}
                        />

                        {!user && (
                            <div className="bottom">
                                <NavLink
                                    to={ROUTE.Login}
                                    style={{ width: "100%" }}
                                >
                                    <Button
                                        label={t("register.loginLabel")}
                                        color="secondary"
                                    />
                                </NavLink>
                                <NavLink
                                    to={{
                                        pathname: ROUTE.Register,
                                        search: "?utm_source=external-order",
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    <Button
                                        label={t("register.createAccountLabel")}
                                        color="secondary"
                                    />
                                </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ExternalCarrierOrder;
