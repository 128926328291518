import { Map } from "@vis.gl/react-google-maps";
import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../shared/types/redux";
import { DEFAULT_CENTER } from "../../../shared/values/enums";
import "./style.scss";

type Props = {
    onClick?: () => void;
    label: string;
    style?: CSSProperties;
};

function MapButton(props: Props) {
    const { user } = useSelector((state: ReduxState) => state.auth);

    return (
        <button
            className="map-button"
            type="button"
            onClick={props.onClick}
            style={props.style}
        >
            <Map
                mapId={"map-button"}
                center={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                zoom={10}
                disableDefaultUI
            >
                <div className="cover">
                    <div className="cover-button text-m-m waves-effect">
                        {props.label}
                    </div>
                </div>
            </Map>
        </button>
    );
}

export default MapButton;
