import { intervalToDuration } from "date-fns";
import { Order } from "../types/api";
import { DropdownOption, OrderStatus } from "../types/internal";
import { orderTimes } from "../values/dropdown";
import { QUERY_PARAM, ROUTE } from "../values/enums";
import { formatDateString } from "./date";

export function getOrderStatus(order: Order) {
    let status: OrderStatus = "created";

    if (order.verified_at) {
        status = "verified";
    }
    if (order.arrived_at_pickup_at) {
        status = "at-pickup";
    }
    if (order.driver_picked_up_at) {
        status = "in-progress";
    }
    if (order.dropoff_stop_started_at) {
        status = "next-stop";
    }
    if (order.arrived_at) {
        status = "unloading";
    }
    if (order.fulfilled_at) {
        status = "fulfilled";
    }

    return status;
}

export function getOrderStatusTimestamps(order: Order | null) {
    const timestamps: Record<OrderStatus, string | undefined> = {
        created: order?.created_at_utc
            ? formatDateString(order.created_at_utc, { showTime: true })
            : undefined,
        verified: order?.verified_at
            ? formatDateString(order.verified_at, { showTime: true })
            : undefined,
        "at-pickup": order?.arrived_at_pickup_at
            ? formatDateString(order.arrived_at_pickup_at, { showTime: true })
            : undefined,
        "in-progress": order?.driver_picked_up_at
            ? formatDateString(order.driver_picked_up_at, { showTime: true })
            : undefined,
        "next-stop": order?.dropoff_stop_started_at
            ? formatDateString(order.dropoff_stop_started_at, {
                  showTime: true,
              })
            : undefined,
        unloading: order?.arrived_at
            ? formatDateString(order.arrived_at, { showTime: true })
            : undefined,
        fulfilled: order?.fulfilled_at
            ? formatDateString(order.fulfilled_at, { showTime: true })
            : undefined,
    };

    return timestamps;
}

export function getOrderDurations(order: Order | null) {
    const durations: {
        loadingTime: Duration | null;
        drivingTime: Duration | null;
        offloading: Duration | null;
    } = {
        loadingTime: null,
        drivingTime: null,
        offloading: null,
    };

    if (!order) return durations;

    if (order.arrived_at_pickup_at && order.driver_picked_up_at) {
        durations.loadingTime = intervalToDuration({
            start: new Date(order.arrived_at_pickup_at),
            end: new Date(order.driver_picked_up_at),
        });
    }

    if (order.driver_picked_up_at && order.arrived_at) {
        durations.drivingTime = intervalToDuration({
            start: new Date(order.driver_picked_up_at),
            end: new Date(order.arrived_at),
        });
    }

    if (order.arrived_at && order.fulfilled_at) {
        durations.offloading = intervalToDuration({
            start: new Date(order.arrived_at),
            end: new Date(order.fulfilled_at),
        });
    }

    return durations;
}

export function getOrderTimes(type: "pickup" | "dropoff"): DropdownOption[] {
    const filteredOrderTimes = orderTimes.filter((ot) => {
        if (type === "pickup") {
            return ot.value !== "21:30" && ot.value !== "22:00";
        }
        if (type === "dropoff") {
            return ot.value !== "06:00" && ot.value !== "06:30";
        }
        return true;
    });

    return filteredOrderTimes;
}

export function copyPublicOrderUrl(publicOrderId: string) {
    return navigator.clipboard.writeText(
        `${window.location.origin}${ROUTE.PublicOrder}?${QUERY_PARAM.PublicOrderId}=${publicOrderId}`
    );
}
