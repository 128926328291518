import "./style.scss";

type Props = {
    onClick?: () => void;
    active?: boolean;
};

function HamburgerToggle(props: Props) {
    const hamburgerToggleClasses = ["hamburger-toggle"];
    if (props.active) {
        hamburgerToggleClasses.push("active");
    }

    return (
        <div
            className={hamburgerToggleClasses.join(" ")}
            onClick={props.onClick}
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

export default HamburgerToggle;
