import { IconType } from "../../../shared/types/internal";
import Icon from "../Icon";
import "./style.scss";

type Props = {
    text: string;
    icon?: IconType;
    className?: string;
    style?: React.CSSProperties;
    iconColor?: string;
    iconStyle?: React.CSSProperties;
    textColor?: string;
};

function Subtitle(props: Props) {
    const subtitleClasses = ["subtitle", "text-s-r"];

    if (props.icon) {
        subtitleClasses.push("has-icon");
    }

    if (props.className) {
        subtitleClasses.push(props.className);
    }

    return (
        <p
            className={subtitleClasses.join(" ")}
            style={{
                ...props.style,
                color: props.textColor,
            }}
        >
            {props.icon ? (
                <Icon
                    style={{
                        ...props.iconStyle,
                        color: props.iconColor,
                    }}
                    type={props.icon}
                />
            ) : null}
            {props.text}
        </p>
    );
}

export default Subtitle;
