import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSlider from "react-slider";
import { numberRegex } from "../../../shared/utility/regex";
import Icon from "../../UI/Icon";
import Tooltip from "../../UI/Tooltip";
import "./style.scss";

type Props = {
    value: string;
    onChange: (value: string) => void;
    steps: number[];
    max: number;
    min?: number;
    label?: string;
    labelColor?: string;
    tooltip?: string;
    width?: string;
    unitLabel?: string;
    isValid?: boolean;
};

function ExpoSlider(props: Props) {
    const { t } = useTranslation();

    const expoSliderClasses = ["expo-slider-wrapper"];
    if (props.isValid) {
        expoSliderClasses.push("valid");
    }

    const [sliderValue, setSliderValue] = useState(0);
    const [value, setValue] = useState(props.value);

    /**
     * "exponential step" curry function returns a tuple where:
     *  [0] is the number of inputs we need our slider to have
     *  [1] is our output transform function
     * */
    const scaleTransform = useCallback(
        (
            min: number,
            max: number,
            intervals: number[]
        ): [number, (input: number) => number] => {
            //determine how many "points" we need
            let distributions = intervals.length;
            let descretePoints = Math.ceil(
                (max - min) /
                    intervals.reduce(
                        (total, step) => total + step / distributions,
                        0
                    )
            );

            return [
                descretePoints,
                (input: number) => {
                    let stepTransforms = intervals.map((s, i) => {
                        let setCount = Math.min(
                            Math.ceil(
                                input - (descretePoints * i) / distributions
                            ),
                            Math.round(descretePoints / distributions)
                        );
                        return setCount > 0 ? setCount * s : 0;
                    });

                    let lastStep = 0;
                    let out =
                        Math.round(
                            stepTransforms.reduce((total, num, i) => {
                                if (num) {
                                    lastStep = i;
                                }
                                return total + num;
                            })
                        ) + min;

                    let currentUnit = intervals[lastStep];
                    return Math.min(
                        Math.round(out / currentUnit) * currentUnit, //round to nearest step
                        max
                    );
                },
            ];
        },
        []
    );

    function slideHanlder(value: number) {
        setSliderValue(value);
        const transformValue = sliderTransform(value);
        props.onChange(`${transformValue}`);
        setValue(`${transformValue}`);
    }

    let [points, sliderTransform] = scaleTransform(
        props.min || 0,
        props.max,
        props.steps
    );

    const getSliderValue = useCallback(
        (value: number) => {
            let sValue = 0;
            for (let i = 0; i <= points; i++) {
                if (sliderTransform(i) >= +value) {
                    sValue = i;
                    break;
                }

                if (i === points) {
                    sValue = i;
                }
            }
            return sValue;
        },
        [points, sliderTransform]
    );

    const inputHanlder = useCallback(
        (value: string) => {
            if (!numberRegex.test(value)) {
                return;
            }

            setValue(value);
            let sValue = 0;
            for (let i = 0; i <= points; i++) {
                if (sliderTransform(i) >= +value) {
                    sValue = i;
                    break;
                }

                if (i === points) {
                    sValue = i;
                }
            }
            setSliderValue(sValue);
            props.onChange(value);
        },
        [points, props, sliderTransform]
    );

    useEffect(() => {
        if (props.value === "") {
            setValue("");
            setSliderValue(0);
        }
        if (+props.value > 0) {
            setSliderValue(getSliderValue(+props.value));
            setValue(props.value);
        }
    }, [getSliderValue, inputHanlder, props.value]);

    return (
        <div
            className={expoSliderClasses.join(" ")}
            style={{ width: props.width }}
        >
            {(props.tooltip || props.label) && (
                <div
                    className="expo-slider-label"
                    style={{
                        color: props.labelColor,
                    }}
                >
                    {props.tooltip && <Tooltip content={props.tooltip} />}
                    <span className="text-s-r">{props.label}</span>
                </div>
            )}
            <div className="expo-slider-inputs">
                <ReactSlider
                    className="expo-slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    min={props.min}
                    max={points}
                    value={sliderValue}
                    onChange={slideHanlder}
                    renderThumb={(props) => (
                        <div {...props}>
                            <Icon type="chevron-right" />
                            <Icon type="chevron-left" />
                        </div>
                    )}
                />
                <input
                    type="text"
                    className="expo-slider-input"
                    value={value}
                    onChange={(e) => inputHanlder(e.target.value)}
                    placeholder={t("createOrder.weightPlaceholder") || ""}
                />
                <span className="text-m-r">
                    {props.unitLabel
                        ? props.unitLabel
                        : t("createOrder.weightUnit")}
                </span>
            </div>
        </div>
    );
}

export default ExpoSlider;
