import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm, useFormState, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { createOrder, getOrderPrice, getPricings } from "../../api/order";
import Spinner from "../../components/UI/Spinner";
import Button from "../../components/buttons/Button";
import IconButton from "../../components/buttons/IconButton";
import AddressSearch from "../../components/inputs/AddressSearch";
import ExpoSlider from "../../components/inputs/ExpoSlider";
import Input from "../../components/inputs/Input";
import Textarea from "../../components/inputs/Textarea";
import AvailableVehicles from "../../components/widgets/AvailableVehicles";
import FleetSelector from "../../fleet-on-demand/FleetSelector";
import NewOrderGuide from "../../fleet-on-demand/NewOrderGuide";
import useOrderOptions from "../../hooks/data/useOrderOptions";
import ConfirmOrderPopup from "../../popups/ConfirmOrderPopup";
import OrderAdditionalInfoPopup from "../../popups/OrderAdditionalInfoPopup";
import OrderCreatedPopup from "../../popups/OrderCreatedPopup";
import { NewOrderForm, newOrderFormSchema } from "../../schemas/form";
import analytics from "../../shared/services/ga4";
import {
    AdditionalOrderInfo,
    MultiPriceData,
    OrderDraft,
    StopDraft,
    Vehicle,
} from "../../shared/types/api";
import {
    dateToString,
    getExpressOrderTimes,
    getTomorrowOrderTimes,
    stringToDate,
} from "../../shared/utility/date";
import {
    convertToNumberWithSpaces,
    isEnvironment,
    onFormError,
    throttle,
} from "../../shared/utility/misc";
import { formatPhoneNumber } from "../../shared/utility/phone";
import {
    API_ERROR,
    DELIVERY_NAME,
    NEW_ORDER_SECTION_ID,
} from "../../shared/values/enums";
import "./style.scss";

type Props = {
    noGuide?: boolean;
    orderDraft?: OrderDraft;
    stopDrafts?: {
        pickup?: StopDraft;
        dropoff?: StopDraft;
    };
    isPopup?: boolean;
    isFromFleetPlanner?: boolean;
    onOrderCreated?: (orderId: number) => void;
};

function NewOrder(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { orderOptions, orderOptionsStatus } = useOrderOptions();

    const defaultValues = useMemo(() => {
        let defaultValues: NewOrderForm = {
            fromAddress: "",
            toAddress: "",
            weight: 0,
            vehicleName: "",
            phone: "",
            cargoContent: "",
            deliveryInfo: {
                deliveryDate: null,
                earliestPickUp: "",
                latestDelivery: "",
                deliveryOption: "scheduled",
            },
            orderNumber: "",
            additionalInfo: null,
            numberOfEuPallets: 0,
            weightOfHeaviestPackage: 0,
            isSideLoaded: false,
            pricingHash: "",
            priceCalculatedFrom: "",
        };

        if (props.orderDraft) {
            const earliestPickupHasTime =
                props.orderDraft?.earliest_pickup_utc?.includes("T") || false;
            const earliestPickupDate = stringToDate(
                props.orderDraft?.earliest_pickup_utc || ""
            );

            const latestDeliveryHasTime =
                props.orderDraft?.latest_delivery_utc?.includes("T") || false;
            const latestDeliveryDate = stringToDate(
                props.orderDraft?.latest_delivery_utc || ""
            );

            defaultValues = {
                ...defaultValues,
                fromAddress: props.orderDraft.from_location || "",
                toAddress: props.orderDraft.to_location || "",
                weight: props.orderDraft.weight_kg || 0,
                vehicleName: props.orderDraft.vehicle_type || "",
                phone: isEnvironment("staging")
                    ? ""
                    : props.orderDraft.dropoff_phone || "",
                cargoContent: props.orderDraft.cargo_content || "",
                deliveryInfo: {
                    deliveryDate:
                        latestDeliveryDate || earliestPickupDate || null,
                    earliestPickUp: earliestPickupHasTime
                        ? dateToString(earliestPickupDate, { onlyTime: true })
                        : "",
                    latestDelivery: latestDeliveryHasTime
                        ? dateToString(latestDeliveryDate, { onlyTime: true })
                        : "",
                    deliveryOption: "scheduled",
                },
                orderNumber: props.orderDraft.origin_order_number || "",
                additionalInfo: {
                    estimatedOffloadTime:
                        props.orderDraft.estimated_offload_time || 0,
                },
            };
        }

        if (props.stopDrafts) {
            defaultValues = {
                ...defaultValues,
                fromAddress: props.stopDrafts.pickup?.to_location || "",
                toAddress: props.stopDrafts.dropoff?.to_location || "",
                phone: props.stopDrafts.dropoff?.phone || "",
                cargoContent: props.stopDrafts.dropoff?.cargo_content || "",
                orderNumber: props.stopDrafts.dropoff?.order_number || "",
                deliveryInfo: {
                    deliveryDate: props.stopDrafts.dropoff?.date_tooltip
                        ? stringToDate(props.stopDrafts.dropoff.date_tooltip)
                        : null,
                    earliestPickUp: props.stopDrafts.pickup?.time_tooltip || "",
                    latestDelivery:
                        props.stopDrafts.dropoff?.time_tooltip || "",
                    deliveryOption: "scheduled",
                },
                additionalInfo: {
                    estimatedOffloadTime: props.stopDrafts.dropoff
                        ?.estimated_offload_time
                        ? +props.stopDrafts.dropoff?.estimated_offload_time
                        : 0,
                },
                weight: props.stopDrafts.dropoff?.weight_kg || 0,
            };
        }

        return defaultValues;
    }, [props.orderDraft, props.stopDrafts]);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        reset: resetInputs,
        watch,
    } = useForm<NewOrderForm>({
        resolver: zodResolver(newOrderFormSchema),
        mode: "onChange",
        defaultValues,
    });

    useEffect(() => {
        resetInputs(defaultValues);
    }, [defaultValues, resetInputs]);

    const { errors } = useFormState({
        control,
        name: ["phone", "orderNumber"],
    });

    const priceOptionsWatch = useWatch({
        control,
        name: [
            "weight",
            "fromAddress",
            "toAddress",
            "numberOfEuPallets",
            "deliveryInfo",
        ],
    });
    const priceWatch = useWatch({
        control,
        name: [
            "weight",
            "vehicleName",
            "deliveryInfo",
            "fromAddress",
            "toAddress",
            "numberOfEuPallets",
            "pricingHash",
            "priceCalculatedFrom",
        ],
    });
    const vehicleWatch = useWatch({
        control,
        name: [
            "weight",
            "vehicleName",
            "numberOfEuPallets",
            "weightOfHeaviestPackage",
        ],
    });

    const activeOrderNumber = watch("orderNumber");
    const activePhone = watch("phone");
    const activeCargoContent = watch("cargoContent");

    const [unselectableVehicles, setUnselectableVehicles] = useState<string[]>(
        []
    );

    //from backend
    const [price, setPrice] = useState(0);
    const [priceOptions, setPriceOptions] = useState<MultiPriceData | null>(
        null
    );

    const [, setHasSentPriceEvent] = useState(false);
    const [, setHasSentMultiPriceEvent] = useState(false);

    //popups
    const [isAdditionalInfoPopupOpen, setIsAdditionalInfoPopupOpen] =
        useState(false);
    const [isConfirmOrderPopupOpen, setIsConfirmOrderPopupOpen] =
        useState(false);
    const [createdOrderId, setCreatedOrderId] = useState<number | null>(null);

    const { mutate: createOrderHandler, isPending: isCreatingOrder } =
        useMutation({
            mutationFn: async (values: NewOrderForm) => {
                let backendAdditionalInfo: AdditionalOrderInfo = {
                    consignee_company:
                        values.additionalInfo?.consigneeCompany || undefined,
                    estimated_offload_time:
                        values.additionalInfo?.estimatedOffloadTime ||
                        undefined,
                    contacts:
                        values.additionalInfo?.contacts?.map((c) => ({
                            consignee_name: c.consigneeName,
                            consignee_phone: formatPhoneNumber(
                                c.consigneePhone
                            ),
                        })) || undefined,
                    offload_instructions:
                        values.additionalInfo?.offloadInstructions || undefined,
                    cargo_dimensions:
                        values.additionalInfo?.cargoDimensions || undefined,
                    branch_number:
                        values.additionalInfo?.branchNumber || undefined,
                    invoice_number:
                        values.additionalInfo?.invoiceNumber || undefined,
                    side_loading: values.isSideLoaded || undefined,
                    number_of_eu_pallets: values.numberOfEuPallets || undefined,
                };

                const { earliestPickup, latestDelivery, shouldSendPriceHash } =
                    getEarliestPickupLatestDelivery(values.deliveryInfo);

                const res = await createOrder({
                    order_number: values.orderNumber,
                    weight_kg: values.weight,
                    vehicle: values.vehicleName,
                    from_location: values.fromAddress,
                    to_location: values.toAddress,
                    phone: formatPhoneNumber(values.phone),
                    cargo_content: values.cargoContent,
                    earliest_pickup: earliestPickup,
                    latest_delivery: latestDelivery,
                    weight_of_heaviest_package: values.weightOfHeaviestPackage,
                    additional_info: backendAdditionalInfo,
                    calculate_price_from: shouldSendPriceHash
                        ? values.priceCalculatedFrom
                        : undefined,
                    pricing_hash: shouldSendPriceHash
                        ? values.pricingHash
                        : undefined,
                    created_from_fleet_planner: props.isFromFleetPlanner,
                });

                return {
                    price,
                    orderId: res.data.id,
                    earliestPickup,
                    latestDelivery,
                };
            },
            onSuccess: (data, { vehicleName, weight }) => {
                setIsConfirmOrderPopupOpen(false);
                resetInputs();
                queryClient.removeQueries({
                    queryKey: ["orders"],
                    type: "all",
                });
                analytics.orderCreated({
                    price: data?.price || 0,
                    weight: +weight,
                    vehicle: vehicleName,
                    earliestPickup: data.earliestPickup,
                    latestDelivery: data.latestDelivery,
                });
                setHasSentPriceEvent(false);
                if (props.onOrderCreated) {
                    props.onOrderCreated(data?.orderId || 0);
                } else {
                    setCreatedOrderId(data?.orderId || null);
                }
            },
            onError: async (error, data) => {
                if (isAxiosError(error)) {
                    const axiosError = error as AxiosError<{
                        detail: { error: string };
                    }>;

                    if (
                        axiosError.response?.data.detail.error ===
                            API_ERROR.ExpiredPriceHash ||
                        axiosError.response?.data.detail.error ===
                            API_ERROR.InvalidPriceHash
                    ) {
                        const { earliestPickup, latestDelivery } =
                            getEarliestPickupLatestDelivery(data.deliveryInfo);
                        const res = await getPricings({
                            weightKg: data.weight,
                            fromAddress: data.fromAddress,
                            toAddress: data.toAddress,
                            numberOfPallets: data.numberOfEuPallets || 0,
                            earliestPickup,
                            latestDelivery,
                        });
                        setValue("pricingHash", res.data.pricing_hash);
                        setValue(
                            "priceCalculatedFrom",
                            res.data.calculated_from
                        );
                        toast.error(t("errorMessage.expiredPriceHash"));
                        return;
                    }

                    if (
                        axiosError.response?.data.detail.error ===
                        API_ERROR.InvalidTimeslot
                    ) {
                        toast.error(t("errorMessage.invalidTimeslot"));
                        return;
                    }
                }

                console.error(error);
                toast.error(t("errorMessage.unknown"));
            },
        });

    function getEarliestPickupLatestDelivery(
        deliveryInfo: NewOrderForm["deliveryInfo"]
    ) {
        let pickupDate;
        let deliveryDate;
        let shouldSendPriceHash = true;
        if (deliveryInfo.deliveryOption) {
            switch (deliveryInfo.deliveryOption) {
                case DELIVERY_NAME.Express:
                    const expressDates = getExpressOrderTimes();
                    pickupDate = expressDates.pickup;
                    deliveryDate = expressDates.delivery;
                    shouldSendPriceHash = false;
                    break;
                case DELIVERY_NAME.Tomorrow:
                    const tomorrowDates = getTomorrowOrderTimes();
                    pickupDate = tomorrowDates.pickup;
                    deliveryDate = tomorrowDates.delivery;
                    shouldSendPriceHash = false;
                    break;
                default:
                    if (
                        deliveryInfo.deliveryDate &&
                        deliveryInfo.earliestPickUp
                    ) {
                        pickupDate = new Date(
                            `${dateToString(deliveryInfo.deliveryDate)} ${
                                deliveryInfo.earliestPickUp
                            }`
                        );
                    }
                    if (
                        deliveryInfo.deliveryDate &&
                        deliveryInfo.latestDelivery
                    ) {
                        deliveryDate = new Date(
                            `${dateToString(deliveryInfo.deliveryDate)} ${
                                deliveryInfo.latestDelivery
                            }`
                        );
                    }
                    break;
            }
        }

        return {
            earliestPickup: dateToString(pickupDate, {
                timezone: true,
            }),
            latestDelivery: dateToString(deliveryDate, {
                timezone: true,
            }),
            shouldSendPriceHash,
        };
    }

    function isVehicleDisabled({
        vehicle,
        chosenWeight,
        numberOfEUPallets,
        heaviestPackageWeight,
    }: {
        vehicle: Vehicle;
        chosenWeight: number | null;
        numberOfEUPallets: number | null;
        heaviestPackageWeight: number | null;
    }) {
        if ((vehicle.max_weight || Infinity) < (chosenWeight || 0)) return true;

        if (
            (heaviestPackageWeight || 0) >
            (vehicle.heaviest_package || Infinity)
        ) {
            return true;
        }

        if (
            (numberOfEUPallets || 0) >
            (vehicle.max_eu_pallet === null ? Infinity : vehicle.max_eu_pallet)
        ) {
            return true;
        }

        if (
            (numberOfEUPallets || 0) <
            (vehicle.min_eu_pallet === null ? 0 : vehicle.min_eu_pallet)
        ) {
            return true;
        }

        return false;
    }

    const deselectVehicleHandler = useCallback(
        ({
            chosenVehicleName,
            chosenWeight,
            numberOfEUPallets,
            heaviestPackageWeight,
        }: {
            chosenVehicleName: string | null;
            chosenWeight: number | null;
            numberOfEUPallets: number | null;
            heaviestPackageWeight: number | null;
        }) => {
            const vehicle = orderOptions?.vehicle_types.find(
                (v) => v.car_name === chosenVehicleName
            );

            if (!vehicle) return;

            if (
                isVehicleDisabled({
                    vehicle,
                    chosenWeight,
                    numberOfEUPallets,
                    heaviestPackageWeight,
                })
            ) {
                setValue("vehicleName", "");
                //Because you always select delivery name with vehicle select
                setValue("deliveryInfo", {
                    deliveryDate: null,
                    earliestPickUp: "",
                    latestDelivery: "",
                    deliveryOption: "scheduled",
                });
            }
        },
        [orderOptions?.vehicle_types, setValue]
    );

    const calculatePrice = useMemo(
        () =>
            throttle(
                async ({
                    weight,
                    vehicleType,
                    fromAddress,
                    toAddress,
                    numberOfPallets,
                    earliestPickup,
                    latestDelivery,
                    pricingHash,
                    calculateFrom,
                }: {
                    vehicleType: string;
                    fromAddress: string;
                    toAddress: string;
                    weight: number;
                    numberOfPallets?: number;
                    earliestPickup: string;
                    latestDelivery: string;
                    pricingHash: string;
                    calculateFrom: string;
                }) => {
                    if (
                        !weight ||
                        !vehicleType ||
                        !fromAddress ||
                        !toAddress ||
                        !earliestPickup ||
                        !latestDelivery ||
                        !pricingHash ||
                        !calculateFrom
                    ) {
                        setPrice(0);
                        return;
                    }

                    try {
                        const res = await getOrderPrice({
                            weightKg: weight,
                            vehicle: vehicleType,
                            fromAddress,
                            toAddress,
                            numberOfPallets,
                            earliestPickup,
                            latestDelivery,
                            pricingHash,
                            calculateFrom,
                        });
                        setPrice(res.data.price);
                        setHasSentPriceEvent((state) => {
                            if (state) return true;
                            analytics.orderPriceSet({
                                price: res.data.price,
                                vehicle: vehicleType,
                                weight,
                                distance: res.data.distance_km,
                                earliestPickup,
                                latestDelivery,
                            });
                            return true;
                        });
                    } catch (error) {
                        toast.error(t("errorMessage.failedToCalculatePrice"));
                        setPrice(0);
                    }
                },
                500
            ),
        [t]
    );

    const calculatePriceOptions = useMemo(
        () =>
            throttle(
                async ({
                    weight,
                    fromAddress,
                    toAddress,
                    numberOfPallets,
                    earliestPickup,
                    latestDelivery,
                }: {
                    weight: number;
                    fromAddress: string;
                    toAddress: string;
                    numberOfPallets: number;
                    earliestPickup: string;
                    latestDelivery: string;
                }) => {
                    if (!weight || !fromAddress || !toAddress) {
                        setValue("pricingHash", "");
                        setValue("priceCalculatedFrom", "");
                        setPriceOptions(null);
                        return;
                    }

                    try {
                        const res = await getPricings({
                            weightKg: weight,
                            fromAddress,
                            toAddress,
                            numberOfPallets,
                            earliestPickup: earliestPickup || undefined,
                            latestDelivery: latestDelivery || undefined,
                        });

                        setHasSentMultiPriceEvent((state) => {
                            if (state) return true;
                            analytics.orderMultipriceSet({
                                weight,
                                distance: res.data.distance_km,
                            });
                            return true;
                        });
                        setValue("pricingHash", res.data.pricing_hash);
                        setValue(
                            "priceCalculatedFrom",
                            res.data.calculated_from
                        );
                        setPriceOptions(res.data);
                    } catch (error) {
                        toast.error(
                            t("errorMessage.failedToCalculatePriceOptions")
                        );
                        setValue("pricingHash", "");
                        setValue("priceCalculatedFrom", "");
                        setPriceOptions(null);
                    }
                },
                500
            ),
        [setValue, t]
    );

    useEffect(() => {
        const [
            weight,
            fromAddress,
            toAddress,
            numberOfEuPallets,
            deliveryInfo,
        ] = priceOptionsWatch;

        const { earliestPickup, latestDelivery } =
            getEarliestPickupLatestDelivery(deliveryInfo);

        calculatePriceOptions({
            weight: weight || 0,
            fromAddress: fromAddress || "",
            toAddress: toAddress || "",
            numberOfPallets: numberOfEuPallets || 0,
            earliestPickup: earliestPickup,
            latestDelivery: latestDelivery,
        });
    }, [calculatePriceOptions, priceOptionsWatch]);

    useEffect(() => {
        const [
            weight,
            vehicleName,
            deliveryInfo,
            fromAddress,
            toAddress,
            numberOfEuPallets,
            pricingHash,
            priceCalculatedFrom,
        ] = priceWatch;

        const { earliestPickup, latestDelivery } =
            getEarliestPickupLatestDelivery(deliveryInfo);

        calculatePrice({
            weight: weight || 0,
            vehicleType: vehicleName || "",
            fromAddress: fromAddress || "",
            toAddress: toAddress || "",
            numberOfPallets: numberOfEuPallets || 0,
            earliestPickup: earliestPickup,
            latestDelivery: latestDelivery,
            pricingHash,
            calculateFrom: priceCalculatedFrom,
        });
    }, [calculatePrice, priceWatch]);

    useEffect(() => {
        const [
            weight,
            vehicleName,
            numberOfEuPallets,
            weightOfHeaviestPackage,
        ] = vehicleWatch;

        deselectVehicleHandler({
            chosenVehicleName: vehicleName || null,
            chosenWeight: weight || null,
            numberOfEUPallets: numberOfEuPallets || null,
            heaviestPackageWeight: weightOfHeaviestPackage || null,
        });

        setUnselectableVehicles(
            () =>
                orderOptions?.vehicle_types
                    .filter((vehicle) =>
                        isVehicleDisabled({
                            vehicle,
                            chosenWeight: weight || null,
                            numberOfEUPallets: numberOfEuPallets || null,
                            heaviestPackageWeight:
                                weightOfHeaviestPackage || null,
                        })
                    )
                    .map((vehicle) => vehicle.car_name) || []
        );
    }, [deselectVehicleHandler, orderOptions?.vehicle_types, vehicleWatch]);

    function handleOrderFormSubmit() {
        setIsConfirmOrderPopupOpen(true);
    }

    if (orderOptionsStatus !== "success") {
        return <Spinner padding="10px" />;
    }

    return (
        <div className="new-order">
            <form
                style={{
                    width: "100%",
                }}
                onSubmit={handleSubmit(handleOrderFormSubmit, onFormError)}
            >
                <section id={NEW_ORDER_SECTION_ID.Address}>
                    <Controller
                        control={control}
                        name="fromAddress"
                        render={({ field: { value, onChange } }) => (
                            <AddressSearch
                                value={value}
                                preselectedAddress={
                                    defaultValues.fromAddress || ""
                                }
                                onChange={onChange}
                                placeholder={t(
                                    "createOrder.fromAddressPlaceholder"
                                )}
                                label={t("createOrder.fromAddressLabel")}
                                labelColor="var(--text-color)"
                                type="pickup"
                                width="100%"
                                isValid={!!value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="toAddress"
                        render={({ field: { value, onChange } }) => (
                            <AddressSearch
                                value={value}
                                preselectedAddress={
                                    defaultValues.toAddress || ""
                                }
                                onChange={onChange}
                                placeholder={t(
                                    "createOrder.toAddressPlaceholder"
                                )}
                                label={t("createOrder.toAddressLabel")}
                                labelColor="var(--text-color)"
                                type="dropoff"
                                width="100%"
                                isValid={!!value}
                            />
                        )}
                    />
                </section>
                <section className="column" id={NEW_ORDER_SECTION_ID.Weight}>
                    <Controller
                        control={control}
                        name="weight"
                        render={({ field: { value, onChange } }) => (
                            <ExpoSlider
                                value={value === 0 ? "" : value.toString()}
                                onChange={(v) => onChange(+v)}
                                steps={[5, 10, 50, 100]}
                                max={4500}
                                label={t("createOrder.weightLabel")}
                                labelColor="var(--text-color)"
                                isValid={!!value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="weightOfHeaviestPackage"
                        render={({ field: { value, onChange } }) => (
                            <ExpoSlider
                                value={value === 0 ? "" : value.toString()}
                                onChange={(v) => onChange(+v)}
                                steps={[5, 10, 50, 100]}
                                max={4500}
                                label={t("createOrder.heaviestPackageLabel")}
                                labelColor="var(--text-color)"
                                isValid={!!value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="numberOfEuPallets"
                        render={({ field: { value, onChange } }) => (
                            <ExpoSlider
                                value={value === 0 ? "" : value.toString()}
                                onChange={(v) => onChange(+v)}
                                steps={[1]}
                                max={15}
                                label={t("createOrder.numberOfEuPackagesLabel")}
                                labelColor="var(--text-color)"
                                unitLabel={t("createOrder.amountUnit")}
                                isValid={value > 0}
                            />
                        )}
                    />
                </section>
                <section className="column" id={NEW_ORDER_SECTION_ID.Vehicle}>
                    <p className="text-s-r">{t("createOrder.fleetLabel")}</p>
                    <Controller
                        name="vehicleName"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FleetSelector
                                control={control}
                                orderOptions={orderOptions}
                                priceOptions={priceOptions}
                                activeVehicle={value}
                                onVehicleChange={onChange}
                                disabledVehicles={unselectableVehicles}
                            />
                        )}
                    />
                </section>
                <section id={NEW_ORDER_SECTION_ID.Contact}>
                    <Controller
                        name="orderNumber"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                type="text"
                                value={value}
                                onChange={onChange}
                                placeholder={t(
                                    "createOrder.orderNumberPlaceholder"
                                )}
                                label={t("createOrder.orderNumberLabel")}
                                labelColor="var(--text-color)"
                                width="100%"
                                id="order-number"
                                isValid={
                                    !errors.orderNumber && !!activeOrderNumber
                                }
                            />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                type="tel"
                                value={value}
                                onChange={onChange}
                                placeholder={t("createOrder.phonePlaceholder")}
                                label={t("createOrder.phoneLabel")}
                                labelColor="var(--text-color)"
                                width="100%"
                                name="phone"
                                id="phone"
                                isValid={!errors.phone && !!activePhone}
                                invalid={errors.phone && !!activePhone}
                            />
                        )}
                    />
                </section>
                <section id={NEW_ORDER_SECTION_ID.CargoContent}>
                    <Controller
                        name="cargoContent"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Textarea
                                value={value}
                                onChange={onChange}
                                placeholder={t(
                                    "createOrder.cargoContentPlaceholder"
                                )}
                                label={t("createOrder.cargoContentLabel")}
                                labelColor="var(--text-color)"
                                width="100%"
                                isValid={!!activeCargoContent}
                            />
                        )}
                    />
                </section>
                <section>
                    <IconButton
                        icon="plus"
                        label={t("createOrder.additionalInfoLabel")}
                        width="fit-content"
                        onClick={() => setIsAdditionalInfoPopupOpen(true)}
                    />
                </section>
                <div className="bottom">
                    <AvailableVehicles />
                    <div className="price-wrapper">
                        <span className="text-m-r">
                            {!!price && convertToNumberWithSpaces(price, "SEK")}
                        </span>
                        <Button
                            label={t("createOrder.submitOrderLabel")}
                            color="secondary"
                            onClick={
                                props.isPopup
                                    ? handleSubmit(
                                          handleOrderFormSubmit,
                                          onFormError
                                      )
                                    : undefined
                            }
                            isLoading={isCreatingOrder}
                        />
                    </div>
                </div>
            </form>
            {!props.noGuide && <NewOrderGuide />}
            <Controller
                name="additionalInfo"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <OrderAdditionalInfoPopup
                        showPopup={isAdditionalInfoPopupOpen}
                        onClose={() => setIsAdditionalInfoPopupOpen(false)}
                        orderAdditionalInfo={value}
                        onSubmit={(data) => {
                            setIsAdditionalInfoPopupOpen(false);
                            onChange(data);
                        }}
                    />
                )}
            />
            {orderOptionsStatus === "success" && (
                <ConfirmOrderPopup
                    orderData={getValues()}
                    vehicleType={
                        orderOptions.vehicle_types.find(
                            (v) => v.car_name === getValues("vehicleName")
                        ) || null
                    }
                    price={price}
                    showPopup={isConfirmOrderPopupOpen}
                    onClose={() => setIsConfirmOrderPopupOpen(false)}
                    onSubmit={() => createOrderHandler(getValues())}
                    isSubmitting={isCreatingOrder}
                />
            )}
            <OrderCreatedPopup
                orderId={createdOrderId || undefined}
                showPopup={!!createdOrderId}
                onClose={() => setCreatedOrderId(null)}
            />
        </div>
    );
}

export default NewOrder;
