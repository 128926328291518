import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useMemo } from "react";

function usePolylinePath({
    hide,
    path,
}: {
    hide: boolean;
    path: google.maps.LatLngLiteral[];
}) {
    const map = useMap();
    const mapsLibrary = useMapsLibrary("maps");

    const arrowIcon = useMemo(
        () => ({
            path: google.maps.SymbolPath?.FORWARD_OPEN_ARROW || "",
            strokeOpacity: 1,
            scale: 3,
            strokeColor: "#0f0f0f",
        }),
        []
    );

    useEffect(() => {
        if (!map || !mapsLibrary || hide) return;

        const polyline = new mapsLibrary.Polyline({
            path,
            map,
            strokeColor: "#0f0f0f",
            strokeOpacity: 0.4,
            strokeWeight: 5,
            icons: [
                {
                    icon: arrowIcon,
                    offset: "50%",
                },
            ],
        });

        return () => polyline.setMap(null);
    }, [map, mapsLibrary, path, hide, arrowIcon]);
}

export default usePolylinePath;
