import { IconType } from "../../../shared/types/internal";
import Icon from "../../UI/Icon";
import Spinner from "../../UI/Spinner";
import "./style.scss";

type Props = {
    label: string;
    onClick?: (event: React.MouseEvent) => void;
    disabled?: boolean;
    color?: string;
    noSumbit?: boolean;
    style?: React.CSSProperties;
    className?: string;
    id?: string;
    isLoading?: boolean;
    icon?: IconType;
};

function TextButton(props: Props) {
    const buttonClasses = ["text-button", "text-s-r"];

    if (props.className) {
        buttonClasses.push(props.className);
    }

    return (
        <button
            className={buttonClasses.join(" ")}
            disabled={props.disabled}
            onClick={(e) => {
                if (props.isLoading || props.disabled) {
                    e.preventDefault();
                    return;
                }

                if (props.onClick) props.onClick(e);
            }}
            style={{ ...props.style, color: props.color }}
            type={props.noSumbit || props.onClick ? "button" : undefined}
            id={props.id}
        >
            {props.isLoading ? (
                <Spinner />
            ) : (
                <span className="label">
                    {props.label}
                    {props.icon && <Icon type={props.icon} />}
                </span>
            )}
        </button>
    );
}

export default TextButton;
