import Tooltip from "../../UI/Tooltip";
import "./style.scss";

type Props = {
    value?: string;
    onChange?: (value: string) => void;
    label?: string | null;
    labelColor?: string;
    placeholder?: string | null;
    tooltip?: string | null;
    width?: string;
    isValid?: boolean;
};

function Textarea(props: Props) {
    const textareaClasses = ["textarea-wrapper", "text-m-r"];
    if (props.isValid) {
        textareaClasses.push("valid");
    }

    return (
        <label
            className={textareaClasses.join(" ")}
            style={{ width: props.width }}
        >
            {(props.label || props.tooltip) && (
                <div
                    className="textarea-label"
                    style={{ color: props.labelColor }}
                >
                    {props.tooltip && <Tooltip content={props.tooltip} />}
                    {props.label && (
                        <span className="text-s-r">{props.label}</span>
                    )}
                </div>
            )}
            <textarea
                className="textarea text-m-r"
                placeholder={props.placeholder || undefined}
                value={props.value}
                onChange={(e) => props.onChange?.(e.target.value)}
            />
        </label>
    );
}

export default Textarea;
