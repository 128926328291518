import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import RouteNavigation from "../../components/UI/RouteNavigation";
import OrderInfoPopup from "../../popups/OrderInfoPopup";
import { Order } from "../../shared/types/api";
import { NavItem } from "../../shared/types/internal";
import { ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {};

function Orders(props: Props) {
    const { t } = useTranslation();

    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    const navItems: NavItem[] = [
        {
            id: "new-order",
            path: ROUTE.NewOrder,
            label: t("orders.new-order"),
        },
        {
            id: "active-orders",
            path: ROUTE.ActiveOrders,
            label: t("orders.active-orders"),
        },
        {
            id: "fulfilled-orders",
            path: ROUTE.FulfilledOrders,
            label: t("orders.fulfilled-orders"),
        },
    ];

    return (
        <div className="orders">
            <RouteNavigation navItems={navItems} />
            <div className="content">
                <Outlet
                    context={{
                        selectedOrder,
                        setSelectedOrder,
                    }}
                />
            </div>
            <OrderInfoPopup
                showPopup={!!selectedOrder}
                onClose={() => setSelectedOrder(null)}
                order={selectedOrder}
            />
        </div>
    );
}

export default Orders;
