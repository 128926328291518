import { useTranslation } from "react-i18next";
import { Order } from "../../../shared/types/api";
import { OrderStatus } from "../../../shared/types/internal";
import { getOrderStatusTimestamps } from "../../../shared/utility/order";
import Icon from "../../UI/Icon";
import StatusCircle from "../../UI/StatusCircle";
import "./style.scss";

type Props = {
    activeStatus: OrderStatus;
    order: Order | null;
};

function OrderStatusTimeline(props: Props) {
    const { t } = useTranslation();

    const timestamps = getOrderStatusTimestamps(props.order);

    return (
        <div className="order-status-timeline">
            <section>
                <div className="status-text text-s-r">
                    <StatusCircle status="created" />
                    {t("orderHistory.statusCreated")}
                    <span>{timestamps["created"]}</span>
                </div>
                <div
                    className="status-description text-m-r"
                    style={{
                        color:
                            props.activeStatus !== "created"
                                ? "var(--text-color-light)"
                                : undefined,
                    }}
                >
                    {props.activeStatus !== "created" && (
                        <Icon type="comment" />
                    )}
                    {t("orderHistory.statusCreatedDescription")}
                </div>
                {props.activeStatus === "created" && (
                    <div className="active-dots" />
                )}
            </section>
            <section>
                <div className="status-text text-s-r">
                    <StatusCircle status="verified" />
                    {t("orderHistory.statusVerified")}
                    <span>{timestamps["verified"]}</span>
                </div>
                <div
                    className="status-description text-m-r"
                    style={{
                        color:
                            props.activeStatus !== "verified"
                                ? "var(--text-color-light)"
                                : undefined,
                    }}
                >
                    {props.activeStatus !== "verified" && (
                        <Icon type="comment" />
                    )}
                    {t("orderHistory.statusVerifiedDescription")}
                </div>
                {props.activeStatus === "verified" && (
                    <div className="active-dots" />
                )}
            </section>
            <section>
                <div className="status-text text-s-r">
                    <StatusCircle status="at-pickup" />
                    {t("orderHistory.statusAtPickup")}
                    <span>{timestamps["at-pickup"]}</span>
                </div>
                <div
                    className="status-description text-m-r"
                    style={{
                        color:
                            props.activeStatus !== "at-pickup"
                                ? "var(--text-color-light)"
                                : undefined,
                    }}
                >
                    {props.activeStatus !== "at-pickup" && (
                        <Icon type="comment" />
                    )}
                    {t("orderHistory.statusAtPickupDescription")}
                </div>
                {props.activeStatus === "at-pickup" && (
                    <div className="active-dots" />
                )}
            </section>
            <section>
                <div className="status-text text-s-r">
                    <StatusCircle status="in-progress" />
                    {t("orderHistory.statusInProgress")}
                    <span>{timestamps["in-progress"]}</span>
                </div>
                <div
                    className="status-description text-m-r"
                    style={{
                        color:
                            props.activeStatus !== "in-progress"
                                ? "var(--text-color-light)"
                                : undefined,
                    }}
                >
                    {props.activeStatus !== "in-progress" && (
                        <Icon type="comment" />
                    )}
                    {t("orderHistory.statusInProgressDescription")}
                </div>
                {props.activeStatus === "in-progress" && (
                    <div className="active-dots" />
                )}
            </section>
            <section>
                <div className="status-text text-s-r">
                    <StatusCircle status="next-stop" />
                    {t("orderHistory.statusNextStop")}
                    <span>{timestamps["next-stop"]}</span>
                </div>
                <div
                    className="status-description text-m-r"
                    style={{
                        color:
                            props.activeStatus !== "next-stop"
                                ? "var(--text-color-light)"
                                : undefined,
                    }}
                >
                    {props.activeStatus !== "next-stop" && (
                        <Icon type="comment" />
                    )}
                    {t("orderHistory.statusNextStopDescription")}
                </div>
                {props.activeStatus === "next-stop" && (
                    <div className="active-dots" />
                )}
            </section>
            <section>
                <div className="status-text text-s-r">
                    <StatusCircle status="unloading" />
                    {t("orderHistory.statusUnloading")}
                    <span>{timestamps["unloading"]}</span>
                </div>
                <div
                    className="status-description text-m-r"
                    style={{
                        color:
                            props.activeStatus !== "unloading"
                                ? "var(--text-color-light)"
                                : undefined,
                    }}
                >
                    {props.activeStatus !== "unloading" && (
                        <Icon type="comment" />
                    )}
                    {t("orderHistory.statusUnloadingDescription")}
                </div>
                {props.activeStatus === "unloading" && (
                    <div className="active-dots" />
                )}
            </section>
            <section>
                <div className="status-text text-s-r">
                    <StatusCircle status="fulfilled" />
                    {t("orderHistory.statusFulfilled")}{" "}
                    <span>{timestamps["fulfilled"]}</span>
                </div>
                <div
                    className="status-description text-m-r"
                    style={{
                        color:
                            props.activeStatus !== "fulfilled"
                                ? "var(--text-color-light)"
                                : undefined,
                    }}
                >
                    {props.activeStatus !== "fulfilled" && (
                        <Icon type="comment" />
                    )}
                    {t("orderHistory.statusFulfilledDescription")}
                </div>
            </section>
        </div>
    );
}

export default OrderStatusTimeline;
