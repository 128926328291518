import { DropdownOption } from "../../../shared/types/internal";
import RadioInput from "../RadioInput";
import "./style.scss";

type Props = {
    value: string;
    options: DropdownOption[];
    onChange: (value: string) => void;
    label?: string;
    labelColor?: string;
};

function RadioGroup(props: Props) {
    return (
        <div className="radio-group">
            {props.label && (
                <p className="text-s-r" style={{ color: props.labelColor }}>
                    {props.label}
                </p>
            )}
            {props.options.map((option) => (
                <RadioInput
                    key={option.value}
                    checked={props.value === option.value}
                    label={option.label}
                    onClick={() => props.onChange(option.value)}
                />
            ))}
        </div>
    );
}

export default RadioGroup;
