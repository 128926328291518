import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import RouteNavigation from "../../components/UI/RouteNavigation";
import { useSearchQuery } from "../../hooks/functionality/useSearchQuery";
import TourAccessPopup from "../../popups/TourAccessPopup";
import { NavItem } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { stringToDate } from "../../shared/utility/date";
import { QUERY_PARAM, ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {};

function Fleet(props: Props) {
    const { t } = useTranslation();
    const location = useLocation();
    const [filterDate, setFilterDate] = useState<Date | null>(new Date());
    const query = useSearchQuery();
    const navigate = useNavigate();
    const { user } = useSelector((state: ReduxState) => state.auth);

    useEffect(() => {
        const queryDate = query.get(QUERY_PARAM.FilterDate);
        if (!queryDate) return;
        setFilterDate(stringToDate(queryDate));
    }, [query]);

    const navItems: NavItem[] = [
        {
            id: "active-fleet",
            path: ROUTE.ActiveFleet,
            label: t("fleet.active-fleet"),
        },
        {
            id: "fleet-calendar",
            path: ROUTE.FleetCalendar,
            label: t("fleet.fleet-calendar"),
        },
        {
            id: "fulfilled-fleet-tours",
            path: ROUTE.FulfilledFleetTours,
            label: t("fleet.fulfilled-fleet-tours"),
        },
        {
            id: "drivers",
            path: ROUTE.Drivers,
            label: t("fleet.drivers"),
        },
        {
            id: "dashboard",
            path: ROUTE.Dashboard,
            label: t("fleet.dashboard"),
        },
        {
            id: "fleet-settings",
            path: ROUTE.FleetSettings,
            label: t("fleet.fleet-settings"),
        },
    ];

    return (
        <div className="fleet">
            {location.pathname !== ROUTE.FleetCalendarFullPage && (
                <RouteNavigation navItems={navItems} />
            )}
            <div className="content">
                <Outlet
                    context={{
                        filterDate,
                        setFilterDate,
                    }}
                />
            </div>
            <TourAccessPopup
                showPopup={!user?.location_entity?.mt_organization}
                onClose={() => navigate(ROUTE.NewOrder)}
            />
        </div>
    );
}

export default Fleet;
