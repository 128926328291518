import { useTranslation } from "react-i18next";
import { Order } from "../../shared/types/api";
import { OrderMode } from "../../shared/types/internal";
import { getOrderStatus } from "../../shared/utility/order";
import OrderRow from "./OrderRow";
import "./style.scss";

type Props = {
    orders: Order[];
    onOrderClick: (order: Order) => void;
    mode: OrderMode;
};

function OrdersTable(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="orders-table">
            <table>
                <thead>
                    <tr>
                        <th className="text-s-r status">
                            {t("orderHistory.status")}
                        </th>
                        <th className="text-s-r order-number">
                            {t("orderHistory.orderNumber")}
                        </th>
                        <th className="text-s-r created">
                            {t("orderHistory.created")}
                        </th>
                        <th className="text-s-r address">
                            {props.mode === "active"
                                ? t("orderHistory.pickUp")
                                : t("orderHistory.pickedUp")}
                        </th>
                        <th className="text-s-r address">
                            {props.mode === "active"
                                ? t("orderHistory.dropOff")
                                : t("orderHistory.droppedOff")}
                        </th>
                        {props.mode === "fulfilled" && (
                            <th className="text-s-r fulfilled-time">
                                {t("orderHistory.fulfilledTime")}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.orders.map((o, i) => (
                        <OrderRow
                            key={o.id}
                            trackingIds={
                                o.dropoff_tracking_code &&
                                o.pickup_tracking_code
                                    ? {
                                          pickup: o.pickup_tracking_code,
                                          dropoff: o.dropoff_tracking_code,
                                      }
                                    : undefined
                            }
                            status={getOrderStatus(o)}
                            orderNumber={o.origin_order_id || ""}
                            created={o.created_at || ""}
                            customer={o.customer}
                            pickupData={{
                                address: o.pickup_address || "",
                                note: o.pickup_driver_note,
                            }}
                            dropoffData={{
                                address: o.dropoff_address || "",
                                date: o.fulfilled_at,
                                note: o.dropoff_driver_note,
                            }}
                            onViewMore={() => props.onOrderClick(o)}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default OrdersTable;
