import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { createContact, deleteContact, updateContact } from "../../api/contact";
import Button from "../../components/buttons/Button";
import AddressSearch from "../../components/inputs/AddressSearch";
import Input from "../../components/inputs/Input";
import Popup from "../../hoc/Popup";
import { ContactForm, contactForm } from "../../schemas/form";
import { Contact } from "../../shared/types/api";
import { onFormError } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onSave: (contact?: Contact) => void;
    onDelete: (id: number) => void;
    name?: string;
    editContact?: Contact;
};

function ContactPopup(props: Props) {
    const { t } = useTranslation();

    const defaultValues = useMemo<ContactForm>(() => {
        const values: ContactForm = {
            name: "",
            phone: "",
            address: "",
        };

        if (props.name) {
            values.name = props.name;
        }

        if (props.editContact) {
            values.name = props.editContact.name;
            values.phone = props.editContact.phone || "";
            values.address = props.editContact.address || "";
        }

        return values;
    }, [props.editContact, props.name]);

    const {
        handleSubmit,
        control,
        reset: resetInputs,
    } = useForm<ContactForm>({
        resolver: zodResolver(contactForm),
        defaultValues,
    });

    const { mutate: createHandler, isPending: isCreating } = useMutation({
        mutationFn: async ({ name, phone, address }: ContactForm) => {
            const res = await createContact({
                name,
                phone,
                address,
            });

            return res.data;
        },
        onSuccess: (contact) => {
            toast.success(t("successMessage.contactCreated"));
            closeHandler();
            props.onSave(contact);
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: editHandler, isPending: isEditing } = useMutation({
        mutationFn: async ({
            data: { name, phone, address },
            id,
        }: {
            data: ContactForm;
            id: number;
        }) => {
            const res = await updateContact({
                name,
                phone: phone || undefined,
                address: address || undefined,
                id: id,
            });

            return res.data;
        },
        onSuccess: (contact) => {
            toast.success(t("successMessage.contactUpdated"));
            closeHandler();
            props.onSave(contact);
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: deleteHandler, isPending: isDeleting } = useMutation({
        mutationFn: async (id: number) => {
            await deleteContact(id);

            return id;
        },
        onSuccess: (deletedId) => {
            toast.success(t("successMessage.contactDeleted"));
            closeHandler();
            props.onDelete(deletedId);
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    useEffect(() => {
        resetInputs(defaultValues);
    }, [defaultValues, resetInputs]);

    function closeHandler() {
        resetInputs();
        props.onClose();
    }
    return (
        <Popup
            showPopup={props.showPopup}
            onClose={closeHandler}
            title={t("popup.contact.title")}
            dontCloseOnOutsideClick
        >
            <div className="contact-popup">
                <p className="description text-s-r">
                    {t("popup.contact.description")}
                </p>
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            label={t("popup.favourite.nameLabel")}
                            placeholder={t("popup.favourite.namePlaceholder")}
                            value={value}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="tel"
                            label={t("createTour.phone")}
                            placeholder={t("createTour.phonePlaceholder")}
                            value={value || ""}
                            onChange={onChange}
                            width="100%"
                        />
                    )}
                />
                <Controller
                    name="address"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <AddressSearch
                            value={value}
                            onChange={onChange}
                            label={t("popup.favourite.addressLabel")}
                            placeholder={t(
                                "popup.favourite.addressPlaceholder"
                            )}
                            preselectedAddress={defaultValues.address || ""}
                            width="100%"
                            noFavorites
                        />
                    )}
                />
                <div className="buttons">
                    {props.editContact ? (
                        <>
                            <Button
                                label={t("popup.favourite.deleteLabel")}
                                onClick={() =>
                                    deleteHandler(props.editContact!.id)
                                }
                                isLoading={isDeleting}
                                color="secondary"
                                width="49%"
                            />
                            <Button
                                label={t("popup.favourite.submitLabel")}
                                onClick={handleSubmit(
                                    (data) =>
                                        editHandler({
                                            data,
                                            id: props.editContact!.id,
                                        }),
                                    onFormError
                                )}
                                isLoading={isEditing}
                                color="secondary"
                                width="49%"
                            />
                        </>
                    ) : (
                        <Button
                            label={t("popup.favourite.submitLabel")}
                            onClick={handleSubmit(
                                (data) => createHandler(data),
                                onFormError
                            )}
                            isLoading={isCreating}
                            color="secondary"
                            width="100%"
                        />
                    )}
                </div>
            </div>
        </Popup>
    );
}

export default ContactPopup;
