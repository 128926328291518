import {
    DragDropContext,
    DraggableLocation,
    OnDragEndResponder,
} from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { addMinutes, isAfter } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import {
    acceptStopDraftLocationChange,
    deleteStopDraft,
    fulfillStopDraft,
    undoStopDraftLocationChange,
    updateOrderDraft,
    updateStopDrafts,
} from "../../api/stop-draft";
import {
    addStopsToTour,
    archiveStopDraftTour,
    dispatchTour,
    removeStopFromTour,
} from "../../api/tours";
import DotsModal from "../../components/UI/DotsModal";
import Spinner from "../../components/UI/Spinner";
import SwitchModal from "../../components/UI/SwitchModal";
import Button from "../../components/buttons/Button";
import Input from "../../components/inputs/Input";
import WeekDaySelector from "../../components/inputs/WeekDaySelector";
import alertPrompt from "../../components/widgets/alertPrompt";
import CombinedStopCardDraggable from "../../fleet-planner/cards/CombinedStopCardDraggable";
import ExternalCard from "../../fleet-planner/cards/ExternalCard";
import OrderCard from "../../fleet-planner/cards/OrderCard";
import OrderDraftCard from "../../fleet-planner/cards/OrderDraftCard";
import StopDraftDraggable from "../../fleet-planner/cards/StopDraftDraggable";
import FleetPlannerColumn from "../../fleet-planner/columns/FleetPlannerColumn";
import useFleetPlannerData from "../../hooks/data/useFleetPlannerData";
import useDemoMutations from "../../hooks/functionality/useDemoMutations";
import useFileToOrderDraft from "../../hooks/functionality/useFileToOrderDraft";
import useFleetPlannerDrag from "../../hooks/functionality/useFleetPlannerDrag";
import useStopDraftMutations from "../../hooks/functionality/useStopDraftMutations";
import { useFleetRoute } from "../../hooks/route/useFleetRoute";
import FleetPlannerMap from "../../maps/FleetPlannerMap";
import StopDraftsMap from "../../maps/StopDraftsMap";
import EditStopDraftPopup from "../../popups/EditStopDraftPopup";
import ExternalStopsPopup from "../../popups/ExternalStopsPopup";
import GroupTourStopsPopup from "../../popups/GroupTourStopsPopup";
import NewOrderPopup from "../../popups/NewOrderPopup";
import OrderDraftPopup from "../../popups/OrderDraftPopup";
import OrderInfoPopup from "../../popups/OrderInfoPopup";
import StopChangeLocationPopup from "../../popups/StopChangeLocationPopup";
import StopFormPopup from "../../popups/StopFormPopup";
import {
    Driver,
    Order,
    OrderDraft,
    StopDraft,
    StopDraftsTour,
} from "../../shared/types/api";
import { KeyNumber } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import {
    dateToString,
    formatMinutes,
    stringToDate,
} from "../../shared/utility/date";
import { getDriverDisplayName, isEnvironment } from "../../shared/utility/misc";
import { uuidRegex } from "../../shared/utility/regex";
import {
    cardHoverHandler,
    checkForGroupsAround,
    getStopListDurationInMinutes,
    getTourStatus,
} from "../../shared/utility/stop-draft";
import {
    ALRIK_COLUMN_ID,
    CHANGED_LOCATION_COLUMN_ID,
    ORDER_DRAFT_COLUMN_ID,
    OUTSIDE_FLEET_COLUMN_ID,
    STOP_DRAFT_TYPE,
    UNHANDLED_COLUMN_ID,
} from "../../shared/values/enums";
import { setFleetPlannerSettings } from "../../store/slices/fleetPlanner";
import "./style.scss";

type Props = {};

function ActiveFleet(props: Props) {
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const { user } = useSelector((state: ReduxState) => state.auth);
    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);
    const dispatch = useDispatch();

    const [driverSearchString, setDriverSearchString] = useState("");
    const [hiddenDriversIds, setHiddenDriversIds] = useState<string[]>([]);

    const [orderDraftForCreate, setOrderDraftForCreate] =
        useState<OrderDraft | null>(null);
    const [orderDraftDroppedLocation, setOrderDraftDroppedLocation] =
        useState<DraggableLocation | null>(null);

    //loading
    const [detachingIds, setDetachingIds] = useState<number[]>([]);
    const [fulfillIds, setFulfillIds] = useState<number[]>([]);
    const [colorChangeIds, setColorChangeIds] = useState<number[]>([]);
    const [sendingBackIds, setSendingBackIds] = useState<number[]>([]);
    const [acceptingIds, setAcceptingIds] = useState<number[]>([]);

    //popups
    const [mapTourId, setMaptourId] = useState<number | null>(null);
    const [groupTourId, setGroupTourId] = useState<number | null>(null);
    const [isCreateStopDraftPopupOpen, setIsCreateStopDraftPopupOpen] =
        useState(false);
    const [editStopDraft, setEditStopDraft] = useState<StopDraft | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [selectedOrderDraft, setSelectedOrderDraft] =
        useState<OrderDraft | null>(null);
    const [newOrderPopupData, setNewOrderPopupData] = useState<{
        stopdrafts?: { pickup?: StopDraft; dropoff?: StopDraft };
        orderDraft?: OrderDraft;
    } | null>(null);
    const [isStopDraftsMapOpen, setIsStopDraftsMapOpen] = useState(false);
    const [stopsForExternalMarking, setStopsForExternalMarking] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [changeLocationStops, setChangeLocationStops] = useState<number[]>(
        []
    );
    const [editStops, setEditStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);

    const { filterDate, setFilterDate } = useFleetRoute();

    const {
        //Data
        tours,
        etas,
        stopDrafts,
        fleetPlannerOrders,
        orderDrafts,
        drivers,
        movedStops,

        // Statuses
        driversStatus,
        toursStatus,
        stopDraftsStatus,
        fleetPlannerOrdersStatus,

        // Fetching
        isFetchingTours,
        isFetchingEtas,
        isFetchingStopDrafts,
        isFetchingMovedStops,

        reloadData,
    } = useFleetPlannerData({
        filterDate: dateToString(filterDate),
        driversFromCompany:
            !!user?.company_entity?.show_location_orders &&
            !!settings.showAllDriversInCompany,
    });

    const {
        onDragEndStopDraftInTour,
        setColumnIdsLoading,
        columnIdsLoading,
        sortTourPickupsFirst,
        createNewTour,
    } = useFleetPlannerDrag({
        tours,
        stopDrafts,
        filterDate: dateToString(filterDate),
        reloadData,
    });

    const { updateStopDraftHandler, isUpdating, deleteHandler, isDeleting } =
        useStopDraftMutations({
            stopDrafts: stopDrafts || [],
            onSuccess: async () => {
                setEditStopDraft(null);
                await reloadData("stop-drafts");
                queryClient.invalidateQueries({
                    queryKey: ["project-references"],
                    type: "all",
                });
            },
        });

    const {
        uploadFileHandler: uploadFileHandlerNew,
        isUploadingFile: isUploadingFileNew,
    } = useFileToOrderDraft({
        onUploadSuccess: () => reloadData("order-drafts"),
    });

    const {
        createDemoStopDrafts,
        isCreatingDemoStopDrafts,
        deleteAllStopDrafts,
        isCreatingTestStopDrafts,
        createTestStopDrafts,
        isDeletingAllStopDrafts,
        archiveAllActiveTours,
        isArchivingAllActiveTours,
    } = useDemoMutations({
        onSuccess: async () => reloadData("tours", "stop-drafts"),
    });

    const onDragEndAlrik = useCallback(
        ({
            draggableId,
            source,
            destination,
        }: {
            draggableId: string;
            source: DraggableLocation;
            destination: DraggableLocation;
        }) => {
            if (source.droppableId === UNHANDLED_COLUMN_ID) {
                const movedCard = stopDrafts?.find(
                    (stopDraft) => stopDraft.id.toString() === draggableId
                );
                if (!movedCard) return;

                const pickup = stopDrafts?.find(
                    (stop) =>
                        stop.group_id === movedCard.group_id &&
                        stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                );

                const dropoff = stopDrafts?.find(
                    (stop) =>
                        stop.group_id === movedCard.group_id &&
                        stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                );

                setNewOrderPopupData({
                    stopdrafts: {
                        pickup,
                        dropoff,
                    },
                });

                return;
            } else if (source.droppableId === ORDER_DRAFT_COLUMN_ID) {
                setNewOrderPopupData({
                    orderDraft: orderDrafts?.find(
                        (orderDraft) => orderDraft.id.toString() === draggableId
                    ),
                });
                return;
            } else {
                toast.error(t("errorMessage.invalidMoveNeedsDetachment"));
            }
        },
        [orderDrafts, stopDrafts, t]
    );

    const onDragEndSourceOrderDraft = useCallback(
        ({
            draggableId,
            source,
            destination,
        }: {
            draggableId: string;
            source: DraggableLocation;
            destination: DraggableLocation;
        }) => {
            const movedOrderDraft = orderDrafts?.find(
                (od) => od.id.toString() === draggableId
            );

            if (!movedOrderDraft) return;

            setOrderDraftDroppedLocation(destination);
            setOrderDraftForCreate(movedOrderDraft);
            setIsCreateStopDraftPopupOpen(true);
        },
        [orderDrafts]
    );

    const onDragEndOutsideFleet = useCallback(
        async ({
            draggableId,
            source,
            destination,
        }: {
            draggableId: string;
            source: DraggableLocation;
            destination: DraggableLocation;
        }) => {
            if (source.droppableId === UNHANDLED_COLUMN_ID) {
                const movedCard = stopDrafts?.find(
                    (stop) => stop.id.toString() === draggableId
                );

                if (!movedCard) return;

                const pickup = stopDrafts?.find(
                    (stop) =>
                        movedCard.group_id === stop.group_id &&
                        stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                );

                const dropoff = stopDrafts?.find(
                    (stop) =>
                        movedCard.group_id === stop.group_id &&
                        stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                );

                if (!pickup || !dropoff) return;

                setStopsForExternalMarking({ pickup, dropoff });
            } else {
                toast.error(t("errorMessage.invalidMoveNeedsDetachment"));
            }
        },
        [stopDrafts, t]
    );

    const onDragEndChangedLocation = useCallback(
        ({
            draggableId,
            source,
            destination,
        }: {
            draggableId: string;
            source: DraggableLocation;
            destination: DraggableLocation;
        }) => {
            if (source.droppableId === UNHANDLED_COLUMN_ID) {
                const movedCard = stopDrafts?.find(
                    (stop) => stop.id.toString() === draggableId
                );

                if (!movedCard) return;

                // If the card is already assigned from another location
                if (
                    movedCard.origin_location ||
                    !!movedCard.order?.assigned_location_id
                ) {
                    toast.error(t("errorMessage.invalidMoveAlreadyAssigned"));
                    return;
                }

                const pickup = stopDrafts?.find(
                    (stop) =>
                        movedCard.group_id === stop.group_id &&
                        stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                );

                const dropoff = stopDrafts?.find(
                    (stop) =>
                        movedCard.group_id === stop.group_id &&
                        stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                );

                if (!pickup || !dropoff) return;

                setChangeLocationStops([pickup.id, dropoff.id]);
            } else {
                toast.error(t("errorMessage.invalidMoveNeedsDetachment"));
            }
        },
        [stopDrafts, t]
    );

    const onDragEnd = useCallback<OnDragEndResponder>(
        async (result) => {
            const { source, destination, draggableId } = result;

            if (!destination) return;

            //return if the card is dropped in the same place it was picked up
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            )
                return;

            //if the card is from the inbox and dropped somewhere to make a card
            if (
                source.droppableId === ORDER_DRAFT_COLUMN_ID &&
                destination.droppableId !== ALRIK_COLUMN_ID
            ) {
                if (
                    destination.droppableId === ORDER_DRAFT_COLUMN_ID ||
                    destination.droppableId === OUTSIDE_FLEET_COLUMN_ID ||
                    destination.droppableId === CHANGED_LOCATION_COLUMN_ID
                ) {
                    toast.error(t("errorMessage.invalidMoveForOrderDraft"));
                    return;
                }

                onDragEndSourceOrderDraft({ draggableId, source, destination });
                return;
            }

            switch (destination.droppableId) {
                case UNHANDLED_COLUMN_ID:
                    if (source.droppableId !== UNHANDLED_COLUMN_ID) {
                        toast.error(
                            t("errorMessage.invalidMoveNeedsDetachment")
                        );
                    }
                    return;
                case ORDER_DRAFT_COLUMN_ID:
                    return;
                case ALRIK_COLUMN_ID:
                    onDragEndAlrik({ draggableId, source, destination });
                    return;
                case OUTSIDE_FLEET_COLUMN_ID:
                    await onDragEndOutsideFleet({
                        draggableId,
                        source,
                        destination,
                    });
                    return;
                case CHANGED_LOCATION_COLUMN_ID:
                    onDragEndChangedLocation({
                        draggableId,
                        source,
                        destination,
                    });
                    return;
            }

            await onDragEndStopDraftInTour({
                draggableId,
                source,
                destination,
                isFromUnhandledColumn:
                    source.droppableId === UNHANDLED_COLUMN_ID,
                //TODO make this check better
                isDroppedInEmptyColumn: uuidRegex.test(destination.droppableId),
            });
        },
        [
            onDragEndAlrik,
            onDragEndChangedLocation,
            onDragEndOutsideFleet,
            onDragEndSourceOrderDraft,
            onDragEndStopDraftInTour,
            t,
        ]
    );

    const onStopsCreated = useCallback(
        async (createdIds: number[]) => {
            setIsCreateStopDraftPopupOpen(false);

            if (orderDraftForCreate) {
                try {
                    await updateOrderDraft({
                        id: orderDraftForCreate.id,
                        stopDraftIds: createdIds,
                    });
                } catch (error) {
                    toast.error(
                        t(
                            "errorMessage.orderDraftUpdatedStopDraftsCreatedError"
                        )
                    );
                }
            }

            if (
                orderDraftDroppedLocation &&
                orderDraftDroppedLocation.droppableId !== UNHANDLED_COLUMN_ID &&
                orderDraftDroppedLocation.droppableId !==
                    OUTSIDE_FLEET_COLUMN_ID &&
                orderDraftDroppedLocation.droppableId !==
                    CHANGED_LOCATION_COLUMN_ID
            ) {
                if (uuidRegex.test(orderDraftDroppedLocation.droppableId)) {
                    setColumnIdsLoading((state) => [
                        ...state,
                        orderDraftDroppedLocation.droppableId,
                    ]);

                    try {
                        await createNewTour({
                            stopDraftIds: createdIds,
                            preferredDriverId:
                                orderDraftDroppedLocation.droppableId,
                            date: dateToString(filterDate),
                            time: "06:00",
                        });
                    } catch (error) {
                        toast.error(t("errorMessage.tourCreationFailed"));
                    }
                } else {
                    setColumnIdsLoading((state) => [
                        ...state,
                        orderDraftDroppedLocation.droppableId,
                    ]);
                    try {
                        await addStopsToTour({
                            tour_id: +orderDraftDroppedLocation.droppableId,
                            stop_draft_ids: createdIds,
                            index: orderDraftDroppedLocation.index,
                        });
                    } catch (error: any) {
                        if (error.response?.data.error_token) {
                            toast.error(t(error.response?.data.error_token));
                        } else {
                            toast.error(
                                t("errorMessage.tourChangesSavedError")
                            );
                        }
                    }
                }
            }

            setOrderDraftForCreate(null);
            setOrderDraftDroppedLocation(null);
            setIsCreateStopDraftPopupOpen(false);
            await reloadData("order-drafts", "stop-drafts", "tours");
            setColumnIdsLoading((state) =>
                state.filter(
                    (id) => id !== orderDraftDroppedLocation?.droppableId
                )
            );
        },
        [
            createNewTour,
            filterDate,
            orderDraftDroppedLocation,
            orderDraftForCreate,
            reloadData,
            setColumnIdsLoading,
            t,
        ]
    );

    const onOrderCreated = useCallback(
        async (orderId: number) => {
            toast.success(t("successMessage.orderCreated"));

            if (
                newOrderPopupData?.stopdrafts?.pickup &&
                newOrderPopupData.stopdrafts.dropoff
            ) {
                try {
                    await Promise.all([
                        deleteStopDraft(newOrderPopupData.stopdrafts.pickup.id),
                        deleteStopDraft(
                            newOrderPopupData.stopdrafts.dropoff.id
                        ),
                    ]);

                    await reloadData("fleet-planner-orders", "stop-drafts");
                } catch (error) {
                    toast.error(
                        t("errorMessage.stopDraftDeletedOrderCreatedError")
                    );
                }
            }

            if (newOrderPopupData?.orderDraft) {
                try {
                    await updateOrderDraft({
                        id: newOrderPopupData.orderDraft.id,
                        orderId,
                    });

                    await reloadData("order-drafts", "fleet-planner-orders");
                } catch (error) {
                    toast.error(
                        t("errorMessage.orderDraftUpdatedOrderCreatedError")
                    );
                }
            }

            setNewOrderPopupData(null);
        },
        [newOrderPopupData, reloadData, t]
    );

    const archiveTourHandler = useCallback(
        async (tourId: number) => {
            if (
                !(await alertPrompt({
                    title: t("activeFleet.confirmArchiveTourTitle"),
                    message: t("activeFleet.confirmArchiveTour"),
                }))
            ) {
                return;
            }

            setColumnIdsLoading((prev) => [...prev, tourId.toString()]);

            try {
                await archiveStopDraftTour(tourId);

                queryClient.removeQueries({
                    queryKey: ["fulfilled-fleet"],
                    type: "all",
                });

                await reloadData("tours", "etas");

                toast.success(t("activeFleet.tourArchived"));
            } catch (error) {
                toast.error(t("errorMessage.tourArchivedError"));
            } finally {
                setColumnIdsLoading((prev) =>
                    prev.filter((tourId) => tourId !== tourId.toString())
                );
            }
        },
        [queryClient, reloadData, setColumnIdsLoading, t]
    );

    const dispatchTourHandler = useCallback(
        async (tour: StopDraftsTour) => {
            if (
                tour.stops.some((stop) =>
                    stop.date_tooltip ? stop.date_tooltip !== tour.date : false
                )
            ) {
                if (
                    !(await alertPrompt({
                        title: t("activeFleet.dateMismatchTitle"),
                        message: t("activeFleet.dateMismatchMessage"),
                    }))
                )
                    return;
            }

            setColumnIdsLoading((prev) => [...prev, tour.tour_id.toString()]);

            try {
                await dispatchTour(tour.tour_id);
            } catch (error: any) {
                console.log(error);
                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                } else if (error.response?.data.new_order) {
                    toast.error(t("errorMessage.tourDispatchErrorNewOrder"));
                } else if (error.response?.data.new_tour_id) {
                    toast.error(t("errorMessage.tourDispatchErrorNewTour"));
                } else {
                    toast.error(t("errorMessage.tourDispatchError"));
                }
            } finally {
                await reloadData("tours", "etas");
                setColumnIdsLoading((prev) =>
                    prev.filter((tourId) => tourId !== tourId.toString())
                );
            }
        },
        [reloadData, setColumnIdsLoading, t]
    );

    const detachStopHandler = useCallback(
        async (stopDraft: StopDraft) => {
            setDetachingIds((state) => [...state, stopDraft.id]);

            try {
                await removeStopFromTour(stopDraft.id);

                toast.success(t("activeFleet.stopDetached"));

                await reloadData("tours", "stop-drafts", "etas");
            } catch (error) {
                toast.error(t("errorMessage.stopDetachedError"));
            } finally {
                setDetachingIds((state) =>
                    state.filter((id) => id !== stopDraft.id)
                );
            }
        },
        [reloadData, t]
    );

    const sendBackHandler = useCallback(
        async (stopIds: number[]) => {
            setSendingBackIds((state) => [...state, ...stopIds]);

            try {
                await undoStopDraftLocationChange(stopIds);

                toast.success(t("activeFleet.stopSentBack"));
                await reloadData("stop-drafts", "moved-stops");
            } catch (error) {
                toast.error(t("errorMessage.unknown"));
            } finally {
                setSendingBackIds((state) =>
                    state.filter((id) => !stopIds.includes(id))
                );
            }
        },
        [reloadData, t]
    );

    const acceptRequestHandler = useCallback(
        async (stopIds: number[]) => {
            setAcceptingIds((state) => [...state, ...stopIds]);

            try {
                await acceptStopDraftLocationChange(stopIds);

                toast.success(t("activeFleet.stopAccepted"));
                await reloadData("stop-drafts", "moved-stops");
            } catch (error) {
                toast.error(t("errorMessage.unknown"));
            } finally {
                setAcceptingIds((state) =>
                    state.filter((id) => !stopIds.includes(id))
                );
            }
        },
        [reloadData, t]
    );

    const saveColorChangesHandler = useCallback(
        async (stopDraft: StopDraft, color: string | null) => {
            setColorChangeIds((state) => [...state, stopDraft.id]);

            try {
                await updateStopDrafts([
                    {
                        ...stopDraft,
                        color,
                    },
                ]);
                await reloadData("tours", "stop-drafts");

                toast.success(t("activeFleet.stopDraftColorSaved"));
            } catch (error) {
                console.log(error);
                toast.error(t("errorMessage.stopDraftColorSavedError"));
            } finally {
                setColorChangeIds((state) =>
                    state.filter((id) => id !== stopDraft.id)
                );
            }
        },
        [reloadData, t]
    );

    const fulfillExternalStopHandler = useCallback(
        async (stopDraft: StopDraft) => {
            setFulfillIds((state) => [...state, stopDraft.id]);

            try {
                await fulfillStopDraft(stopDraft.id);
                queryClient.removeQueries({
                    queryKey: ["fulfilled-fleet"],
                    type: "all",
                });
                await reloadData("stop-drafts");
                toast.success(t("activeFleet.stopFulfilled"));
            } catch (error) {
                toast.error(t("errorMessage.stopFulfilledError"));
            } finally {
                setFulfillIds((state) =>
                    state.filter((id) => id !== stopDraft.id)
                );
            }
        },
        [queryClient, reloadData, t]
    );

    const driverSearchHandler = useCallback(
        (searchString: string) => {
            setDriverSearchString(searchString);

            if (!searchString) {
                setHiddenDriversIds([]);
                return;
            }

            setHiddenDriversIds((state) => {
                if (!drivers) return state;

                const newState: string[] = [];

                for (let i = 0; i < drivers.length; i++) {
                    const driver = drivers[i];

                    const driverName = getDriverDisplayName(driver);

                    if (
                        !driverName
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) &&
                        driver.mt_driver_id
                    ) {
                        newState.push(driver.mt_driver_id);
                    }
                }
                return newState;
            });
        },
        [drivers]
    );

    const filterDrivers = useCallback(
        (driver: Driver) => {
            return (
                !!driver.mt_driver_id &&
                settings.hiddenDriverIds?.[driver.mt_driver_id] !== 1 &&
                !hiddenDriversIds.includes(driver.mt_driver_id)
            );
        },
        [hiddenDriversIds, settings.hiddenDriverIds]
    );

    const mapTour = useMemo<StopDraftsTour | null>(() => {
        if (!tours || !mapTourId) return null;

        return tours.find((tour) => tour.tour_id === mapTourId) || null;
    }, [mapTourId, tours]);

    const groupTour = useMemo<StopDraftsTour | null>(() => {
        if (!tours || !groupTourId) return null;

        return tours.find((tour) => tour.tour_id === groupTourId) || null;
    }, [groupTourId, tours]);

    const etaMap = useMemo<KeyNumber<string>>(() => {
        if (!tours || !etas) return {};

        const newEtaMap: KeyNumber<string> = {};

        for (let i = 0; i < tours.length; i++) {
            const tour = tours[i];

            for (let j = 0; j < tour.stops.length; j++) {
                const stop = tour.stops[j];
                const eta = etas.find((e) => e.order_id === stop.order_id);

                if (eta) {
                    if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
                        newEtaMap[stop.id] = eta.pickup_expected_arrival_at;
                    } else {
                        newEtaMap[stop.id] = eta.dropoff_expected_arrival_at;
                    }
                }
            }
        }

        return newEtaMap;
    }, [etas, tours]);

    const externalStops = useMemo(() => {
        const newExternalStops: { pickup: StopDraft; dropoff: StopDraft }[] =
            [];
        if (!stopDrafts) return newExternalStops;

        const pickups = stopDrafts
            .filter(
                (stop) =>
                    stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    stop.external &&
                    (stop.date_tooltip
                        ? stop.date_tooltip === dateToString(filterDate)
                        : true)
            )
            .sort((a, b) => {
                const aDate = stringToDate(a.converted_at || "")!;
                const bDate = stringToDate(b.converted_at || "")!;

                if (isAfter(aDate, bDate)) {
                    return -1;
                } else if (isAfter(bDate, aDate)) {
                    return 1;
                } else {
                    return 0;
                }
            });

        for (let i = 0; i < pickups.length; i++) {
            const pickup = pickups[i];

            const dropoff = stopDrafts.find(
                (stop) =>
                    stop.group_id === pickup.group_id &&
                    stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!dropoff) continue;

            newExternalStops.push({ pickup, dropoff });
        }

        return newExternalStops;
    }, [filterDate, stopDrafts]);

    const unhandledStops = useMemo(() => {
        const newUnhandledStops: { pickup: StopDraft; dropoff: StopDraft }[] =
            [];

        if (!stopDrafts) return newUnhandledStops;

        const pickups = stopDrafts.filter(
            (stop) =>
                !stop.external &&
                (settings.filterUnhandledByDate && !!stop.date_tooltip
                    ? stop.date_tooltip === dateToString(filterDate)
                    : true) &&
                stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                (stop.origin_location ? !!stop.move_accepted_at : true)
        );

        for (let i = 0; i < pickups.length; i++) {
            const pickup = pickups[i];

            const dropoff = stopDrafts.find(
                (stop) =>
                    stop.group_id === pickup.group_id &&
                    stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!dropoff) continue;

            newUnhandledStops.push({ pickup, dropoff });
        }

        return newUnhandledStops;
    }, [filterDate, settings.filterUnhandledByDate, stopDrafts]);

    const currentMovedStops = useMemo(() => {
        const newMovedStops: { pickup: StopDraft; dropoff: StopDraft }[] = [];

        if (!movedStops) return newMovedStops;

        const pickups = movedStops.filter(
            (stop) =>
                !stop.external &&
                stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                (settings.filterUnhandledByDate && !!stop.date_tooltip
                    ? stop.date_tooltip === dateToString(filterDate)
                    : true)
        );

        for (let i = 0; i < pickups.length; i++) {
            const pickup = pickups[i];

            const dropoff = movedStops.find(
                (stop) =>
                    stop.group_id === pickup.group_id &&
                    stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!dropoff) continue;

            newMovedStops.push({ pickup, dropoff });
        }

        return newMovedStops;
    }, [filterDate, movedStops, settings.filterUnhandledByDate]);

    const requestedStops = useMemo(() => {
        const newRequestedStops: { pickup: StopDraft; dropoff: StopDraft }[] =
            [];

        if (!stopDrafts) return newRequestedStops;

        const pickups = stopDrafts.filter(
            (stop) =>
                !stop.external &&
                stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                stop.origin_location &&
                !stop.move_accepted_at
        );

        for (let i = 0; i < pickups.length; i++) {
            const pickup = pickups[i];

            const dropoff = stopDrafts.find(
                (stop) =>
                    stop.group_id === pickup.group_id &&
                    stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!dropoff) continue;

            newRequestedStops.push({ pickup, dropoff });
        }

        return newRequestedStops;
    }, [stopDrafts]);

    const isLoading = useMemo(() => {
        return (
            driversStatus === "pending" ||
            toursStatus === "pending" ||
            stopDraftsStatus === "pending" ||
            fleetPlannerOrdersStatus === "pending"
        );
    }, [
        driversStatus,
        fleetPlannerOrdersStatus,
        stopDraftsStatus,
        toursStatus,
    ]);

    return (
        <div className="active-fleet">
            <div className="controls">
                <WeekDaySelector value={filterDate} onChange={setFilterDate} />
                <section>
                    <Button
                        color="secondary"
                        onClick={async () => {
                            await reloadData(
                                "tours",
                                "etas",
                                "stop-drafts",
                                "order-drafts",
                                "fleet-planner-orders",
                                "moved-stops"
                            );
                        }}
                        label={t("activeFleet.updateAllData")}
                        isLoading={
                            isFetchingTours ||
                            isFetchingStopDrafts ||
                            isFetchingEtas ||
                            isFetchingMovedStops
                        }
                    />
                    <a
                        href="https://alrik-content.notion.site/Fleet-Planner-c827ecca0ee24243a25416c6757d631d"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Button
                            color="secondary"
                            label={t("activeFleet.guides")}
                        />
                    </a>
                    {!isEnvironment("production") ? (
                        <DotsModal
                            content={[
                                {
                                    onClick: () => createDemoStopDrafts(),
                                    title: t("activeFleet.createDemoData"),
                                    isLoading: isCreatingDemoStopDrafts,
                                },
                                isEnvironment("staging")
                                    ? {
                                          onClick: () => createTestStopDrafts(),
                                          title: t(
                                              "activeFleet.createTestData"
                                          ),
                                          isLoading: isCreatingTestStopDrafts,
                                      }
                                    : null,
                                {
                                    onClick: () => deleteAllStopDrafts(),
                                    title: t("activeFleet.deleteAllStopDrafts"),
                                    isLoading: isDeletingAllStopDrafts,
                                },
                                {
                                    onClick: () => archiveAllActiveTours(),
                                    title: t(
                                        "activeFleet.archiveAllActiveTours"
                                    ),
                                    isLoading: isArchivingAllActiveTours,
                                },
                            ]}
                        />
                    ) : null}
                    <Input
                        type="text"
                        value={driverSearchString}
                        onChange={driverSearchHandler}
                        placeholder={t("fleetPlanner.searchPlaceholder")}
                        icon="search"
                    />
                    <SwitchModal
                        icon="cog"
                        content={[
                            {
                                title: t("activeFleet.settingsTitle"),
                                items: [
                                    {
                                        title: t(
                                            "fleetPlanner.orderDraftsSettingsTitle"
                                        ),
                                        isActive: !!settings?.showInboxColumn,
                                        onChange: (value) =>
                                            dispatch(
                                                setFleetPlannerSettings({
                                                    showInboxColumn: value
                                                        ? 1
                                                        : 0,
                                                })
                                            ),
                                    },
                                    {
                                        title: t(
                                            "fleetPlanner.alrikSettingsTitle"
                                        ),
                                        isActive: !!settings?.showAlrikColumn,
                                        onChange: (value) =>
                                            dispatch(
                                                setFleetPlannerSettings({
                                                    showAlrikColumn: value
                                                        ? 1
                                                        : 0,
                                                })
                                            ),
                                    },
                                    {
                                        title: t(
                                            "fleetPlanner.outsideFleetSettingsTitle"
                                        ),
                                        isActive: !!settings?.showOutsideColumn,
                                        onChange: (value) =>
                                            dispatch(
                                                setFleetPlannerSettings({
                                                    showOutsideColumn: value
                                                        ? 1
                                                        : 0,
                                                })
                                            ),
                                    },
                                    {
                                        title: t(
                                            "fleetPlanner.cargoContentSettingsTitle"
                                        ),
                                        isActive: !!settings?.showCargoContent,
                                        onChange: (value) =>
                                            dispatch(
                                                setFleetPlannerSettings({
                                                    showCargoContent: value
                                                        ? 1
                                                        : 0,
                                                })
                                            ),
                                    },
                                    {
                                        title: t(
                                            "fleetPlanner.filterUnhandledByDateSettingsTitle"
                                        ),
                                        isActive:
                                            !!settings?.filterUnhandledByDate,
                                        onChange: (value) =>
                                            dispatch(
                                                setFleetPlannerSettings({
                                                    filterUnhandledByDate: value
                                                        ? 1
                                                        : 0,
                                                })
                                            ),
                                    },
                                ],
                            },
                            {
                                title: t("activeFleet.hideDriversTitle"),
                                items:
                                    drivers?.map((driver) => ({
                                        title: getDriverDisplayName(driver, {
                                            showOrganization:
                                                !!settings.showAllDriversInCompany,
                                        }),
                                        isActive:
                                            !settings?.hiddenDriverIds?.[
                                                driver.mt_driver_id || ""
                                            ] && !!driver.mt_driver_id,
                                        onChange: (value) =>
                                            dispatch(
                                                setFleetPlannerSettings({
                                                    hiddenDriverIds: {
                                                        ...settings?.hiddenDriverIds,
                                                        [driver.mt_driver_id ||
                                                        ""]: value ? 0 : 1,
                                                    },
                                                })
                                            ),
                                        disabled: !driver.mt_driver_id,
                                    })) || [],
                            },
                        ]}
                    />
                </section>
            </div>
            <ScrollContainer
                className="content"
                ignoreElements=".stop-draft-card, .popup, .order-draft-card, .combined-stop-card"
                vertical={false}
                hideScrollbars={false}
            >
                {isLoading ? (
                    <Spinner />
                ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                        {!!settings.showInboxColumn && (
                            <FleetPlannerColumn
                                key={ORDER_DRAFT_COLUMN_ID}
                                id={ORDER_DRAFT_COLUMN_ID}
                                theme={ORDER_DRAFT_COLUMN_ID}
                                title={t("fleetPlanner.orderDraftsTitle")}
                                isLoading={columnIdsLoading.includes(
                                    ORDER_DRAFT_COLUMN_ID
                                )}
                                controls={{
                                    fileUpload: {
                                        onChange: (files) =>
                                            files[0]
                                                ? uploadFileHandlerNew(files[0])
                                                : null,
                                        isLoading: isUploadingFileNew,
                                        disabled: isUploadingFileNew,
                                    },
                                }}
                                cards={[
                                    ...(requestedStops?.map((stops, i) => (
                                        <CombinedStopCardDraggable
                                            key={stops.pickup.id}
                                            index={i}
                                            stops={stops}
                                            shareActions={{
                                                onAccept: () =>
                                                    acceptRequestHandler([
                                                        stops.pickup.id,
                                                        stops.dropoff.id,
                                                    ]),
                                                onDecline: () =>
                                                    sendBackHandler([
                                                        stops.pickup.id,
                                                        stops.dropoff.id,
                                                    ]),
                                                isLoading:
                                                    sendingBackIds.includes(
                                                        stops.pickup.id
                                                    ) ||
                                                    acceptingIds.includes(
                                                        stops.pickup.id
                                                    ),
                                            }}
                                            isDragDisabled
                                        />
                                    )) || []),
                                    ...(orderDrafts?.map((orderDraft, i) => (
                                        <OrderDraftCard
                                            key={orderDraft.id}
                                            orderDraft={orderDraft}
                                            index={
                                                i +
                                                (requestedStops?.length || 0)
                                            }
                                            onClick={() =>
                                                setSelectedOrderDraft(
                                                    orderDraft
                                                )
                                            }
                                        />
                                    )) || []),
                                ]}
                            />
                        )}
                        {/* UNHANDLED START */}
                        <FleetPlannerColumn
                            key={UNHANDLED_COLUMN_ID}
                            id={UNHANDLED_COLUMN_ID}
                            theme={UNHANDLED_COLUMN_ID}
                            title={t("fleetPlanner.unhandledTitle")}
                            isLoading={columnIdsLoading.includes(
                                UNHANDLED_COLUMN_ID
                            )}
                            controls={{
                                create: {
                                    onClick: () =>
                                        setIsCreateStopDraftPopupOpen(true),
                                    title: t("fleetPlanner.addStopsLabel"),
                                },
                                map: {
                                    onClick: () => setIsStopDraftsMapOpen(true),
                                    title: t("fleetPlanner.stopMapLabel"),
                                },
                            }}
                            cards={unhandledStops.map((stops, i) => (
                                <CombinedStopCardDraggable
                                    key={stops.pickup.id}
                                    index={i}
                                    stops={stops}
                                    onColorChange={saveColorChangesHandler}
                                    isPickupChangingColor={colorChangeIds.includes(
                                        stops.pickup.id
                                    )}
                                    isDropoffChangingColor={colorChangeIds.includes(
                                        stops.dropoff.id
                                    )}
                                    modalContent={[
                                        !stops.pickup.order
                                            ?.assigned_location_id
                                            ? {
                                                  title: t(
                                                      "fleetPlanner.editStops"
                                                  ),
                                                  onClick: () => {
                                                      setEditStops(stops);
                                                      setIsCreateStopDraftPopupOpen(
                                                          true
                                                      );
                                                  },
                                              }
                                            : null,
                                        stops.pickup.origin_location
                                            ? {
                                                  title: t(
                                                      "fleetPlanner.sendBackToOriginLocation"
                                                  ),
                                                  onClick: () =>
                                                      sendBackHandler([
                                                          stops.pickup.id,
                                                          stops.dropoff.id,
                                                      ]),
                                              }
                                            : !user?.company_entity
                                                  ?.hidden_locations &&
                                              user?.company_entity
                                                  ?.show_location_orders
                                            ? {
                                                  title: t(
                                                      "fleetPlanner.changeLocation"
                                                  ),
                                                  onClick: () =>
                                                      setChangeLocationStops([
                                                          stops.pickup.id,
                                                          stops.dropoff.id,
                                                      ]),
                                              }
                                            : null,
                                    ]}
                                />
                            ))}
                        />
                        {/* UNHANDLED END */}
                        <div className="dotted-line" />
                        <div className="other-fleet-wrapper">
                            {!!settings.showAlrikColumn && (
                                <FleetPlannerColumn
                                    key={ALRIK_COLUMN_ID}
                                    theme={ALRIK_COLUMN_ID}
                                    id={ALRIK_COLUMN_ID}
                                    title={t("fleetPlanner.alrikTitle")}
                                    cards={
                                        fleetPlannerOrders?.items.map(
                                            (order) => (
                                                <OrderCard
                                                    key={order.id}
                                                    order={order}
                                                    onClick={() =>
                                                        setSelectedOrder(order)
                                                    }
                                                />
                                            )
                                        ) || []
                                    }
                                />
                            )}
                            {!user?.company_entity?.hidden_locations &&
                                user?.company_entity?.show_location_orders && (
                                    <FleetPlannerColumn
                                        key={CHANGED_LOCATION_COLUMN_ID}
                                        theme={CHANGED_LOCATION_COLUMN_ID}
                                        id={CHANGED_LOCATION_COLUMN_ID}
                                        isLoading={columnIdsLoading.includes(
                                            CHANGED_LOCATION_COLUMN_ID
                                        )}
                                        title={t(
                                            "fleetPlanner.changedLocationTitle"
                                        )}
                                        cards={currentMovedStops.map(
                                            (stops, i) => (
                                                <CombinedStopCardDraggable
                                                    key={stops.pickup.id}
                                                    stops={stops}
                                                    index={i}
                                                    draggableId={`changed-location-${stops.pickup.id}`}
                                                    isDragDisabled
                                                    onClick={() =>
                                                        setSelectedOrder(
                                                            stops.pickup
                                                                .order || null
                                                        )
                                                    }
                                                />
                                            )
                                        )}
                                    />
                                )}
                            {!!settings.showOutsideColumn && (
                                <FleetPlannerColumn
                                    key={OUTSIDE_FLEET_COLUMN_ID}
                                    theme={OUTSIDE_FLEET_COLUMN_ID}
                                    id={OUTSIDE_FLEET_COLUMN_ID}
                                    isLoading={columnIdsLoading.includes(
                                        OUTSIDE_FLEET_COLUMN_ID
                                    )}
                                    title={t("fleetPlanner.outsideFleetTitle")}
                                    cards={externalStops.map((stops) => (
                                        <ExternalCard
                                            key={stops.pickup.group_id}
                                            stops={stops}
                                            onClick={() =>
                                                setSelectedOrder(
                                                    stops.pickup.order || null
                                                )
                                            }
                                            modalControls={[
                                                {
                                                    title: t(
                                                        "fleetPlanner.fulfill"
                                                    ),

                                                    onClick: () =>
                                                        fulfillExternalStopHandler(
                                                            stops.pickup
                                                        ),
                                                    isLoading:
                                                        fulfillIds.includes(
                                                            stops.pickup.id
                                                        ) ||
                                                        fulfillIds.includes(
                                                            stops.dropoff.id
                                                        ),
                                                },
                                                {
                                                    title: t(
                                                        "fleetPlanner.detach"
                                                    ),
                                                    onClick: () =>
                                                        detachStopHandler(
                                                            stops.pickup
                                                        ),
                                                    isLoading:
                                                        detachingIds.includes(
                                                            stops.pickup.id
                                                        ) ||
                                                        detachingIds.includes(
                                                            stops.dropoff.id
                                                        ),
                                                },
                                            ]}
                                        />
                                    ))}
                                />
                            )}
                        </div>
                        {/* TOURS START */}
                        {tours
                            ?.filter(
                                (tour) =>
                                    !hiddenDriversIds.includes(
                                        tour.preferred_driver_id
                                    )
                            )
                            .map((tour) => {
                                const {
                                    isUndispatched,
                                    hasUndispatchedChanges,
                                    hideEta,
                                } = getTourStatus(tour);

                                const tourDuration = formatMinutes(
                                    getStopListDurationInMinutes(tour.stops)
                                );

                                const endDate = stringToDate(
                                    etaMap[
                                        tour.stops[tour.stops.length - 1].id
                                    ] || ""
                                );

                                const endTime = endDate
                                    ? dateToString(
                                          addMinutes(
                                              endDate,
                                              tour.stops[tour.stops.length - 1]
                                                  .estimated_offload_time
                                          ),
                                          {
                                              onlyTime: true,
                                          }
                                      )
                                    : undefined;

                                return (
                                    <FleetPlannerColumn
                                        key={tour.tour_id.toString()}
                                        id={tour.tour_id.toString()}
                                        title={tour.preferred_driver}
                                        driverOrganization={
                                            !!settings.showAllDriversInCompany
                                                ? tour.preferred_driver_org_name
                                                : undefined
                                        }
                                        isLoading={columnIdsLoading.includes(
                                            tour.tour_id.toString()
                                        )}
                                        isUndispatched={isUndispatched}
                                        hasUndispatchedChanges={
                                            hasUndispatchedChanges
                                        }
                                        infoData={{
                                            date: tour.date,
                                            duration: tourDuration.toString(),
                                            endTime: endTime,
                                            showEndTime: !hideEta,
                                        }}
                                        controls={{
                                            dispatch:
                                                isUndispatched ||
                                                hasUndispatchedChanges
                                                    ? {
                                                          onClick: () => {
                                                              dispatchTourHandler(
                                                                  tour
                                                              );
                                                          },
                                                          title: isUndispatched
                                                              ? t(
                                                                    "fleetPlanner.dispatchTourFirstTime"
                                                                )
                                                              : t(
                                                                    "fleetPlanner.dispatchTour"
                                                                ),
                                                          isLoading:
                                                              columnIdsLoading.includes(
                                                                  tour.tour_id.toString()
                                                              ),
                                                      }
                                                    : undefined,
                                            map: {
                                                onClick: () =>
                                                    setMaptourId(tour.tour_id),
                                                title: "",
                                            },
                                        }}
                                        modalControls={[
                                            {
                                                title: t(
                                                    "createTour.groupLabel"
                                                ),
                                                onClick: () =>
                                                    setGroupTourId(
                                                        tour.tour_id
                                                    ),
                                            },
                                            {
                                                title: t(
                                                    "createTour.archiveLabel"
                                                ),
                                                onClick: () =>
                                                    archiveTourHandler(
                                                        tour.tour_id
                                                    ),
                                            },
                                            {
                                                title: t(
                                                    "createTour.sortByType"
                                                ),
                                                onClick: () =>
                                                    sortTourPickupsFirst({
                                                        tourId: tour.tour_id,
                                                        stops: tour.stops,
                                                    }),
                                            },
                                        ]}
                                        cards={tour.stops.map((stop, i) => (
                                            <StopDraftDraggable
                                                key={stop.id}
                                                index={i}
                                                stopDraft={stop}
                                                eta={etaMap[stop.id]}
                                                hideEta={hideEta}
                                                modalContent={[
                                                    {
                                                        title: t(
                                                            "fleetPlanner.detach"
                                                        ),
                                                        onClick: () =>
                                                            detachStopHandler(
                                                                stop
                                                            ),
                                                        isLoading:
                                                            detachingIds.includes(
                                                                stop.id
                                                            ),
                                                    },
                                                ]}
                                                onColorChange={
                                                    saveColorChangesHandler
                                                }
                                                isChangingColor={colorChangeIds.includes(
                                                    stop.id
                                                )}
                                                hasGroupsAround={checkForGroupsAround(
                                                    {
                                                        stop,
                                                        stopAbove:
                                                            tour.stops[i - 1],
                                                        stopBelow:
                                                            tour.stops[i + 1],
                                                    }
                                                )}
                                                onClick={() =>
                                                    setSelectedOrder(
                                                        stop.order || null
                                                    )
                                                }
                                                onHover={(groupId) =>
                                                    cardHoverHandler({
                                                        groupId,
                                                        columnId:
                                                            tour.tour_id.toString(),
                                                    })
                                                }
                                                isUndispatched={
                                                    !stop.tour_stop_id
                                                }
                                                isInWrongDate={
                                                    stop.date_tooltip
                                                        ? dateToString(
                                                              filterDate
                                                          ) !==
                                                          stop.date_tooltip
                                                        : false
                                                }
                                            />
                                        ))}
                                    />
                                );
                            })}
                        {/* TOURS END */}
                        {(!!tours?.length ||
                            !!settings.showAlrikColumn ||
                            !!settings.showOutsideColumn) && (
                            <div className="dotted-line" />
                        )}
                        {/* DRIVERS START */}
                        {drivers?.filter(filterDrivers).map((driver) => (
                            <FleetPlannerColumn
                                key={driver.mt_driver_id}
                                id={driver.mt_driver_id!}
                                title={getDriverDisplayName(driver)}
                                driverOrganization={
                                    !!settings.showAllDriversInCompany
                                        ? driver.mt_organization_name
                                        : undefined
                                }
                                isLoading={columnIdsLoading.includes(
                                    driver.mt_driver_id!
                                )}
                                cards={[]}
                            />
                        ))}
                        {/* DRIVERS END */}
                    </DragDropContext>
                )}
            </ScrollContainer>
            <StopDraftsMap
                showPopup={isStopDraftsMapOpen}
                onClose={() => setIsStopDraftsMapOpen(false)}
                stopDrafts={stopDrafts?.filter((stop) => !stop.external) || []}
                drivers={drivers || []}
                onTourCreated={() => reloadData("tours", "stop-drafts")}
                preSelectedDate={
                    settings.filterUnhandledByDate
                        ? filterDate || undefined
                        : undefined
                }
            />
            {!!mapTour && (
                <FleetPlannerMap
                    title={mapTour.preferred_driver}
                    showPopup={!!mapTour}
                    onClose={() => setMaptourId(null)}
                    onDragEnd={onDragEnd}
                    tour={mapTour}
                    onSortClick={() =>
                        sortTourPickupsFirst({
                            tourId: mapTour.tour_id,
                            stops: mapTour.stops,
                        })
                    }
                    droppableId={mapTour.tour_id.toString()}
                    isColumnLoading={columnIdsLoading.includes(
                        mapTour.tour_id.toString()
                    )}
                />
            )}
            {!!groupTour && (
                <GroupTourStopsPopup
                    showPopup={!!groupTour}
                    onClose={() => setGroupTourId(null)}
                    droppableId={groupTour.tour_id.toString()}
                    onDragEnd={onDragEnd}
                    isColumnLoading={columnIdsLoading.includes(
                        groupTour.tour_id.toString()
                    )}
                    tour={groupTour}
                    onStopsGrouped={() => reloadData("tours")}
                    onSortClick={() =>
                        sortTourPickupsFirst({
                            tourId: groupTour.tour_id,
                            stops: groupTour.stops,
                        })
                    }
                />
            )}
            <StopFormPopup
                showPopup={isCreateStopDraftPopupOpen}
                onClose={() => {
                    setIsCreateStopDraftPopupOpen(false);
                    setOrderDraftForCreate(null);
                    setEditStops(null);
                }}
                onCreated={onStopsCreated}
                onEdited={() => {
                    reloadData("stop-drafts");
                    setIsCreateStopDraftPopupOpen(false);
                    setOrderDraftForCreate(null);
                    setEditStops(null);
                }}
                onDeleted={() => {
                    reloadData("stop-drafts");
                    setIsCreateStopDraftPopupOpen(false);
                    setOrderDraftForCreate(null);
                    setEditStops(null);
                }}
                editStops={editStops}
                orderDraft={orderDraftForCreate}
            />
            <EditStopDraftPopup
                showPopup={!!editStopDraft}
                onClose={() => setEditStopDraft(null)}
                onEdit={updateStopDraftHandler}
                onDelete={deleteHandler}
                isSaving={isUpdating}
                isDeleting={isDeleting}
                stopDraft={editStopDraft}
                pairStopDraft={
                    stopDrafts?.find(
                        (stop) =>
                            stop.group_id === editStopDraft?.group_id &&
                            stop.stop_type_id !== editStopDraft?.stop_type_id
                    ) || null
                }
            />
            {stopsForExternalMarking && (
                <ExternalStopsPopup
                    showPopup={!!stopsForExternalMarking}
                    onClose={() => setStopsForExternalMarking(null)}
                    onSubmit={() => reloadData("stop-drafts")}
                    pickup={stopsForExternalMarking.pickup}
                    dropoff={stopsForExternalMarking.dropoff}
                    preselectedDate={dateToString(filterDate)}
                />
            )}

            <StopChangeLocationPopup
                showPopup={!!changeLocationStops.length}
                onClose={() => setChangeLocationStops([])}
                stopIds={changeLocationStops}
                onLocationChange={() =>
                    reloadData("stop-drafts", "moved-stops")
                }
            />
            <OrderInfoPopup
                showPopup={!!selectedOrder}
                onClose={() => setSelectedOrder(null)}
                order={selectedOrder}
            />

            <OrderDraftPopup
                showPopup={!!selectedOrderDraft}
                onClose={() => setSelectedOrderDraft(null)}
                orderDraft={selectedOrderDraft}
                onDeleted={() => reloadData("order-drafts")}
            />
            <NewOrderPopup
                showPopup={!!newOrderPopupData}
                onClose={() => setNewOrderPopupData(null)}
                orderDraft={newOrderPopupData?.orderDraft}
                stopDrafts={newOrderPopupData?.stopdrafts}
                onOrderCreated={onOrderCreated}
                isFromFleetPlanner
            />
        </div>
    );
}

export default ActiveFleet;
