import { NavLink } from "react-router-dom";
import { NavItem } from "../../../shared/types/internal";
import TextButton from "../../buttons/TextButton";
import Beta from "../BetaIcon";
import NewIcon from "../NewIcon";
import "./style.scss";

type Props = {
    navItems: NavItem[];
};

function RouteNavigation(props: Props) {
    return (
        <div className="route-navigation">
            {props.navItems.map((nav) => (
                <NavLink
                    id={nav.id}
                    key={nav.id}
                    to={nav.path}
                    className="nav-item text-m-r"
                    children={({ isActive }) => (
                        <>
                            <TextButton
                                label={nav.label}
                                color={
                                    isActive
                                        ? "var(--text-color)"
                                        : "var(--text-color-light)"
                                }
                                className="text-m-r"
                                style={{ textDecoration: "none" }}
                            />
                            {nav.isBeta && (
                                <Beta
                                    style={{
                                        top: -10,
                                        left: "calc(100% - 20px)",
                                        position: "absolute",
                                    }}
                                />
                            )}
                            {nav.newExpires && (
                                <NewIcon
                                    style={{
                                        top: -10,
                                        left: "calc(100% - 20px)",
                                        position: "absolute",
                                    }}
                                    expiryDate={nav.newExpires}
                                />
                            )}
                        </>
                    )}
                />
            ))}
        </div>
    );
}

export default RouteNavigation;
