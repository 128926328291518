import {
    eachDayOfInterval,
    eachMonthOfInterval,
    eachWeekOfInterval,
    endOfDay,
    endOfMonth,
    endOfWeek,
    format,
    isWithinInterval,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subWeeks,
} from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Spinner from "../../components/UI/Spinner";
import Button from "../../components/buttons/Button";
import BarChart from "../../components/charts/BarChart";
import LineChart from "../../components/charts/LineChart";
import DateInput from "../../components/inputs/DateInput";
import Dropdown from "../../components/inputs/Dropdown";
import Figure, { FigureProps } from "../../components/widgets/Figure";
import useDrivers from "../../hooks/data/useDrivers";
import useLocationStats from "../../hooks/data/useLocationStats";
import DownloadDashboardPopup from "../../popups/DownloadDashboardPopup";
import {
    LocationDeliveredStats,
    LocationDriverCostStats,
    LocationStats,
    LocationStatsData,
} from "../../shared/types/api";
import { DeepNonNullableFields } from "../../shared/types/internal";
import { dateToString, stringToDate } from "../../shared/utility/date";
import { ROUTE } from "../../shared/values/enums";
import "./style.scss";

type GranularityOption = "day" | "week" | "month";

type Props = {};

function FleetDashboard(props: Props) {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState<Date | null>(
        startOfYear(new Date())
    );
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [granularity, setGranularity] = useState<GranularityOption>("week");
    const [isDownloadPopupOpen, setisDownloadPopupOpen] = useState(false);

    const { drivers } = useDrivers();
    const { locationStats, isFetching: isFetchingLocationStats } =
        useLocationStats({
            startDate: startDate
                ? dateToString(startOfDay(startDate), {
                      clock: true,
                  })
                : "",
            endDate: endDate
                ? dateToString(endOfDay(endDate), {
                      clock: true,
                  })
                : "",
        });

    const labels = useMemo<string[]>(() => {
        if (!startDate || !endDate) return [];

        if (granularity === "week") {
            return eachWeekOfInterval({
                start: startDate,
                end: endDate,
            }).map((date) => format(endOfWeek(date), "yyyy-ww"));
        }
        if (granularity === "month") {
            return eachMonthOfInterval({
                start: startDate,
                end: endDate,
            }).map((date) => format(date, "yyyy-MM"));
        }

        return eachDayOfInterval({
            start: startDate,
            end: endDate,
        }).map((date) => dateToString(date));
    }, [endDate, granularity, startDate]);

    const formattedLocationStats = useMemo<
        DeepNonNullableFields<LocationStatsData>
    >(() => {
        if (!locationStats || !startDate || !endDate) {
            return {
                stats: [],
                delivered_orders: [],
                driver_cost_stats: [],
            };
        }

        const stats: DeepNonNullableFields<LocationStats[]> = [];
        const deliveredOrders: DeepNonNullableFields<LocationDeliveredStats[]> =
            [];
        const driverCostStats: DeepNonNullableFields<
            LocationDriverCostStats[]
        > = [];

        let dates = eachDayOfInterval({
            start: startDate,
            end: endDate,
        });

        if (granularity === "week") {
            dates = eachWeekOfInterval(
                {
                    start: startDate,
                    end: endDate,
                },
                {
                    weekStartsOn: 1,
                }
            );
        }

        if (granularity === "month") {
            dates = eachMonthOfInterval({
                start: startDate,
                end: endDate,
            });
        }

        for (let i = 0; i < dates.length; i++) {
            const date = dates[i];

            const startOfInterval = date;
            let endOfInterval = endOfDay(startOfInterval);
            if (granularity === "week") {
                endOfInterval = endOfWeek(startOfInterval, { weekStartsOn: 1 });
            }
            if (granularity === "month") {
                endOfInterval = endOfMonth(endOfInterval);
            }

            const driverCostStatsForInterval =
                locationStats.driver_cost_stats.filter((stat) =>
                    isWithinInterval(stringToDate(stat.date)!, {
                        start: startOfInterval,
                        end: endOfInterval,
                    })
                );

            const deliveredOrdersForInterval =
                locationStats.delivered_orders.filter((stat) =>
                    isWithinInterval(stringToDate(stat.delivery_date)!, {
                        start: startOfInterval,
                        end: endOfInterval,
                    })
                );

            const statsForInterval = locationStats.stats.filter((stat) =>
                isWithinInterval(stringToDate(stat.delivery_date)!, {
                    start: startOfInterval,
                    end: endOfInterval,
                })
            );

            stats.push({
                delivery_date: dateToString(startOfInterval),
                total_deliveries_with_emissions: statsForInterval.reduce(
                    (acc, stat) =>
                        acc +
                        Math.round(stat.total_deliveries_with_emissions || 0),
                    0
                ),
                total_emission_km: statsForInterval.reduce(
                    (acc, stat) =>
                        acc + Math.round(stat.total_emission_km || 0),
                    0
                ),
                total_saved_emissions_fuel: statsForInterval.reduce(
                    (acc, stat) =>
                        acc + Math.round(stat.total_saved_emissions_fuel || 0),
                    0
                ),
                total_saved_emissions_km: statsForInterval.reduce(
                    (acc, stat) =>
                        acc + Math.round(stat.total_saved_emissions_km || 0),
                    0
                ),
                total_distance_km: statsForInterval.reduce(
                    (acc, stat) =>
                        acc + Math.round(stat.total_distance_km || 0),
                    0
                ),
                total_emission: statsForInterval.reduce(
                    (acc, stat) => acc + Math.round(stat.total_emission || 0),
                    0
                ),
            });

            deliveredOrders.push({
                delivery_date: dateToString(startOfInterval),
                total_deliveries: deliveredOrdersForInterval.reduce(
                    (acc, stat) => acc + Math.round(stat.total_deliveries || 0),
                    0
                ),
            });

            driverCostStats.push({
                date: dateToString(startOfInterval),
                activity_cost: driverCostStatsForInterval.reduce(
                    (acc, stat) => acc + Math.round(stat.activity_cost || 0),
                    0
                ),
                online_cost: driverCostStatsForInterval.reduce(
                    (acc, stat) => acc + Math.round(stat.online_cost || 0),
                    0
                ),
                orders_delivered_with_cost: driverCostStatsForInterval.reduce(
                    (acc, stat) =>
                        acc + Math.round(stat.orders_delivered_with_cost || 0),
                    0
                ),
            });
        }

        return {
            stats,
            delivered_orders: deliveredOrders,
            driver_cost_stats: driverCostStats,
        };
    }, [endDate, granularity, locationStats, startDate]);

    const hasDriversWithMissingData = useMemo<boolean>(() => {
        if (!drivers) return false;

        return drivers.some(
            (d) => !d.fuel_type || !d.hourly_cost || !d.fuel_consumption
        );
    }, [drivers]);

    const figuresData = useMemo<{
        deliveries: FigureProps;
        emissions: FigureProps;
        totalCost: FigureProps;
        distance: FigureProps;
        distancePerDelivery: FigureProps;
        emmisionPerDelivery: FigureProps;
    } | null>(() => {
        if (!locationStats) return null;

        const today = new Date();

        let interval = {
            start: today,
            end: endOfDay(today),
        };
        let compareInterval = {
            start: subDays(today, 1),
            end: endOfDay(subDays(today, 1)),
        };

        if (granularity === "week") {
            interval = {
                start: startOfWeek(today, { weekStartsOn: 1 }),
                end: today,
            };
            compareInterval = {
                start: startOfWeek(subWeeks(today, 1)),
                end: subWeeks(today, 1),
            };
        }

        if (granularity === "month") {
            interval = {
                start: startOfMonth(today),
                end: today,
            };
            compareInterval = {
                start: startOfMonth(subMonths(today, 1)),
                end: subMonths(today, 1),
            };
        }

        const deliveries = locationStats.delivered_orders
            .filter((stat) =>
                isWithinInterval(stringToDate(stat.delivery_date)!, interval)
            )
            .reduce((acc, stat) => acc + (stat.total_deliveries || 0), 0);
        const compareDeliveries = locationStats.delivered_orders
            .filter((stat) =>
                isWithinInterval(
                    stringToDate(stat.delivery_date)!,
                    compareInterval
                )
            )
            .reduce((acc, stat) => acc + (stat.total_deliveries || 0), 0);

        const emmisions = locationStats.stats
            .filter((stat) =>
                isWithinInterval(stringToDate(stat.delivery_date)!, interval)
            )
            .reduce((acc, stat) => acc + (stat.total_emission || 0), 0);
        const compareEmmisions = locationStats.stats
            .filter((stat) =>
                isWithinInterval(
                    stringToDate(stat.delivery_date)!,
                    compareInterval
                )
            )
            .reduce((acc, stat) => acc + (stat.total_emission || 0), 0);

        const totalCost = locationStats.driver_cost_stats
            .filter((stat) =>
                isWithinInterval(stringToDate(stat.date)!, interval)
            )
            .reduce((acc, stat) => acc + (stat.activity_cost || 0), 0);
        const compareTotalCost = locationStats.driver_cost_stats
            .filter((stat) =>
                isWithinInterval(stringToDate(stat.date)!, compareInterval)
            )
            .reduce((acc, stat) => acc + (stat.activity_cost || 0), 0);

        const distance = locationStats.stats
            .filter((stat) =>
                isWithinInterval(stringToDate(stat.delivery_date)!, interval)
            )
            .reduce((acc, stat) => acc + (stat.total_emission_km || 0), 0);
        const compareDistance = locationStats.stats
            .filter((stat) =>
                isWithinInterval(
                    stringToDate(stat.delivery_date)!,
                    compareInterval
                )
            )
            .reduce((acc, stat) => acc + (stat.total_emission_km || 0), 0);

        const distancePerDelivery = distance / deliveries || 0;
        const compareDistancePerDelivery =
            compareDistance / compareDeliveries || 0;

        const emmisionPerDelivery = emmisions / deliveries || 0;
        const compareEmmisionPerDelivery =
            compareEmmisions / compareDeliveries || 0;

        let granularityKey = "";
        if (granularity === "day") granularityKey = "fleetDashboard.thisDay";
        if (granularity === "week") granularityKey = "fleetDashboard.thisWeek";
        if (granularity === "month")
            granularityKey = "fleetDashboard.thisMonth";

        return {
            deliveries: {
                title: `${t("fleetDashboard.deliveries")} ${t(granularityKey)}`,
                value: deliveries,
                compareValue: compareDeliveries,
            },
            emissions: {
                title: `${t("fleetDashboard.emmisions")} ${t(granularityKey)}`,
                value: Math.round(emmisions),
                compareValue: Math.round(compareEmmisions),
            },
            totalCost: {
                title: `${t("fleetDashboard.cost")} ${t(granularityKey)}`,
                value: Math.round(totalCost),
                compareValue: Math.round(compareTotalCost),
            },
            distance: {
                title: `${t("fleetDashboard.distance")} ${t(granularityKey)}`,
                value: Math.round(distance),
                compareValue: Math.round(compareDistance),
            },
            distancePerDelivery: {
                title: `${t("fleetDashboard.distancePerDelivery")} ${t(
                    granularityKey
                )}`,
                value: Math.round(distancePerDelivery),
                compareValue: Math.round(compareDistancePerDelivery),
            },
            emmisionPerDelivery: {
                title: `${t("fleetDashboard.emmisionPerDelivery")} ${t(
                    granularityKey
                )}`,
                value: Math.round(emmisionPerDelivery),
                compareValue: Math.round(compareEmmisionPerDelivery),
            },
        };
    }, [granularity, locationStats, t]);

    const getEmissionSavedPercentage = useCallback(
        (stats: DeepNonNullableFields<LocationStats>) => {
            const totalEmission = stats.total_emission;
            const totalSavedEmission =
                stats.total_saved_emissions_fuel +
                stats.total_saved_emissions_km;

            const emissionsSavingsRate =
                totalSavedEmission / (totalEmission + totalSavedEmission);

            return Math.round(emissionsSavingsRate * 100);
        },
        []
    );

    return (
        <div className="fleet-dashboard">
            <div className="top">
                <DateInput
                    value={startDate}
                    onChange={setStartDate}
                    label={t("fleetDashboard.startDate")}
                    labelColor="var(--text-color)"
                    showAllDates
                    ignoreUnselectableDates
                    showWeekNumbers
                />
                <DateInput
                    value={endDate}
                    onChange={setEndDate}
                    label={t("fleetDashboard.endDate")}
                    labelColor="var(--text-color)"
                    showAllDates
                    ignoreUnselectableDates
                    showWeekNumbers
                />
                <Dropdown
                    value={granularity}
                    onSelect={(option) =>
                        setGranularity(option.value as GranularityOption)
                    }
                    options={[
                        {
                            label: t("fleetDashboard.granularityDay"),
                            value: "day",
                        },
                        {
                            label: t("fleetDashboard.granularityWeek"),
                            value: "week",
                        },
                        {
                            label: t("fleetDashboard.granularityMonth"),
                            value: "month",
                        },
                    ]}
                    label={t("fleetDashboard.granularity")}
                    labelColor="var(--text-color)"
                />
                <Button
                    label={t("fleetDashboard.downloadData")}
                    color="secondary"
                    onClick={() => setisDownloadPopupOpen(true)}
                />
                {hasDriversWithMissingData && (
                    <NavLink to={ROUTE.Drivers} className="missing-driver-info">
                        {t("fleetDashboard.hasMissingDriverData")}
                    </NavLink>
                )}
            </div>
            {isFetchingLocationStats ? (
                <Spinner />
            ) : (
                <>
                    {!!figuresData && (
                        <section className="figure-wrapper">
                            <Figure
                                title={figuresData.deliveries.title}
                                value={figuresData.deliveries.value}
                                compareValue={
                                    figuresData.deliveries.compareValue
                                }
                            />
                            <Figure
                                title={figuresData.emissions.title}
                                value={figuresData.emissions.value}
                                compareValue={
                                    figuresData.emissions.compareValue
                                }
                                unit="kg Co2"
                                invertColors
                            />
                            <Figure
                                title={figuresData.emmisionPerDelivery.title}
                                value={figuresData.emmisionPerDelivery.value}
                                compareValue={
                                    figuresData.emmisionPerDelivery.compareValue
                                }
                                unit="kg Co2"
                                invertColors
                            />
                            <Figure
                                title={figuresData.distance.title}
                                value={figuresData.distance.value}
                                compareValue={figuresData.distance.compareValue}
                                unit="km"
                            />
                            <Figure
                                title={figuresData.totalCost.title}
                                value={figuresData.totalCost.value}
                                compareValue={
                                    figuresData.totalCost.compareValue
                                }
                                unit="kr"
                            />
                        </section>
                    )}
                    <div className="content">
                        <LineChart
                            options={{
                                spanGaps: true,
                            }}
                            labels={labels}
                            data={[
                                {
                                    label:
                                        t(
                                            "fleetDashboard.emmisionPerDelivery"
                                        ) + " (kg Co2)",
                                    data: formattedLocationStats.stats.map(
                                        (s) =>
                                            Math.round(
                                                s.total_emission /
                                                    s.total_deliveries_with_emissions
                                            )
                                    ),
                                    borderColor: "#818094",
                                    datalabels: {
                                        display: true,
                                        align: "top",
                                        color: "#0f0f0f",
                                    },
                                },
                                {
                                    label:
                                        t(
                                            "fleetDashboard.distancePerDelivery"
                                        ) + " (km)",
                                    data: formattedLocationStats.stats.map(
                                        (s) =>
                                            Math.round(
                                                s.total_emission_km /
                                                    s.total_deliveries_with_emissions
                                            )
                                    ),
                                    datalabels: {
                                        display: true,
                                        align: "top",
                                        color: "#0f0f0f",
                                    },
                                },
                            ]}
                            title={t("fleetDashboard.emmisionPerDelivery")}
                        />
                        <BarChart
                            labels={labels}
                            data={[
                                {
                                    label: t("fleetDashboard.deliveries"),
                                    data: formattedLocationStats.delivered_orders.map(
                                        (d) => d.total_deliveries
                                    ),
                                },
                            ]}
                            title={t("fleetDashboard.deliveries")}
                        />
                        <BarChart
                            labels={labels}
                            data={[
                                {
                                    label:
                                        t("fleetDashboard.totalEmmisions") +
                                        " (kg Co2)",
                                    data: formattedLocationStats.stats.map(
                                        (s) => s.total_emission
                                    ),
                                    backgroundColor: "#818094",
                                    order: 2,
                                },
                                {
                                    label:
                                        t(
                                            "fleetDashboard.totalSavedEmmisions"
                                        ) + " (kg Co2)",
                                    data: formattedLocationStats.stats.map(
                                        (s) =>
                                            s.total_saved_emissions_fuel +
                                            s.total_saved_emissions_km
                                    ),
                                    order: 2,
                                },
                                {
                                    label: t(
                                        "fleetDashboard.totalSavedEmmisionsPercentage"
                                    ),
                                    data: formattedLocationStats.stats.map(
                                        getEmissionSavedPercentage
                                    ),
                                    type: "line" as any,
                                    yAxisID: "y1",
                                    order: 1,
                                    datalabels: {
                                        display: true,
                                        align: "top",
                                        color: "#0f0f0f",
                                        formatter: (value) => value + "%",
                                    },
                                },
                            ]}
                            options={{
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: (ctx) => {
                                                if (ctx.datasetIndex === 2) {
                                                    return `${ctx.dataset.label?.replace(
                                                        " %",
                                                        ""
                                                    )}: ${ctx.parsed.y}%`;
                                                }
                                                return `${ctx.dataset.label}: ${ctx.parsed.y}`;
                                            },
                                        },
                                    },
                                },
                                scales: {
                                    y: {
                                        stacked: true,
                                    },
                                    y1: {
                                        display: true,
                                        position: "right",
                                        grid: {
                                            drawOnChartArea: false,
                                        },
                                        suggestedMin: 0,
                                        max: 100,
                                        ticks: {
                                            callback: (value) => value + "%",
                                        },
                                    },
                                    x: {
                                        stacked: true,
                                    },
                                },
                            }}
                            title={t("fleetDashboard.emmisions")}
                            description={t(
                                "fleetDashboard.emmisionsDescription"
                            )}
                        />
                        <LineChart
                            options={{
                                spanGaps: true,
                            }}
                            labels={labels}
                            data={[
                                {
                                    label:
                                        t("fleetDashboard.activityCost") +
                                        " (SEK)",
                                    data: formattedLocationStats.driver_cost_stats.map(
                                        (s) =>
                                            Math.round(
                                                s.activity_cost /
                                                    s.orders_delivered_with_cost
                                            )
                                    ),
                                    borderColor: "#818094",
                                    datalabels: {
                                        display: true,
                                        align: "top",
                                        color: "#0f0f0f",
                                    },
                                },
                                {
                                    label:
                                        t("fleetDashboard.onlineCost") +
                                        " (SEK)",
                                    data: formattedLocationStats.driver_cost_stats.map(
                                        (s) =>
                                            Math.round(
                                                s.online_cost /
                                                    s.orders_delivered_with_cost
                                            )
                                    ),
                                    datalabels: {
                                        display: true,
                                        align: "top",
                                        color: "#0f0f0f",
                                    },
                                },
                            ]}
                            title={t("fleetDashboard.costPerDelivery")}
                            description={t(
                                "fleetDashboard.costPerDeliveryDescription"
                            )}
                        />
                    </div>
                </>
            )}

            <DownloadDashboardPopup
                showPopup={isDownloadPopupOpen}
                onClose={() => setisDownloadPopupOpen(false)}
            />
        </div>
    );
}

export default FleetDashboard;
