import {
    addDays,
    addMinutes,
    endOfDay,
    format,
    isAfter,
    isSameDay,
    isWeekend,
    parse,
} from "date-fns";
import { useCallback, useMemo } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import craneIcon from "../../../assets/vehicles/crane.svg";
import largeCraneIcon from "../../../assets/vehicles/large-crane.svg";
import largeVanIcon from "../../../assets/vehicles/large-van.svg";
import mediumCraneIcon from "../../../assets/vehicles/medium-crane.svg";
import ordinaryCarIcon from "../../../assets/vehicles/ordinary-car.svg";
import truckIcon from "../../../assets/vehicles/truck.svg";
import Icon from "../../../components/UI/Icon";
import DateInput from "../../../components/inputs/DateInput";
import Dropdown from "../../../components/inputs/Dropdown";
import RadioInput from "../../../components/inputs/RadioInput";
import { NewOrderForm } from "../../../schemas/form";
import { DeliveryPriceData, Vehicle } from "../../../shared/types/api";
import { DeliveryNames } from "../../../shared/types/internal";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../../shared/utility/misc";
import { getOrderTimes } from "../../../shared/utility/order";
import { CAR_NAME, DELIVERY_NAME } from "../../../shared/values/enums";
import "./style.scss";

type Props = {
    control: Control<NewOrderForm>;
    vehicle: Vehicle;
    prices: DeliveryPriceData | null;
    onDeliveryChange: () => void;
    activeVehicle: string;
    disabled?: boolean;
};

function VehicleCard(props: Props) {
    const { t } = useTranslation();

    const isActiveVehicle = props.activeVehicle === props.vehicle.car_name;

    const vehicleCardClasses = ["vehicle-card"];
    if (isActiveVehicle) {
        vehicleCardClasses.push("active");
    }
    if (props.disabled) {
        vehicleCardClasses.push("disabled");
    }

    let vehicleIcon = ordinaryCarIcon;
    switch (props.vehicle.car_name) {
        case CAR_NAME.Car:
            vehicleIcon = ordinaryCarIcon;
            break;
        case CAR_NAME.SmallTruck:
            vehicleIcon = largeVanIcon;
            break;
        case CAR_NAME.Truck:
            vehicleIcon = truckIcon;
            break;
        case CAR_NAME.Crane:
            vehicleIcon = craneIcon;
            break;
        case CAR_NAME.MediumCrane:
            vehicleIcon = mediumCraneIcon;
            break;
        case CAR_NAME.LargeCrane:
            vehicleIcon = largeCraneIcon;
            break;
        default:
            vehicleIcon = ordinaryCarIcon;
            break;
    }

    const isDeliveryChosen = useCallback(
        (deliveryName: DeliveryNames, chosenDelivery: DeliveryNames) => {
            return isActiveVehicle && deliveryName === chosenDelivery;
        },
        [isActiveVehicle]
    );

    const isTimeDisabled = useCallback(
        ({
            time,
            chosenDeliveryDate,
            chosenEarliestPickup,
            type,
        }: {
            time: string;
            chosenDeliveryDate: Date | null;
            chosenEarliestPickup: string;
            type: "pickup" | "dropoff";
        }) => {
            const date = chosenDeliveryDate || new Date();
            const today = new Date();

            let earliestPickup = addMinutes(today, 30);
            if (props.vehicle.earliest_possible_pickup) {
                const parsedDate = stringToDate(
                    props.vehicle.earliest_possible_pickup
                );
                if (parsedDate && isSameDay(date, parsedDate)) {
                    earliestPickup = parsedDate;
                }
            }

            let earliestDelivery = addMinutes(earliestPickup, 60);
            if (chosenEarliestPickup) {
                const combinedDateTime =
                    dateToString(date) + chosenEarliestPickup;
                const parsedDate = parse(
                    combinedDateTime,
                    "yyyy-MM-ddHH:mm",
                    new Date()
                );
                earliestDelivery = addMinutes(parsedDate, 60);
            }

            const comparisonTime = new Date(
                `${format(date, "yyyy-MM-dd")}T${time}`
            );

            if (type === "pickup") {
                return isAfter(earliestPickup, comparisonTime);
            }

            if (type === "dropoff") {
                return isAfter(earliestDelivery, comparisonTime);
            }

            return false;
        },
        [props.vehicle.earliest_possible_pickup]
    );

    const isExpressDisabled = useMemo(() => {
        const expressDate = addMinutes(new Date(), 30);

        if (isWeekend(expressDate)) {
            return true;
        }

        if (props.vehicle.earliest_possible_pickup) {
            const earliestPickup = stringToDate(
                props.vehicle.earliest_possible_pickup
            );

            // Only turn off express date if the earliest pickup is the same day as express date and after the time of express date
            if (
                earliestPickup &&
                isSameDay(expressDate, earliestPickup) &&
                !isAfter(expressDate, earliestPickup)
            ) {
                return true;
            }
        }

        if (props.vehicle.disabled_dates) {
            const disabledDates = props.vehicle.disabled_dates.split(",");
            if (disabledDates.includes(dateToString(expressDate))) {
                return true;
            }
        }

        return false;
    }, [props.vehicle.disabled_dates, props.vehicle.earliest_possible_pickup]);

    const isTomorrowDisabled = useMemo(() => {
        const tomorrow = endOfDay(addDays(new Date(), 1));

        if (isWeekend(tomorrow)) {
            return true;
        }

        if (props.vehicle.earliest_possible_pickup) {
            const earliestPickup = stringToDate(
                props.vehicle.earliest_possible_pickup
            );

            // Only turn off tomorrow date if the earliest pickup is the same day as tomorrow and after the time of tomorrow
            if (
                earliestPickup &&
                isSameDay(tomorrow, earliestPickup) &&
                !isAfter(tomorrow, earliestPickup)
            ) {
                return true;
            }
        }

        if (props.vehicle.disabled_dates) {
            const disabledDates = props.vehicle.disabled_dates.split(",");
            if (disabledDates.includes(dateToString(tomorrow))) {
                return true;
            }
        }

        return false;
    }, [props.vehicle.disabled_dates, props.vehicle.earliest_possible_pickup]);

    const unselectableDates = useMemo(() => {
        const disabledDates = props.vehicle.disabled_dates?.split(",") || [];

        return disabledDates;
    }, [props.vehicle.disabled_dates]);

    return (
        <div className={vehicleCardClasses.join(" ")}>
            <div className="top">
                <img src={vehicleIcon} alt={props.vehicle.car_name} />
                <span>{props.vehicle.friendlyname}</span>
            </div>
            <div className="description text-s-r">
                <span>
                    {t(`createOrder.vehicleTooltip.${props.vehicle.car_name}`)}
                </span>
                <div
                    className="side-loading"
                    style={{
                        display:
                            props.activeVehicle !== CAR_NAME.Truck
                                ? "none"
                                : undefined,
                    }}
                >
                    {props.vehicle.car_name === CAR_NAME.Truck && (
                        <p className="text-s-r">
                            {t("createOrder.additionalVehicleTypeLabel")}
                        </p>
                    )}
                    <Controller
                        name="isSideLoaded"
                        control={props.control}
                        render={({ field: { value, onChange } }) =>
                            props.vehicle.car_name === CAR_NAME.Truck ? (
                                <div className="radios">
                                    <RadioInput
                                        label={t("general.yes")}
                                        checked={value}
                                        onClick={() => {
                                            onChange(true);
                                        }}
                                    />
                                    <RadioInput
                                        label={t("general.no")}
                                        checked={!value}
                                        onClick={() => onChange(false)}
                                    />
                                </div>
                            ) : (
                                <></>
                            )
                        }
                    />
                </div>
            </div>
            <Controller
                name="deliveryInfo"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                    <div className="delivery-list">
                        <div className="schedule-option">
                            <button
                                type="button"
                                className="top"
                                onClick={() => {
                                    onChange({
                                        ...value,
                                        deliveryOption: DELIVERY_NAME.Scheduled,
                                    });
                                    props.onDeliveryChange();
                                }}
                            >
                                <Icon
                                    type={
                                        isDeliveryChosen(
                                            DELIVERY_NAME.Scheduled,
                                            value.deliveryOption
                                        )
                                            ? "circle-dot"
                                            : "circle"
                                    }
                                    fontSize="30px"
                                />
                                <span>
                                    {t("createOrder.scheduledDeliverLabel")}
                                </span>
                                <span className="price">
                                    {props.prices?.scheduled?.price
                                        ? convertToNumberWithSpaces(
                                              props.prices.scheduled.price,
                                              "SEK"
                                          )
                                        : "-"}
                                </span>
                            </button>
                            <p className="description text-s-r">
                                {t("createOrder.scheduledDeliveryDescription")}
                            </p>
                            <div
                                className="date-time"
                                style={{
                                    display: isDeliveryChosen(
                                        DELIVERY_NAME.Scheduled,
                                        value.deliveryOption
                                    )
                                        ? undefined
                                        : "none",
                                }}
                            >
                                <DateInput
                                    value={value.deliveryDate}
                                    onChange={(d) =>
                                        onChange({
                                            ...value,
                                            deliveryDate: d,
                                        })
                                    }
                                    width="100%"
                                    isCompleted={!!value.deliveryDate}
                                    unselectableDates={unselectableDates}
                                    minDateDays={0}
                                    label={t("createOrder.deliveryDateLabel")}
                                    labelColor="var(--text-color)"
                                />
                                <Dropdown
                                    key={`pickup-${props.vehicle.car_name}`}
                                    value={value.earliestPickUp}
                                    onSelect={(d) => {
                                        onChange({
                                            ...value,
                                            earliestPickUp: d.value,
                                            latestDelivery:
                                                value.latestDelivery &&
                                                isTimeDisabled({
                                                    time: value.latestDelivery,
                                                    chosenDeliveryDate:
                                                        value.deliveryDate,
                                                    chosenEarliestPickup:
                                                        value.earliestPickUp,
                                                    type: "pickup",
                                                })
                                                    ? value.latestDelivery
                                                    : "",
                                        });
                                    }}
                                    options={getOrderTimes("pickup")}
                                    isOptionDisabled={(option) =>
                                        isTimeDisabled({
                                            time: option.value,
                                            chosenDeliveryDate:
                                                value.deliveryDate,
                                            chosenEarliestPickup:
                                                value.earliestPickUp,
                                            type: "pickup",
                                        })
                                    }
                                    isSearchable
                                    label={t(
                                        "createOrder.earliestPickUpPlaceholder"
                                    )}
                                    labelColor="var(--text-color)"
                                    isCompleted={!!value.earliestPickUp}
                                />
                                <Dropdown
                                    key={`dropoff-${props.vehicle.car_name}`}
                                    value={value.latestDelivery}
                                    onSelect={(d) =>
                                        onChange({
                                            ...value,
                                            latestDelivery: d.value,
                                        })
                                    }
                                    options={getOrderTimes("dropoff")}
                                    isOptionDisabled={(option) =>
                                        isTimeDisabled({
                                            time: option.value,
                                            chosenDeliveryDate:
                                                value.deliveryDate,
                                            chosenEarliestPickup:
                                                value.earliestPickUp,
                                            type: "dropoff",
                                        })
                                    }
                                    isSearchable
                                    label={t(
                                        "createOrder.latestDeliveryPlaceholder"
                                    )}
                                    labelColor="var(--text-color)"
                                    fixedMenu
                                    isCompleted={!!value.latestDelivery}
                                />
                            </div>
                        </div>
                        <button
                            type="button"
                            className="waves-effect"
                            onClick={() => {
                                onChange({
                                    deliveryDate: null,
                                    earliestPickUp: "",
                                    latestDelivery: "",
                                    deliveryOption: DELIVERY_NAME.Express,
                                });
                                props.onDeliveryChange();
                            }}
                            style={{
                                backgroundColor: isDeliveryChosen(
                                    DELIVERY_NAME.Express,
                                    value.deliveryOption
                                )
                                    ? "var(--highlight-color)"
                                    : undefined,
                            }}
                            disabled={isExpressDisabled}
                        >
                            <Icon
                                type={
                                    isDeliveryChosen(
                                        DELIVERY_NAME.Express,
                                        value.deliveryOption
                                    )
                                        ? "circle-dot"
                                        : "circle"
                                }
                                fontSize="30px"
                            />
                            {t("createOrder.expressDeliveryLabel")}
                            <span>
                                {props.prices?.express?.price
                                    ? convertToNumberWithSpaces(
                                          props.prices.express.price,
                                          "SEK"
                                      )
                                    : "-"}
                            </span>
                            <p className="description text-s-r">
                                {t("createOrder.expressDeliveryDescription")}
                            </p>
                        </button>
                        <button
                            type="button"
                            className="waves-effect"
                            onClick={() => {
                                onChange({
                                    deliveryDate: null,
                                    earliestPickUp: "",
                                    latestDelivery: "",
                                    deliveryOption: DELIVERY_NAME.Tomorrow,
                                });
                                props.onDeliveryChange();
                            }}
                            style={{
                                backgroundColor: isDeliveryChosen(
                                    DELIVERY_NAME.Tomorrow,
                                    value.deliveryOption
                                )
                                    ? "var(--highlight-color)"
                                    : undefined,
                            }}
                            disabled={isTomorrowDisabled}
                        >
                            <Icon
                                type={
                                    isDeliveryChosen(
                                        DELIVERY_NAME.Tomorrow,
                                        value.deliveryOption
                                    )
                                        ? "circle-dot"
                                        : "circle"
                                }
                                fontSize="30px"
                            />
                            {t("createOrder.tomorrowDeliveryLabel")}
                            <span>
                                {props.prices?.tomorrow?.price
                                    ? convertToNumberWithSpaces(
                                          props.prices.tomorrow.price,
                                          "SEK"
                                      )
                                    : "-"}
                            </span>
                            <p className="description text-s-r">
                                {t("createOrder.tomorrowDeliveryDescription")}
                            </p>
                        </button>
                    </div>
                )}
            />
        </div>
    );
}

export default VehicleCard;
