import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { changeStopDraftLocation } from "../../api/stop-draft";
import Button from "../../components/buttons/Button";
import Dropdown from "../../components/inputs/Dropdown";
import Popup from "../../hoc/Popup";
import useUsersInCompany from "../../hooks/data/useUsersInCompany";
import {
    StopChangeLocationForm,
    stopChangeLocationForm,
} from "../../schemas/form";
import { ReduxState } from "../../shared/types/redux";
import { onFormError } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    stopIds: number[];
    onLocationChange: () => void;
};

function StopChangeLocationPopup(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);

    const { users } = useUsersInCompany();

    const defaultValues = useMemo(() => {
        return {
            locationId: "",
            notifyCustomerEmail: "",
            stopIds: props.stopIds,
        };
    }, [props.stopIds]);

    const { control, handleSubmit, reset, watch } =
        useForm<StopChangeLocationForm>({
            resolver: zodResolver(stopChangeLocationForm),
            defaultValues,
        });

    const chosenLocationId = watch("locationId");

    const { mutate: changeLocationHandler, isPending: isLoading } = useMutation(
        {
            mutationFn: async ({
                stopIds,
                locationId,
                notifyCustomerEmail,
            }: StopChangeLocationForm) => {
                await changeStopDraftLocation({
                    stopIds,
                    locationId: +locationId,
                    notifyCustomerEmail: notifyCustomerEmail || undefined,
                });
            },
            onSuccess: () => {
                toast.success(t("successMessage.stopDraftLocationChanged"));
                props.onLocationChange();
                props.onClose();
            },
            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        }
    );

    const notifyUsersOptions = useMemo(() => {
        const usersForLocation =
            users?.filter((u) => u.location_id === +chosenLocationId) || [];

        const userOptions = usersForLocation
            .map((u) => ({
                label:
                    u.first_name && u.last_name
                        ? `${u.first_name} ${u.last_name}`
                        : u.email,
                value: u.email,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        return userOptions;
    }, [chosenLocationId, users]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("fleetPlanner.changeLocationTitle")}
        >
            <form
                className="stop-change-location-popup"
                onSubmit={handleSubmit(
                    (data) => changeLocationHandler(data),
                    onFormError
                )}
            >
                <section>
                    <Controller
                        control={control}
                        name="locationId"
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                value={value}
                                onSelect={(v) => onChange(v.value)}
                                label={t("fleetPlanner.locationLabel")}
                                options={
                                    user?.company_entity?.locations
                                        .filter(
                                            (l) => l.id !== user.location_id
                                        )
                                        .sort((a, b) =>
                                            a.name.localeCompare(b.name)
                                        )
                                        .map((l) => ({
                                            label: l.name,
                                            value: l.id.toString(),
                                        })) || []
                                }
                                isSearchable
                                width="100%"
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        control={control}
                        name="notifyCustomerEmail"
                        render={({ field: { value, onChange } }) => (
                            <>
                                <Dropdown
                                    value={value}
                                    onSelect={(v) => onChange(v.value)}
                                    label={t(
                                        "fleetPlanner.notifyCustomerLabel"
                                    )}
                                    options={notifyUsersOptions}
                                    isSearchable
                                    width="100%"
                                />
                                <Button
                                    label={t("fleetPlanner.deselectUser")}
                                    color={value === "" ? "alt" : "secondary"}
                                    onClick={() => onChange("")}
                                />
                            </>
                        )}
                    />
                </section>
                <section>
                    <Button
                        color="secondary"
                        label={t("fleetPlanner.changeLocationSubmit")}
                        width="100%"
                        isLoading={isLoading}
                    />
                </section>
            </form>
        </Popup>
    );
}

export default StopChangeLocationPopup;
